import { Note } from './../common/note';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import JavaLogo from './../../images/JavaLogo.png';
import StringMethodsImg from './../../images/StringMethods.png';
import FileMethodsImg from './../../images/FileMethods.png';
import CollectionArrayMethodImg from './../../images/CollectionArrayMethod.png';
import NotPredicateMethodImg from './../../images/NotPredicateMethod.png';
import HttpClientExampleImg from './../../images/HttpClientExample.png';

const tabsData = [{ eventKey: 'New-String-Methods', event: 'New String Methods' },
{ eventKey: 'New-File-Methods', event: 'New File Methods' },
{ eventKey: 'Collection-to-an-Array', event: 'Collection to an Array' },
{ eventKey: 'The-Not-Predicate-Method', event: 'The Not Predicate Method' },
{ eventKey: 'HTTP-Client', event: 'HTTP Client' }];

const stripMethods = "Java 11 also added methods stripLeading() and stripTrailing(), which handle leading and trailing whitespaces, respectively.";
const linesMethod = "We should prefer this method over split(), as it provides better performance for breaking multi-line input.";

const Java11 = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={JavaLogo} className="paddingRight20" alt="Java Logo not found" style={{ 'paddingRight': '10px', 'height': '30px', 'width': '30px' }} />
							Learn Java 11</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								Oracle released Java 11 in September 2018, only 6 months after its predecessor, version 10.<br/>
								Java 11 is the first long-term support (LTS) release after Java 8. 
								Oracle also stopped supporting Java 8 in January 2019. As a consequence, a lot of us will upgrade to Java 11.
								<br />Here are the some of java 11 features –

						</div>
							<div className="col-lg-12">

								<Tab.Container id="left-tabs-example" defaultActiveKey={tabsData[0].eventKey}>
									<Row>
										<Col sm={3}>
											<Nav variant="pills" className="flex-column menuItems">
												{tabsData.map((tabData, index) => {
													const hrefData= '#'+tabData.eventKey;
													
													return <Nav.Item key={tabData.eventKey}>
															<Nav.Link href={hrefData} eventKey={tabData.eventKey}>{index + 1}.) {tabData.event}</Nav.Link>
														</Nav.Item>
													}
												)}
											</Nav>
										</Col>
										<Col sm={9}>
											<Tab.Content>
												<NewStringMethods />
												<NewFileMethods />
												<CollectionArray />
												<TheNotPredicateMethod />
												<HttpClient />
												
											</Tab.Content>
										</Col>
									</Row>
								</Tab.Container>
							</div>
						</div>
					</div>
					<div className="col-lg-12 paddingTop20">
						<span className="textColorPink">
							<b>Disclaimer: </b>
							Content of this page has been referred from <a href="https://www.oracle.com/java/technologies/java-se-glance.html" target="_blank" rel="noreferrer">Oracle</a>.
						</span> 
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
	);
}

export default Java11;

const NewStringMethods = () => {
	return (
		<Tab.Pane id="New-String-Methods" eventKey="New-String-Methods">
			<div id="New-String-Methods">
				<h5>New String Methods</h5>
				<p>
					Java 11 adds a few new methods to the String class: isBlank, lines, strip, stripLeading, stripTrailing, and repeat.
				</p>

				<h6>isBlank()</h6>
				<p>
					The isBlank() instance method returns true if the string is empty or contains only whitespace. Otherwise, it returns false.
					It is aware of Unicode whitespace characters.
				</p>

				<h6>lines()</h6>
				<p>
					The lines() instance method returns a Stream of lines extracted from the string, separated by line terminators.<br/>
					A line terminator is one of the following:"\n", "\r", or "\r\n".<br/>

					The stream contains lines in the order in which they occur. The line terminator is removed from each line.
					
				</p>
				<Note noteClass="noteInfo" text={linesMethod} />
				
				<h6>strip()</h6>
				<p>The strip() instance method returns a string with all leading and trailing whitespaces removed.</p>
				<Note noteClass="noteInfo" text={stripMethods} />

				<h6>repeat()</h6>
				<p>
					The repeat() method repeats the string content.
					<br/>
					It returns a string whose value is the concatenation of the string repeated n times, where n is passed as a parameter.<br/>
					repeat() returns an empty string if the string is empty or the parameter value is zero.
				</p>				
				
				<p className="pCenter"><img src={StringMethodsImg} alt="not found" className="paddingTop20" style={{'width':'100%'}} /></p>
			</div>
		</Tab.Pane>
	);	
}

const NewFileMethods = () => {
	return (
		<Tab.Pane id="New-File-Methods" eventKey="New-File-Methods">
			<div id="New-File-Methods">
				<h5>New File Methods</h5>
				<p>
					Additionally, it's now easier to read and write Strings from files.
	
					We can use the new readString and writeString static methods from the Files class.
				</p>
				
				<h6>readString()</h6>
				<p>
					This method is used to read all content from a file into a string. The method ensures that the file is closed when all content has been read. 
					<br/>The syntax of the method is given below:
					<br/><br/>
					<p className="codeBlock">public static String readString(Path path) throws IOException</p>
				</p>
				<h6>writeString()</h6>
				<p>
					This method is used to write a string to a file. Characters are encoded into bytes using the UTF-8 charset. 
					<br/>The syntax of the method is given below:
					<br/><br/>

					<p className="codeBlock">public static Path writeString(Path path, CharSequence csq, OpenOption... options)</p>
				</p>
				<p className="pCenter"><img src={FileMethodsImg} alt="not found" className="paddingTop20" style={{'width':'100%'}} /></p>	
			</div>
		</Tab.Pane>
	);	
}

const CollectionArray = () => {
	return (
		<Tab.Pane id="Collection-to-an-Array" eventKey="Collection-to-an-Array">
			<div id="Collection-to-an-Array">
				<h5>Collection to an Array</h5>
				<p>
					The java.util.Collection interface contains a new default toArray method which takes an IntFunction argument.
					<br/>
					This makes it easier to create an array of the right type from a collection.
					<br/><br/>
					<h6>Old Way</h6>
					<p className="codeBlock">array = list.toArray(new String[list.size()]);</p>
					
					<h6>New Way</h6>
					<p className="codeBlock">array = list.toArray(String[]::new);</p>
				</p>
				<p className="pCenter"><img src={CollectionArrayMethodImg} alt="not found" className="paddingTop20" style={{'width':'100%'}} /></p>
			</div>
		</Tab.Pane>
	);	
}

const TheNotPredicateMethod = () => {
	return (
		<Tab.Pane id="The-Not-Predicate-Method" eventKey="The-Not-Predicate-Method">
			<div id="The-Not-Predicate-Method">
				<h5>The Not Predicate Method</h5>
				<p>
					A static not method has been added to the Predicate interface. 
					We can use it to negate an existing predicate, much like the negate method.
				</p>
				<p className="pCenter"><img src={NotPredicateMethodImg} alt="not found" className="paddingTop20" style={{'width':'100%'}} /></p>
			</div>
		</Tab.Pane>
	);	
}

const HttpClient = () => {
	return (
		<Tab.Pane id="HTTP-Client" eventKey="HTTP-Client">
			<div id="HTTP-Client">
				<h5>HTTP Client</h5>
				<p>
					The new HTTP client from the java.net.http package was introduced in Java 9. 
					It has now become a standard feature in Java 11.
					<br/>
					The new HTTP API improves overall performance and provides support for both HTTP/1.1 and HTTP/2.
					<br/><br/>
					Java 11 added a new <b>module java.net.http</b> and a <b>package java.net.http</b> to define the HTTP Client and WebSocket APIs.
					<br/>
					This package contains several classes and interfaces to provide high-level client interfaces to HTTP and 
					low-level client interfaces to WebSocket.
					<br/><br/>
					We can use these classes and interface to sent synchronous or asynchronous requests.
				</p>
				<p className="pCenter"><img src={HttpClientExampleImg} alt="not found" className="paddingTop20" style={{'width':'100%'}} /></p>
			</div>
		</Tab.Pane>
	);	
}



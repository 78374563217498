import { SocialIcon } from 'react-social-icons';

const ContactUs = () => {
	return (
		<div className="container-fluid" >
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="row">
						<div className="col-lg-12 header">
							<h2>Contact Us</h2>
							<hr/>
						</div>
					</div>
					<div className="row paddingTop20">
						<div className="col-lg-12">
							<span className="textColorRed">Connect over Skype :</span> <SocialIcon url="https://join.skype.com/invite/pugQKLdfgXG5" network="soundcloud" bgColor="#6f42c1" style={{ height: 25, width: 25 }} /> 
							<a className="aFooter" href="https://join.skype.com/invite/pugQKLdfgXG5"> Doot Java Academy</a>
						</div>	
						<div className="col-lg-12 paddingTop20">
						<span className="textColorRed">Write Us @ :</span> <SocialIcon url="mailto:dootjavaacademy@gmail.com" bgColor="#6f42c1" style={{ height: 25, width: 25 }} /> 
						 	<a className="aFooter" href="mailto:dootjavaacademy@gmail.com"> DootJavaAcademy@gmail.com</a>
					
						</div>
					</div>
					<div className="row paddingTop20">
						<div className="col-lg-12">
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.0313166488563!2d73.94438!3d18.5274869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c300272f5335%3A0x82208783ab6aa4e!2sDoot%20Java%20Academy!5e0!3m2!1sen!2sin!4v1707401298672!5m2!1sen!2sin" 
								width="100%" height="400" title="Doot Java Academy" allowfullscreen="" loading="lazy" 
								referrerpolicy="no-referrer-when-downgrade">
							</iframe>
						</div>
					</div>
				</div>	
				<div className="col-lg-2"></div>			
			</div>			
		</div>
	);
}

export default ContactUs;
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import Logo from './../../images/HibernateLogo.png';
import { HibernateOverview, HibernateArchitecture, HibernateCaching, HibernateCriteria, HibernateEnvironmentSetup,
	HibernateIntegrationSpring, HibernateMapping, HibernateNamedQuery, HibernateQueryLanguage, HibernateTransactionManagement } from './hibernate-topics';

const tabsData = [
{ eventKey: 'Hibernate-Overview', event: 'Hibernate Overview' },
{ eventKey: 'Hibernate-Architecture', event: 'Hibernate Architecture' },
{ eventKey: 'Hibernate-Environment-Setup', event: 'Hibernate Environment Setup' },
{ eventKey: 'Hibernate-Mapping', event: 'Hibernate Mapping' },
{ eventKey: 'Hibernate-Transaction-Management', event: 'Transaction Management' },
{ eventKey: 'Hibernate-Query-Language', event: 'Hibernate Query Language' },
{ eventKey: 'Hibernate-Named-Query', event: 'Named Query' },
{ eventKey: 'Hibernate-Criteria', event: 'Hibernate Criteria' },
{ eventKey: 'Hibernate-Caching', event: 'Hibernate Caching' },
{ eventKey: 'Hibernate-Integration-Spring', event: 'Hibernate and Spring Integration' }
];

const Hibernate = ()=> {
    return (
        <div className="container-fluid">
			<div className="row paddingBottom20">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'paddingRight': '10px', 'height': '30px', 'width': '30px' }} />
									Hibernate Framework
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								Hibernate is a Java framework that simplifies the development of Java application 
								to interact with the database. It is an open source, lightweight, ORM (Object Relational Mapping) tool. 
								Hibernate implements the specifications of JPA (Java Persistence API) for data persistence.

						</div>
							<div className="col-lg-12">

								<Tab.Container id="left-tabs-example" defaultActiveKey={tabsData[0].eventKey}>
									<Row>
										<Col sm={3}>
											<Nav variant="pills" className="flex-column menuItems">
												{tabsData.map((tabData, index) => {
													const hrefData= '#'+tabData.eventKey;
													
													return <Nav.Item key={tabData.eventKey}>
														<Nav.Link href={hrefData} eventKey={tabData.eventKey}>{index + 1}.) {tabData.event}</Nav.Link>
													</Nav.Item>
													}
												)}
											</Nav>
										</Col>
										<Col sm={9}>
											<Tab.Content>
												<HibernateOverview />
												<HibernateArchitecture />
												<HibernateEnvironmentSetup />
												<HibernateMapping />
												<HibernateCaching />
												<HibernateCriteria />
												<HibernateIntegrationSpring />
												<HibernateNamedQuery />
												<HibernateQueryLanguage />
												<HibernateTransactionManagement />
											</Tab.Content>
										</Col>
									</Row>
								</Tab.Container>
							</div>
							<div className="col-lg-12 paddingTop20">
								<span className="textColorPink">
									<b>Disclaimer: </b>
									Content of this page has been referred from <a href="https://hibernate.org/" target="_blank" rel="noreferrer">Hibernate</a>.
								</span> 
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
        
    );
}

export default Hibernate;

import { SocialIcon } from 'react-social-icons';

const Footer = () => {
	return (
		<div className="myFooter pCenter paddingBottom20">
			<span className="textColorRed">

				<b><i>Live Classes @ </i></b> <span className="aFooter"><i class="fa fa-video-camera"></i> Google Meet</span>
					&nbsp; <b>/ </b>Skype <SocialIcon url="https://join.skype.com/invite/pugQKLdfgXG5" network="soundcloud" bgColor="#6f42c1" style={{ height: 25, width: 25 }} />
				<a className="aFooter" href="https://join.skype.com/invite/pugQKLdfgXG5"> Doot Java Academy</a>
			</span>
			<span className="textColorRed">
				&nbsp;&nbsp; <b>| </b> &nbsp;&nbsp;
						</span>
			<span className="textColorRed">Write Us :</span> <SocialIcon url="mailto:dootjavaacademy@gmail.com" bgColor="#6f42c1" style={{ height: 25, width: 25 }} />
			<a className="aFooter" href="mailto:dootjavaacademy@gmail.com"> DootJavaAcademy@gmail.com</a>

		</div>
	)
}

export default Footer;
import Logo from './../../images/SpringBootLogo.png';

const SpringBoot = ()=> {
    return (
        <div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'padding-right': '10px', 'height': '40px', 'width': '40px' }} />
									Spring Boot - Basics
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								<b>Spring Boot </b>is a module that provides RAD (<b>Rapid Application Development</b>) features to the Spring 
								framework. Spring boot is highly dependent on the starter templates, and 
								auto-configuration features are very powerful and work flawlessly.
						
								<br/><br/>
							</div>
							<div>
								<h5>1. Spring Boot Starter Template</h5>
								Spring Boot starters are templates containing a collection of all the relevant transitive dependencies needed to 
								start a particular functionality.
								<br/>
								For example, If we want to create a Spring Web application then in a traditional setup, we would have included 
								all required dependencies ourselves. It leaves the chances of version conflicts, resulting in more runtime exceptions.
								<br/><br/>
								With Spring boot, to create such an application, all we need to import is <i>spring-boot-starter-web </i>  
								dependency which internally imports all the required dependencies and adds to the project.
								<br/>
								Here, we do not need to provide version information into child dependencies. All versions are resolved in relation 
								to the version of the parent starter.
								<br/><br/>
							</div>
							<div>
								<h5>2. Spring Boot Auto-Configuration</h5>
								Spring boot auto-configuration scans the classpath, finds the libraries in the classpath, then attempts to 
								guess the best configuration for them, and finally configures all such beans.
								<br/>
								Auto-configuration tries to be as intelligent as possible and will back away as we define more of our own 
								configuration. Auto-configuration is enabled with <b>@EnableAutoConfiguration</b> annotation.
								<br/><br/>
								Auto-configuration is always applied after user-defined beans have been registered.
								<br/>
								Spring boot auto-configuration logic is implemented in spring-boot-autoconfigure.jar and works on top of 
								<b>@Conditional, @ConditionalOnClass and @ConditionalOnProperty</b> annotations.
								<br/><br/>
							</div>
							
							<div>
								<h5>3. Embedded Servers</h5>
								Spring Boot applications always include tomcat as the default embedded server. 
								It enables us to run any Spring boot application from the command prompt without any 
								additional details.
								<br/>
								It’s all configuration-based. We can exclude Tomcat and include any other 
								embedded server if we want, e.g. Jetty Server.
								<br/><br/> 
							</div>
							
							<div>
								<h5>4. Bootstrapping the Application</h5>
								To run the spring boot application, we need to use <b>@SpringBootApplication</b> annotation. 
								Behind the scenes, that’s equivalent to <b>@Configuration, @EnableAutoConfiguration, and 
								@ComponentScan</b> together.
								<br/>
								<b>@SpringBootApplication</b> enables the scanning of config classes, and files and loads them into 
								spring context.
								<br/><br/>
								When we run the application, the autoconfiguration feature starts loading all the configuration files, 
								configures them and bootstrapping the application based on application properties which is in 
								/resources folder with name application.properties or application.yml file.
							</div>
						</div>
					</div>
					<div className="col-lg-12 paddingTop20">
						<span className="textColorPink">
							<b>Disclaimer: </b>
							Content of this page has been referred from <a href="https://spring.io/" target="_blank" rel="noreferrer">Spring.io</a>.
						</span> 
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
        
    );
}

export default SpringBoot;

import { Tab } from 'react-bootstrap';
import MSMonolithArchitectureImg from './../../images/MS-Monolith-Architecture.png';
import MSArchitectureImg from './../../images/MS-Architecture.png';
import MSLoadBalancerImg from './../../images/MS-LoadBalancer.png';
import MSWithoutServiceDiscoveryImg from './../../images/MS-without-service-discovery.png';
import MSWithServiceDiscoveryImg from './../../images/MS-with-service-discovery.png';
import MSHystrixFallbackImg from './../../images/MS-HystrixFallback.png';
import MSZipkinImg from './../../images/MS-Zipkin.png';
import MSSpringInitializrImg from './../../images/MS-SpringInitializr.png';
import MSProjectExplorerImg from './../../images/MS-ProjectExplorer.png'
import MSControllerImg from './../../images/MS-Controller.png';
import MSControllerOutputImg from './../../images/MS-ControllerOutput.png';
import MSControllerWithServiceImg from './../../images/MS-ControllerWithService.png';
import MSServiceImg from './../../images/MS-Service.png';
import MSRepositoryImg from './../../images/MS-Repository.png';
import MSEntityImg from './../../images/MS-Entity.png';
import MSApplicationPropertiesImg from './../../images/MS-ApplicationProperties.png';
import MSH2ConsoleImg from './../../images/MS-H2Console.png';
import MSGetAllImg from './../../images/MS-GetAll.png';
import MSGetByIdImg from './../../images/MS-GetById.png';
import MSGetByValueImg from './../../images/MS-GetByValue.png';

export const MicroservicesArchitecture = () => {
	return (
		<Tab.Pane id="Microservices-Architecture" eventKey="Microservices-Architecture">
			<div id="Microservices-Architecture">
				<p>
					<h5>Microservices Architecture </h5>
					In Microservices Architecture, Instead of a monolithic app, we have several independent applications that can run on their own.
					 We can create them using different programming languages and even different platforms. We can structure big and complicated
					 applications with simpler and independent programs that execute by themselves. These smaller programs are grouped to deliver
					 all the functionalities of the big, monolithic app.<br /> <br />
					Microservices captures our business scenario. Now answering the question, <h6><i>“What problem are we trying to solve here?”</i></h6>
					Instead of large teams working on large, monolithic projects, smaller, more agile teams develop the services using the tools
					and frameworks they are most comfortable with. Each of the involved programs is independently versioned, executed, and scaled.<br />
					These microservices can interact with other microservices and can have unique URLs or names while being always available and
					consistent even when failures are experienced.<br />
				So let’s discuss it by an example as below.<br /><br />
	
				For example, imagine an Ecommerce Application with separate microservices for customer-accounts, item-catalog and shopping carts.
				So these components are inevitably important for such a large Ecommerce Application.<br /><br />
				For Ecommerce Application,
				we could use following architectures.
	
					<ul>
						<li>
							<h6>Without Microservices(Monolith architecture)</h6>
							In this architecture we are using Monolith architecture i.e. all collaborating components combine all in one application.
							<p className="pCenter"><img src={MSMonolithArchitectureImg} alt="not found" className="paddingTop20" style={{ 'width': '50%' }} /></p>
						</li>
						<li>
							<h6>With Microservices  </h6>
							In this architecture style, the main application divided into a set of sub-applications called microservices. One large Application divided into multiple collaborating processes as below.
							<p className="pCenter"><img src={MSArchitectureImg} alt="not found" className="paddingTop20" style={{ 'width': '50%' }} /></p>
						</li>
					</ul>
				</p>
			</div>	
		</Tab.Pane>
	)
};

export const MicroservicesBenefits = () => {
	return (
		<Tab.Pane id="Microservices-Benefits" eventKey="Microservices-Benefits">
			<div id="Microservices-Benefits">
				<p>
					<h5>Microservices Benefits</h5>
					There are many benefits to using microservices. Some of them are related to how they allow developers to write code.
					Other influence application architecture.<br /><br />
					<ul>
						<li>
							The smaller code base is easy to maintain.
							</li>
						<li>
							Easy to scale as an individual component.</li>
						<li>
							Technology diversity i.e. we can mix libraries, databases, frameworks etc.</li>
						<li>
							Fault isolation i.e. a process failure should not bring the whole system down.</li>
						<li>
							Better support for smaller and parallel team.</li>
						<li>
							Independent deployment</li>
						<li>
							Deployment time reduce</li>
					</ul>
				</p>
			</div>
		</Tab.Pane>
	)
};

export const MicroservicesChallenges = () => {
	return (
		<Tab.Pane id="Microservices-Challenges" eventKey="Microservices-Challenges">
			<div id="Microservices-Challenges">
				<p>
					<h5>Microservices Challenges </h5>
					<ul>
						<li>
							Difficult to achieve strong consistency across services</li><li>
							ACID transactions do not span multiple processes.</li><li>
							Distributed System so hard to debug and trace the issues</li><li>
							Greater need for an end to end testing</li><li>
							Required cultural changes in across teams like Dev and Ops working together even in the same team.
						</li>
					</ul>
	
				</p>
			</div>
		</Tab.Pane>
	)
};

export const MicroservicesInfrastructure = () => {
	return (
		<Tab.Pane id="Microservices-Infrastructure" eventKey="Microservices-Infrastructure">
			<div id="Microservices-Infrastructure">
				<p>
					<h5>Microservices Infrastructure</h5>
					Platform as a Service like Pivotal Cloud Foundry help to deployment, easily run, scale, monitor etc.
					It supports for continuous deployment, rolling upgrades to new versions of code, running multiple versions of the same service at same time.
	
	
				</p>
			</div>
		</Tab.Pane>
	)
};


export const SpringCloud = () => {
	return (
		<Tab.Pane id="Spring-Cloud" eventKey="Spring-Cloud">
			<div id="Spring-Cloud">
				<p>
					<h5>Spring Cloud</h5>
					<ul>
						<li>
							It is building blocks for Cloud and Microservices
						</li>
						<li>
							It provides microservices infrastructure like provide use services such as Service Discovery, a Configuration server and Monitoring
						</li>
						<li>
							It provides several other open source projects like Netflix OSS
						</li>
						<li>
							It provides PaaS like Cloud Foundry, AWS and Heroku
						</li>
						<li>
							It uses Spring Boot style starters
						</li>
					</ul>
					There are many use-cases supported by Spring Cloud like Cloud Integration, Dynamic Reconfiguration, Service Discovery, Security, Client-side Load Balancing etc.<br />
					<br />
					<h6>Service Discovery</h6>
					Spring Cloud support several ways to implement service discovery but for this, I am going to use Eureka created by Netflix. Spring Cloud provides several annotation to make it use easy and hiding lots of complexity.
					<br /><br />
					<ul>
						<li><h6>Problem without discovery</h6>
						How do services find each other? <br />
						What happens if we run multiple instances for a service <br />
							<p className="pCenter"><img src={MSWithoutServiceDiscoveryImg} alt="not found" className="paddingTop20" style={{ 'width': '50%' }} /></p>
	
						</li>
						<li>
							<h6>Resolution with service discovery</h6>
							<p className="pCenter"><img src={MSWithServiceDiscoveryImg} alt="not found" className="paddingTop20" style={{ 'width': '50%' }} /></p>
						</li>
					</ul>
				</p>
			</div>
		</Tab.Pane>
	)
};

export const SpringCloudGatewayArchitecture = () => {
	return (
		<Tab.Pane id="Spring-Cloud-Gateway-Architecture" eventKey="Spring-Cloud-Gateway-Architecture">
			<div id="Spring-Cloud-Gateway-Architecture">
				<p>
					<h5>Spring Cloud Gateway Architecture</h5>
					API Gateway, Edge Service provides a unified interface for a set of microservices so that clients do not need to know about
					all the details of microservices internals.<br />
					Spring Cloud Gateway is API Gateway implementation by the Spring Cloud team on top of the Spring reactive ecosystem.<br />
					It consists of the following building blocks-
					<ul>
						<li>
							<h6>Route </h6>
							Route the basic building block of the gateway. It consists of an ID, destination URI Collection of predicates, and a collection of filters. A route is matched if the aggregate predicate is true.
					</li><li>
							<h6>Predicate</h6>
							 This is similar to Java 8 Function Predicate. Using this functionality we can match HTTP requests, such as headers, URLs, cookies, or parameters.
					</li><li>
							<h6>Filter</h6>
							These are instances of Spring Framework GatewayFilter. Using this we can modify the request or response as per the requirement.
					</li>
					</ul>
	
				</p>
				<p className="pCenter"><img src={MSLoadBalancerImg} alt="not found" className="paddingTop20" style={{ 'width': '70%' }} /></p>
			</div>	
		</Tab.Pane>
	)
};

export const SpringCloudConfigServer = () => {
	return (
		<Tab.Pane id="Spring-Cloud-Config-Server" eventKey="Spring-Cloud-Config-Server">
			<div id="Spring-Cloud-Config-Server">
				<p>
					<h5>Spring Cloud Config Server</h5>
					To externalize configuration of applications in a central config server with the ability to update the configuration 
					values without requiring to restart the applications. We can use Spring Cloud Config Server with git or Consul 
					or ZooKeeper as a config repository.
				</p>
			</div>
		</Tab.Pane>
	)
};

export const NetflixHystrixCircuitBreaker = () => {
	return (
		<Tab.Pane id="Netflix-Hystrix-Circuit-Breaker" eventKey="Netflix-Hystrix-Circuit-Breaker">
			<div id="Netflix-Hystrix-Circuit-Breaker">
				<p>
					<h5>Circuit Breaker</h5>
					In microservices-based architecture, one service might depend on another service and if one service goes down 
					then failures may cascade to other services as well. <br />
					Spring Cloud provides Netflix Hystrix based Circuit Breaker to handle these kinds of issues.
	
				</p>
				<p className="pCenter"><img src={MSHystrixFallbackImg} alt="not found" className="paddingTop20" style={{ 'width': '70%' }} /> </p>
			</div>
		</Tab.Pane>
	)
};

export const SpringCloudSleuthWithZipkin = () => {
	return (
		<Tab.Pane id="Spring-Cloud-Sleuth-with-Zipkin" eventKey="Spring-Cloud-Sleuth-with-Zipkin">
			<div id="Spring-Cloud-Sleuth-with-Zipkin">
				<p>
					<h5>Spring Cloud Sleuth with Zipkin</h5>
					One of the pain-point with microservices is the ability to debug issues.<br /> 
					One simple end-user action might trigger a chain of microservice calls, there should be a mechanism to trace 
					the related call chains. We can use Spring Cloud Sleuth with Zipkin to trace the cross-service invocations.
	
				</p>
				<p className="pCenter"><img src={MSZipkinImg} alt="not found" className="paddingTop20" style={{ 'width': '70%' }} /> </p>
			</div>
		</Tab.Pane>
	)
};

export const DevelopingSimpleMicroservicesExample = () => {
	return (
		<Tab.Pane eventKey="Developing-Simple-Microservices-Example">
			<div id="Developing-Simple-Microservices-Example">
				<p>
					<h5>Developing Simple Microservice Example</h5>
					In order to develop a simple Spring Boot Microservice to perform <b>CRUD operations</b> on <b>DemoEntity</b> table, we have to follow the below steps - 
					<br/><br/>
					<ol>
						<li>
							Go to <a href="https://start.spring.io/" target="_blank" rel="noreferrer">Spring Initializr</a> and 
							fill the details as mentioned in below screen shot or as per your choice, then click on <b>GENERATE</b> button.<br/>
							It will gererate a .zip folder, which would be containing Spring Boot Project.
							<p className="pCenter"><img src={MSSpringInitializrImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
						</li>
						
						<li>Extract the above generated .zip folder and import it in your favourite IDE, i.e. Eclipse or IntelliJ etc.<br/>
							In IDE, The Project would be look like below - 
							<p className="pCenter"><img src={MSProjectExplorerImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
						</li>
						
						<li>
							Now, Create a new package 'controller' under 'com.example.demo' and create a class named DemoController.
							Add a testDemo() method as mentioned in below screen shot. <br/><br/>
							Go to DemoApplication class (which was already created by Spring Initializr) and Run it as Java Application. 
							You will see that console is showing the application startup statements as
							shown in below screen shot.
							<p className="pCenter"><img src={MSControllerImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
							As we know that Spring Boot uses the <b>Embedded Tomcat</b> to run the Spring Boot Application and default port is <b>8080</b> which is visible on the console.
							<br/>
							Open the web browser and type <i>http://localhost:8080/</i> to see the below output of your Spring Boot Application.
							<p className="pCenter"><img src={MSControllerOutputImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
						</li>
						
						<li>
							Create a new package 'entity' under 'com.example.demo' and 
							following that create a class named DemoEntity with Getters/Setters, Constructors as mentioned in below screen shot.
							This Entity class is the Java represntation of Data Base Table.
							<p className="pCenter"><img src={MSEntityImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
						
						</li>
						
						<li>
							Create a new package 'repo' under 'com.example.demo' and
							following that create an interface named DemoRepository which is extending <b>CrudRepository</b> to inherite JPA features.
							<br/>
							We can add custom methods as well in DemoRepository. One has been added for your reference.							
							<p className="pCenter"><img src={MSRepositoryImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
						
						</li>
						
						<li>
							Open the application.properties under the resources folder to add Data Base configurations. 
							Here, we are using in-memory <b>H2 Data Base</b> to run our application without any dependency(No Data Base installation required).
							<p className="pCenter"><img src={MSApplicationPropertiesImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
							
						</li>
						
						<li>
							Refer the below screen shot for H2 Console details. H2 Console would be showing all tables and allow to perform CRUD operations on those table.
							<p className="pCenter"><img src={MSH2ConsoleImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
						
						</li>
						
						<li>
							Create a new package 'service' under 'com.example.demo' and
							following that create an interface named DemoService, a class named DemoServiceImpl with below mentioned methods in screen shot.
							<p className="pCenter"><img src={MSServiceImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
							These methods will be intracting with DemoRepository to fetch data from Data Base.
							<br/><br/>
						</li>
						
						<li>
							Add the below methods in DemoController to expose them via REST endpoint.
							<p className="pCenter"><img src={MSControllerWithServiceImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
						
						</li>						
					
						<li>
							Now, Run the DemoApplication via main method and refer below REST endpoints mentioned in DemoController.
							All the data would be fetched from <b>DEMO_ENTITY</b> table. 
							<br/>
							Endpoint GetAll - <i><a href="http://localhost:8080/getAll" target="_blank" rel="noreferrer">http://localhost:8080/getAll</a></i>
							<p className="pCenter"><img src={MSGetAllImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
							Endpoint GetById - <i><a href="http://localhost:8080/getById/key1" target="_blank" rel="noreferrer">http://localhost:8080/getById/key1</a></i>
							<p className="pCenter"><img src={MSGetByIdImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
							Endpoint GetByValue - <i><a href="http://localhost:8080/getByValue?value=value2" target="_blank" rel="noreferrer">http://localhost:8080/getByValue?value=value2</a></i>
							<p className="pCenter"><img src={MSGetByValueImg} alt="not found" className="paddingTop20" style={{ 'width': '100%' }} /> </p>
						</li>
					</ol>
				</p>
			</div>
		</Tab.Pane>
	)
};
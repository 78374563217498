import Logo from './../../images/SpringBootLogo.png';

const SpringBootCaching = ()=> {
    return (
        <div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'padding-right': '10px', 'height': '40px', 'width': '40px' }} />
									Spring Boot - Caching
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								The Spring Framework provides support for transparently adding caching to an application. 
								At its core, the abstraction applies caching to methods, thus reducing the number of executions based on 
								the information available in the cache. The caching logic is applied transparently, 
								without any interference to the invoker. Spring Boot auto-configures the cache infrastructure 
								as long as caching support is enabled via the <b>@EnableCaching</b> annotation.
								<br/><br/>
								<h6>Supported Cache Providers</h6>
								The cache abstraction does not provide an actual store and relies on abstraction materialized by the 
								 <b> org.springframework.cache.Cache</b> and <b>org.springframework.cache.CacheManager</b> interfaces.
								<br/><br/>
								If we have not defined a bean of type <b>CacheManager</b> or a <b>CacheResolver</b> named cacheResolver 
								, Spring Boot tries to detect the following providers (in the indicated order):
								<ul>
									<li>
										Generic
									</li>
									<li>
										JCache (JSR-107) (EhCache 3, Hazelcast, Infinispan, and others)
									</li>
									<li>	
										EhCache 2.x
									</li>
									<li>
										Hazelcast
									</li>
									<li>
										Infinispan
									</li>
									<li>
										Couchbase
									</li>
									<li>
										Redis
									</li>
									<li>
										Caffeine
									</li>
									<li>
										Simple
									</li>
								</ul>
								
								<h6>Cache Abstraction</h6>
								The cache abstraction mechanism applies to Java methods. The main objective of using cache abstraction is to 
								reduce the number of executions based on the information present in the cache. 
								It applies to expensive methods such as CPU or IO bound.
								<br/>
								Every time, when a method invokes, the abstraction applies a cache behavior to the method. 
								It checks whether the method has already been executed for the given argument or not.
								<ul>
									<li>
										If yes, the cached result is returned without executing the actual method.
									</li>
									<li>
										If no, first, the method executes, and the result is cached and returned to the user.
									</li>
								</ul>

						</div>
							
						</div>
					</div>
					<div className="col-lg-12 paddingTop20">
						<span className="textColorPink">
							<b>Disclaimer: </b>
							Content of this page has been referred from <a href="https://spring.io/" target="_blank" rel="noreferrer">Spring.io</a>.
						</span> 
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
        
    );
}

export default SpringBootCaching;

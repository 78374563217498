export const CourseModule = (props) => {
	const hrefData = '#'+props.id;
	return (
		<div className="col-12 paddingLeft20 animate fadeInUp two" key={props.id}>
			<p>
				<a className="aModule" data-bs-toggle="collapse" href={hrefData} role="button" aria-expanded="false" aria-controls={props.id}>
					<h5><span className="textColorPink">{props.module} : </span><span>{props.title}</span></h5>
				</a>
				<p className="text-muted collapse" id={props.id}>
					<ul>
						{props.topics.map((topic)=>
							<li key={topic.topic}>{topic.topic}</li>	
						)}
						
					</ul>
				</p>
			</p>
			<hr />
		</div>
	)
};

import Logo from './../../images/SpringBootLogo.png';

const SpringBootTesting = ()=> {
    return (
        <div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'padding-right': '10px', 'height': '40px', 'width': '40px' }} />
									Spring Boot - Testing
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								Unit Testing is a one of the testing done by the developers to make sure individual unit or component 
								functionalities are working fine.
								<br/>
								The Spring TestContext Framework (located in the <b>org.springframework.test.context</b> package) provides generic, 
								annotation-driven unit and integration testing support that is agnostic of the testing framework in use.
								<br/> 
								The <b>TestContext</b> framework also places a great deal of importance on convention over configuration, 
								with reasonable defaults that we can override through annotation-based configuration.
								<br/><br/>
								Spring Boot provides a number of utilities and annotations to help when testing our application. 
								<br/>
								Test support is provided by two modules - 
								<ul>
									<li>spring-boot-test contains core items</li>
									<li>spring-boot-test-autoconfigure supports auto-configuration for tests</li>
								</ul> 
								Most developers use the spring-boot-starter-test 'Starter', which imports both Spring Boot test modules 
								as well as JUnit, AssertJ, Hamcrest, and a number of other useful libraries.
								<br/><br/>
								Spring Boot provides a <b>@SpringBootTest</b> annotation, which can be used as an alternative to the 
								standard spring-test <b>@ContextConfiguration</b> annotation when we need Spring Boot features. 
								<br/>
								The annotation works by creating the ApplicationContext used in our tests through SpringApplication. 
								In addition to <b>@SpringBootTest</b> a number of other annotations are also provided for testing more specific 
								slices of an application.


						</div>
							
						</div>
					</div>
					<div className="col-lg-12 paddingTop20">
						<span className="textColorPink">
							<b>Disclaimer: </b>
							Content of this page has been referred from <a href="https://spring.io/" target="_blank" rel="noreferrer">Spring.io</a>.
						</span> 
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
        
    );
}

export default SpringBootTesting;

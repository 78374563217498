import React from 'react';
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import './index.css';
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import 'font-awesome/css/font-awesome.min.css';
import LogoImg from './JavaAcademyLogo.png';

const MyNavbar = () => {
	return (
		<React.Fragment>
			<Navbar collapseOnSelect expand="lg" variant="light" style={{ 'marginBottom': '20px' }}>
				<Navbar.Brand as={Link} to="/" >
					<img src={LogoImg} alt="Logo not found"/>
					
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav activeKey={window.location.pathname} variant="pills">
						<Nav.Item href="/">
							<Nav.Link as={Link} to="/" eventKey="/home" title="Home">
								Home
                            	</Nav.Link>
						</Nav.Item>
						<NavDropdown title="Courses" id="courses-dropdown">
							
							<NavDropdown.Item>
								<Nav.Link as={Link} to="/core-java-developer" eventKey="/core-java-developer" title="Core Java Developer">
									Core Java Developer
                            	</Nav.Link>
                            	<Nav.Link as={Link} to="/python-developer" eventKey="/python-developer" title="Python Developer">
									Python Developer
                            	</Nav.Link>
                            	<Nav.Link as={Link} to="/java-full-stack-developer" eventKey="/java-full-stack-developer" title="Java Full Stack Developer">
									Java Full Stack Developer
                            	</Nav.Link>
                            	<Nav.Link as={Link} to="/java-backend-developer" eventKey="/java-backend-developer" title="Java Backend Developer">
									Java Backend Developer
                            	</Nav.Link>
                            	
                            	<Nav.Link as={Link} to="/frontend-developer" eventKey="/frontend-developer" title="Frontend Developer - ReactJS">
									Frontend Developer - ReactJS/Angular
                            	</Nav.Link>
                            	<Nav.Link as={Link} to="/springboot" eventKey="/springboot" title="Full Stack - Spring Boot and ReactJS">
									Full Stack - Spring Boot and ReactJS
                            	</Nav.Link>
                            	<Nav.Link as={Link} to="/java-microservices" eventKey="/microservices" title="Microservices with Spring Boot">
									Microservices with Spring Boot
                            	</Nav.Link>
							</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="Java" id="java-dropdown">
							
							<NavDropdown.Item>
								<Nav.Link as={Link} to="/java-8" eventKey="/java-8" title="Java 8">
									Java 8
                            		</Nav.Link>
							</NavDropdown.Item>
							<NavDropdown.Item>
								<Nav.Link as={Link} to="/java-11" eventKey="/java-11" title="Java 11">
									Java 11
                            		</Nav.Link>
							</NavDropdown.Item>
						</NavDropdown>

						<NavDropdown title="Frameworks" id="frameworks-dropdown">
							<NavDropdown.Item>
								<Nav.Link as={Link} to="/spring" eventKey="/spring" title="Spring Framework">
									Spring
                            		</Nav.Link>
							</NavDropdown.Item>

							<NavDropdown.Item>
								<Nav.Link as={Link} to="/hibernate" eventKey="/hibernate" title="Hibernate Framework">
									Hibernate
                            		</Nav.Link>
							</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="Spring Boot" id="nav-dropdown">
							<NavDropdown.Item>
								<Nav.Link as={Link} to="/spring-boot-basics" eventKey="/spring-boot-basics" title="Spring Boot">
									Spring Boot - Basics
                            		</Nav.Link>
							</NavDropdown.Item>

							<NavDropdown.Item>
								<Nav.Link as={Link} to="/spring-boot-customization" eventKey="/spring-boot-customization" title="Spring Boot">
									Spring Boot - Customization
                            		</Nav.Link>
							</NavDropdown.Item>

							<NavDropdown.Item>
								<Nav.Link as={Link} to="/spring-boot-persistence" eventKey="/spring-boot-persistence" title="Spring Boot">
									Spring Boot - Persistence
									</Nav.Link>
							</NavDropdown.Item>

							<NavDropdown.Item>
								<Nav.Link as={Link} to="/spring-boot-restAPI" eventKey="/spring-boot-restAPI" title="Spring Boot">
									Spring Boot - REST API
									</Nav.Link>
							</NavDropdown.Item>

							<NavDropdown.Item>
								<Nav.Link as={Link} to="/spring-boot-mvc" eventKey="/spring-boot-mvc" title="Spring Boot">
									Spring Boot - MVC
									</Nav.Link>
							</NavDropdown.Item>

							<NavDropdown.Item>
								<Nav.Link as={Link} to="/spring-boot-testing" eventKey="/spring-boot-testing" title="Spring Boot">
									Spring Boot - Testing
									</Nav.Link>
							</NavDropdown.Item>

							<NavDropdown.Item>
								<Nav.Link as={Link} to="/spring-boot-devOps" eventKey="/spring-boot-devOps" title="Spring Boot">
									Spring Boot - DevOps
									</Nav.Link>
							</NavDropdown.Item>

							<NavDropdown.Item>
								<Nav.Link as={Link} to="/spring-boot-caching" eventKey="/spring-boot-caching" title="Spring Boot">
									Spring Boot - Caching
									</Nav.Link>
							</NavDropdown.Item>
						</NavDropdown>

						<NavDropdown title="Web" id="web-dropdown">
							<NavDropdown.Item>
								<Nav.Link as={Link} to="/reactjs" eventKey="/reactjs" title="ReactJS">
									ReactJS
                            	</Nav.Link>
                            	<Nav.Link as={Link} to="/angular" eventKey="/angular" title="Angular">
									Angular
                            	</Nav.Link>
                            	<Nav.Link as={Link} to="/python" eventKey="/python" title="Python">
									Python
                            	</Nav.Link>
							</NavDropdown.Item>

							
						</NavDropdown>
						
						<Nav.Item>
							<Nav.Link as={Link} to="/microservices" eventKey="microservices" title="Microservices">
								Microservices
	                            </Nav.Link>
						</Nav.Item>

						<Nav.Item>
							<Nav.Link as={Link} to="/contact-us" eventKey="contactUs" title="Contact Us">
								Contact Us
	                            </Nav.Link>
						</Nav.Item>

					</Nav>


				</Navbar.Collapse>
			</Navbar>
		</React.Fragment>
	);

}

export default MyNavbar;
import { Tab } from 'react-bootstrap';
import { Note } from './../common/note';
import FunctionalInterfaceImg from './../../images/FunctionalInterface.PNG';
import DefaultInterfaceImg from './../../images/DefaultMethodInterface.PNG';
import LambdaExpressionImg from './../../images/LambdaExpression.PNG';
import StreamForEachImg from './../../images/StreamForEach.PNG';
import StreamFilterImg from './../../images/StreamFilter.PNG';
import StreamMapImg from './../../images/StreamMap.PNG';
import StreamCollectImg from './../../images/StreamMap.PNG';
import DateAndTimeImg from './../../images/DateAndTime.PNG';

const fInterfaceNote = "By definition, default methods are non-abstract and we can add as many default methods in the functional interface as we like. Secondly, if an interface has an abstract method that overrides one of the public methods of “Object” class then it is not considered as the interface’s abstract method."
const defaultMethodNote = "if a class wants to customize the default method then we can provide its own implementation by overriding the method."

export const FunctionalInterface = () => {
	return (
		<Tab.Pane id="Functional-Interface" eventKey="Functional-Interface">
			<div id="Functional-Interface">
				<p>
					<h5>Functional Interface</h5>
					Those interfaces which contains single abstract method and n number of non-abstract method is called function interface. 
					we define an annotation @FunctionalInterface to define the functional interface but it not mandatory 
					but we define it then compiler understands this is the functional interface and it contains only one abstract method 
					and if defines multiple abstract methods then the compile gives the error.
					
					<p className="pCenter"><img src={FunctionalInterfaceImg} alt="not found" className="paddingTop20" style={{'width':'80%'}} /></p>						
				</p>
				<Note noteClass="noteInfo" text={fInterfaceNote} />
			</div>
		</Tab.Pane>
	)};
	
export const DefaultMethods = () => {
	return (
		<Tab.Pane id="Default-Methods" eventKey="Default-Methods">
			<div id="Default-Methods">
				<p>
					<h5>Default Methods</h5>
					Before java 8 we can’t define the method body in an Interface but in java 8 a new feature introduced 
					where we define the body of the interface. These methods must be declared default methods. 
					Before java 8 If a multiple class implement an interface and if we define a method 
					then every class mandatory to implement a new method but the default method not mandatory to implemented by every class.
					
					<p className="pCenter"><img src={DefaultInterfaceImg} alt="not found" className="paddingTop20" style={{'width':'80%'}} /></p>										
				</p>
				<Note noteClass="noteInfo" text={defaultMethodNote} />
			</div>
		</Tab.Pane>
	)};
	

export const LambdaExpression = () => {
	return (
		<Tab.Pane id="Lambda-Expression" eventKey="Lambda-Expression">
			<div id="Lambda-Expression">
				<p>
					<h5>Lambda Expression</h5>
					A Lambda Expression (or function) can be defined as
				</p>
					<ul>
						<li>It is an anonymous function.</li>
						<li>Not having name</li>
						<li>No return type and no modifiers</li>
					</ul>
				<p>	
					Lambda Expressions are defined exactly in the place where they are needed, 
					usually as a parameter to some other function.
					<p className="pCenter"><img src={LambdaExpressionImg} alt="not found" className="paddingTop20" style={{'width':'100%'}} /></p>													
				</p>
			</div>
		</Tab.Pane>
	)};	
	
export const Streams = () => {
	return (
		<Tab.Pane id="Streams" eventKey="Streams">
			<div id="Streams">
				<p>
					<h5>Streams</h5>
					Stream API is not a data structure and it receives its input from collections, arrays or other channels.
					Stream does not store the data and it doesn’t change the original data structure. 
					We can pipeline various intermediate operations using Streams and the terminal operations return the result.
				</p>
					<ul>
						<li><b>stream()</b> − Returns a sequential stream considering collection as its source.</li>
						<li><b>parallelStream()</b> − Returns a parallel Stream considering collection as its source.</li>
					</ul>
				
				<p>
					<h5>forEach()</h5>
						Stream has provided a new method ‘forEach’ to iterate each element of the stream. 
						The below code segment shows how to print list of elements using forEach() method.
					<p className="pCenter"><img src={StreamForEachImg} alt="not found" className="paddingTop20" style={{'width':'80%'}} /></p>										
				</p>
				
					<h5>filter()</h5>
				<p>
						The ‘filter’ method is used to eliminate elements based on a criteria. 
						The below code segment prints a count of empty strings from the given list using filter() method.
					<p className="pCenter"><img src={StreamFilterImg} alt="not found" className="paddingTop20" style={{'width':'80%'}} /></p>										
				</p>
				
					<h5>collect()</h5>
				<p>
						Performs a mutable reduction operation on the elements of given stream using a Collector. 
						A Collector encapsulates the functions used as arguments to collect, 
						allowing for reuse of collection strategies and composition of collect operations
						 such as multiple-level grouping or partitioning. 
					<p className="pCenter"><img src={StreamCollectImg} alt="not found" className="paddingTop20" style={{'width':'100%'}} /></p>										
				</p>
				
					<h5>map()</h5>
				<p>
						The ‘map’ method is used to map each element to its corresponding result.
						The below code segment prints unique element of list using map() method.
					<p className="pCenter"><img src={StreamMapImg} alt="not found" className="paddingTop20" style={{'width':'100%'}} /></p>										
				</p>
			</div>
		</Tab.Pane>
	)};	
	
export const DateTimeApiChanges = () => {
	return (
		<Tab.Pane id="Date-Time-API-Changes" eventKey="Date-Time-API-Changes">
			<div id="Date-Time-API-Changes">
				<p>
					<h5>Date/Time API Changes</h5>
					In Java 8, A new date-time API under the package java.time introduced. 
					Below are some of the important classes introduced in java.time package.
				</p>
				<ul>
					<li><b>Local</b> − Simplified date-time API with no complexity of timezone handling.</li>
					<li><b>Zoned</b> − Specialized date-time API to deal with various timezones.</li>
				</ul>
				<p className="pCenter"><img src={DateAndTimeImg} alt="not found" className="paddingTop20" style={{'width':'100%'}} /></p>										
			</div>	
		</Tab.Pane>
	)};	
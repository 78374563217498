import { Tab, Row, Col, Nav } from 'react-bootstrap';
import Logo from './../../images/MicroservicesLogo.jpg';
import {
	MicroservicesArchitecture, MicroservicesBenefits, MicroservicesChallenges, MicroservicesInfrastructure, SpringCloud,
	SpringCloudGatewayArchitecture, SpringCloudConfigServer, NetflixHystrixCircuitBreaker, SpringCloudSleuthWithZipkin, DevelopingSimpleMicroservicesExample
} from './ms-topics';

const tabsData = [
	{ eventKey: 'Microservices-Architecture', event: 'Microservices Architecture' },
	{ eventKey: 'Microservices-Benefits', event: 'Microservices Benefits' },
	{ eventKey: 'Microservices-Challenges', event: 'Microservices Challenges' },
	{ eventKey: 'Microservices-Infrastructure', event: 'Microservices Infrastructure' },
	{ eventKey: 'Spring-Cloud', event: 'Spring Cloud' },
	{ eventKey: 'Spring-Cloud-Gateway-Architecture', event: 'Spring Cloud Gateway Architecture' },
	{ eventKey: 'Spring-Cloud-Config-Server', event: 'Spring Cloud Config Server' },
	{ eventKey: 'Netflix-Hystrix-Circuit-Breaker', event: 'Netflix Hystrix - Circuit Breaker' },
	{ eventKey: 'Spring-Cloud-Sleuth-with-Zipkin', event: 'Spring Cloud Sleuth with Zipkin' },
	{ eventKey: 'Developing-Simple-Microservices-Example', event: 'Developing Simple Microservice Example' }];

const Microservices = () => {
	return (
		<div className="container-fluid">
			<div className="row paddingBottom20">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'paddingRight': '10px', 'height': '50px', 'width': '50px' }} />
									Microservices
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">

								Microservices is not a new term. It coined in 2005 by Dr. Peter Rodgers then called micro web services.
								It became more popular since 2010. Microservices allows us to break our large system into the number of independent
								collaborating processes.
								<br /><br />
								<b><i>'Microservices are an architectural style that develops a single application as a set of small services. 
								Each service runs in its own process. The services communicate with clients, and often each other, 
								using lightweight protocols, often over messaging or HTTP.'</i></b>
								<br />
							</div>
							<div className="col-lg-12 paddingTop20">

								<Tab.Container id="left-tabs-example" defaultActiveKey={tabsData[0].eventKey}>
									<Row>
										<Col sm={3}>
											<Nav variant="pills" className="flex-column menuItems">
												{tabsData.map((tabData, index) => {
													const hrefData= '#'+tabData.eventKey;
													return <Nav.Item key={tabData.eventKey}>
														<Nav.Link href={hrefData} eventKey={tabData.eventKey}>{index + 1}.) {tabData.event}</Nav.Link>
													</Nav.Item>
													}
												)}
											</Nav>
										</Col>
										<Col sm={9}>
											<Tab.Content>
												<MicroservicesArchitecture />
												<MicroservicesBenefits />
												<MicroservicesChallenges />
												<MicroservicesInfrastructure />
												<SpringCloud />
												<SpringCloudGatewayArchitecture />
												<SpringCloudConfigServer />
												<NetflixHystrixCircuitBreaker />
												<SpringCloudSleuthWithZipkin />
												<DevelopingSimpleMicroservicesExample />
											</Tab.Content>
										</Col>
									</Row>
								</Tab.Container>
							</div>
							<div className="col-lg-12 paddingTop20">
								<span className="textColorPink">
									<b>Disclaimer: </b>
									Content of this page has been referred from <a href="https://spring.io/" target="_blank" rel="noreferrer">Spring.io</a>.
								</span> 
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>

	);
}

export default Microservices;

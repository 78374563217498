import Logo from './../../images/SpringBootLogo.png';

const SpringBootRESTAPI = ()=> {
    return (
        <div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'padding-right': '10px', 'height': '40px', 'width': '40px' }} />
									Spring Boot - REST API
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								REST stands for <b>RE</b>presentational <b>S</b>tate <b>T</b>ransfer. It is developed by Roy Thomas Fielding, 
								who also developed HTTP. The main goal of RESTful web services is to make web services more effective. 
								RESTful web services try to define services using the different concepts that are already present in HTTP. 
								REST is an architectural approach, not a protocol.
								<br/><br/>
								It does not define the standard message exchange format. We can build REST services with both XML and JSON. 
								JSON is more popular format with REST. The key abstraction is a resource in REST. A resource can be anything.
								It can be accessed through a Uniform Resource Identifier (URI). 
								<br/>
								<b>For example:</b>
								<br/>
								The resource has representations like XML, HTML, and JSON. The current state capture by representational 
								resource. When we request a resource, we provide the representation of the resource. 
								<br/><br/>
								<h6>The important methods of HTTP are -</h6>
								<ul>
									<li>
										<b>GET</b> - It reads a resource.
									</li>
									<li>
										<b>PUT</b> - It updates an existing resource.
									</li>
									<li>
										<b>POST</b> - It creates a new resource.
									</li>
									<li>
										<b>DELETE</b> - It deletes the resource.
									</li>
								</ul>
								
								<h6>Advantages of RESTful web services</h6>
								<ul>
									<li>
										RESTful web services are platform-independent.
									</li>
									<li>
										It can be written in any programming language and can be executed on any platform.
									</li>
									<li>	
										It provides different data format like JSON, text, HTML, and XML.
									</li>
									<li>
										It is fast in comparison to SOAP because there is no strict specification like SOAP.
									</li>
									<li>
										These are reusable.
									</li>
									<li>
										They are language neutral.
									</li>
								</ul>
								
								<h6>RestController</h6> 
									It is a specialized version of the controller. It includes the <b>@Controller</b> and <b>@ResponseBody</b> annotations, 
									and as a result, simplifies the controller implementation.
									<br/>
								
									The <b>@RequestBody</b> annotation maps the HttpRequest body to a transfer or domain object, enabling automatic deserialization of the inbound HttpRequest body onto a Java object.
									<br/>
									The <b>@ResponseBody</b> annotation tells a controller that the object returned is automatically serialized into JSON and passed back into the HttpResponse object.


						</div>
							
						</div>
					</div>
					<div className="col-lg-12 paddingTop20">
						<span className="textColorPink">
							<b>Disclaimer: </b>
							Content of this page has been referred from <a href="https://spring.io/" target="_blank" rel="noreferrer">Spring.io</a>.
						</span> 
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
        
    );
}

export default SpringBootRESTAPI;

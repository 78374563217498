import { Tab } from 'react-bootstrap';
import HibernateArchitectureImg from './../../images/HibernateArchitecture.png';

export const HibernateOverview = () => {
	return (
		<Tab.Pane id="Hibernate-Overview" eventKey="Hibernate-Overview">
			<div id="Hibernate-Overview">
				<p>
					<h5>Hibernate Overview</h5>
					Hibernate is a pure Java object-relational mapping (ORM) and persistence framework
					that allows us to map plain old Java objects to relational database tables. <br />
					The main goal of hibernate is to relieve the developer from the common data persistence related tasks.
					It maps the objects in the java with the tables in the database very efficiently and
					also we can get maximum using its data query and retrieval facilities.<br />
					Mainly by using Hibernate in our projects we can save incredible time and effort.
				</p>
			</div>
		</Tab.Pane>
	)
};

export const HibernateArchitecture = () => {
	return (
		<Tab.Pane id="Hibernate-Architecture" eventKey="Hibernate-Architecture">
			<div id="Hibernate-Architecture">
				<p>
					<h5>Hibernate Architecture</h5>
					Hibernate framework uses many objects such as session factory, session, transaction etc.
					alongwith existing Java API such as JDBC (Java Database Connectivity), JTA (Java Transaction API)
					and JNDI (Java Naming Directory Interface).
					<p className="pCenter"><img src={HibernateArchitectureImg} alt="not found"
						className="paddingTop20" style={{ 'width': '70%' }} /></p>
				</p>
			</div>
		</Tab.Pane>
	)
};

export const HibernateEnvironmentSetup = () => {
	return (
		<Tab.Pane id="Hibernate-Environment-Setup" eventKey="Hibernate-Environment-Setup">
			<div id="Hibernate-Environment-Setup">
				<p>
					<h5>Hibernate Environment Setup</h5>
					Download the hibernate distribution zip and unzipped the latest version of the Hibernate Installation file,
					we need to perform following two simple steps. <br />Make sure we are setting our CLASSPATH variable properly otherwise
					we will face problem while compiling your application.
					<ul>
						<li>
							Copy all the library files from /lib into our CLASSPATH, and change our classpath variable to
							include all the JARs.
						</li>
						<li>
							Copy hibernateX.jar file into our CLASSPATH. This file lies in the root directory of the
							installation and is the primary JAR that Hibernate needs to do its work.
						</li>
					</ul>
				</p>
			</div>
		</Tab.Pane>
	)
};


export const HibernateMapping = () => {
	return (
		<Tab.Pane id="Hibernate-Mapping" eventKey="Hibernate-Mapping">
			<div id="Hibernate-Mapping">
				<p>
					<h5>Hibernate Mapping</h5>
					Hibernate is having below list of mappings -
					<ul>
						<li>
							Mapping of collections
						</li>
						<li>
							Mapping of associations between entity classes
						</li>
						<li>
							Component Mappings
						</li>
					</ul>
					<h6>Collections Mappings</h6>
					If an entity or class has collection of values for a particular variable, then we can map those values using
					any one of the collection interfaces available in java. Hibernate can persist instances of java.util.Map,
					java.util.Set, java.util.List, and any array of persistent entities or values.

					<h6>Association Mappings</h6>
					The mapping of associations between entity classes and the relationships between tables is the soul of ORM.
					Following are the four ways in which the cardinality of the relationship between the objects can be expressed.
					An association mapping can be unidirectional as well as bidirectional.
					<p>
						<ol>
							<li>Many to One</li>
							<li>One to One</li>
							<li>One to Many</li>
							<li>Many to Many</li>
						</ol>
					</p>
					<h6>Component Mappings</h6>
					It is very much possible that an Entity class can have a reference to another class as a member variable.
					If the referred class does not have its own life cycle and completely depends on the life cycle of the owning
					entity class, then the referred class hence therefore is called as the Component class.

				</p>
			</div>
		</Tab.Pane>
	)
};


export const HibernateTransactionManagement = () => {
	return (
		<Tab.Pane id="Hibernate-Transaction-Management" eventKey="Hibernate-Transaction-Management">
			<div id="Hibernate-Transaction-Management">
				<p>
					<h5>Hibernate Transaction Management</h5>
					A transaction simply represents a unit of work. In such case, if one step fails,
					the whole transaction fails (which is termed as atomicity). A transaction can be described by ACID properties
					(Atomicity, Consistency, Isolation and Durability).
					<br /><br />
					In hibernate framework, we have Transaction interface that defines the unit of work.
					It maintains abstraction from the transaction implementation (JTA, JDBC).
					<br /><br />
					A transaction is associated with Session and instantiated by calling session.beginTransaction().
					<br /><br />
					Some of the methods of Transaction interface are as follows:<br />
					<b>- void begin() </b>starts a new transaction.<br />
					<b>- void commit() </b>ends the unit of work unless we are in FlushMode.NEVER.<br />
					<b>- void rollback() </b>forces this transaction to rollback.<br />
					<b>- void setTimeout(int seconds) </b>it sets a transaction timeout for any transaction started by a subsequent call to begin on this instance.<br />
					<b>- boolean isAlive() </b>checks if the transaction is still alive.<br />
				</p>
			</div>
		</Tab.Pane>
	)
};

export const HibernateQueryLanguage = () => {
	return (
		<Tab.Pane id="Hibernate-Query-Language" eventKey="Hibernate-Query-Language">
			<div id="Hibernate-Query-Language">
				<p>
					<h5>Hibernate Query Language</h5>
					Hibernate Query Language (HQL) is same as SQL (Structured Query Language) but
					it doesn't depends on the table of the database. Instead of table name, we use class name in HQL.
					So it is database independent query language.<br />
					HQL works with persistent objects and their properties. HQL queries are translated by Hibernate into
					conventional SQL queries, which in turns perform action on database.
					<br /><br />
					Although we can use SQL statements directly with Hibernate using Native SQL,
					but I would recommend to use HQL whenever possible to avoid database portability hassles,
					and to take advantage of Hibernate's SQL generation and caching strategies.
					<br /><br />
					Keywords like SELECT, FROM, and WHERE, etc., are not case sensitive, but properties like table
					and column names are case sensitive in HQL.
					<br /><br />
					<h6>Advantage of HQL</h6>
					There are many advantages of HQL. They are as follows:
					<br />
					<ul>
						<li>
							database independent</li><li>
							supports polymorphic queries</li><li>
							easy to learn for Java Programmer</li>
					</ul>
				</p>
			</div>
		</Tab.Pane>
	)
};


export const HibernateNamedQuery = () => {
	return (
		<Tab.Pane id="Hibernate-Named-Query" eventKey="Hibernate-Named-Query">
			<div id="Hibernate-Named-Query">
				<p>
					<h5>Hibernate Named Query</h5>
					The hibernate named query is way to use any query by some meaningful name. It is like using alias names.
					The Hibernate framework provides the concept of named queries so that application programmer need not to
					scatter queries to all the java code.
					<br /><br />
					There are two ways to define the named query in hibernate:
					<br />
					<ol>
						<li>
							<h6>by annotation</h6>

							If you want to use named query in hibernate, you need to have knowledge of
							@NamedQueries and @NamedQuery annotations.
							<br /><br />
							@NameQueries - annotation is used to define the multiple named queries.<br />
							@NameQuery - annotation is used to define the single named query.

						</li>
						<li>
							<h6>by mapping file</h6>

							If want to define named query by mapping file, you need to use query element of hibernate-mapping
							to define the named query.<br />
							In such case, you need to create hbm file that defines the named query.
						</li>
					</ol>
				</p>
			</div>
		</Tab.Pane>
	)
};

export const HibernateCriteria = () => {
	return (
		<Tab.Pane id="Hibernate-Criteria" eventKey="Hibernate-Criteria">
			<div id="Hibernate-Criteria">
				<p>
					<h5>Hibernate Criteria</h5>
					Mostly we use HQL for querying the database and getting the results.
					HQL is not preferred way for updating or deleting values because then we need to take care of
					any associations between tables. <br /><br />
					<b>Hibernate Criteria API</b> provides object oriented approach for querying the database and getting results.
					We can’t use Criteria in Hibernate to run update or delete queries or any DDL statements.
					Hibernate Criteria query is only used to fetch the results from the database using object oriented approach.
					<br /><br />
					Some of the common usage of Hibernate Criteria API are - <br />
					<ul>
						<li>
							Hibernate Criteria API provides Projection that we can use for aggregate functions such as <b>sum(), min(), max()</b> etc.
						</li><li>
							Hibernate Criteria API can be used with ProjectionList to fetch selected columns only.
						</li><li>
							Criteria in Hibernate can be used for join queries by joining multiple tables, useful methods for Hibernate criteria join are
							<b>createAlias(), setFetchMode() and setProjection()</b>
						</li><li>Criteria in Hibernate API can be used for fetching results with conditions, useful methods are
						add() where we can add Restrictions.
						</li><li>
							Hibernate Criteria API provides addOrder() method that we can use for ordering the results.
						</li>
					</ul>
				</p>
			</div>
		</Tab.Pane>
	)
};


export const HibernateCaching = () => {
	return (
		<Tab.Pane id="Hibernate-Caching" eventKey="Hibernate-Caching">
			<div id="Hibernate-Caching">
				<p>
					<h5>Hibernate Caching</h5>
					Hibernate caching improves the performance of the application by pooling the object in the cache. 
					It is useful when we have to fetch the same data multiple times.<br/><br/>

					There are mainly two types of caching:
					<br/>
					<ul>
						<li>
							First Level Cache
						</li><li>
							Second Level Cache
						</li>
					</ul>
					
					<h6>First Level Cache</h6>
					Session object holds the first level cache data. It is enabled by default. 
					The first level cache data will not be available to entire application. 
					An application can use many session object.
					<br/><br/>
					<h6>Second Level Cache</h6>
					SessionFactory object holds the second level cache data. 
					The data stored in the second level cache will be available to entire application. 
					But we need to enable it explicitely. <br/>
					There are four ways to use second level cache.<br/>
					<ol>
						<li>
							<b>read-only</b> - caching will work for read only operation.
						</li>
						<li>
							<b>nonstrict-read-write</b> - caching will work for read and write but one at a time.
						</li>
						<li>	
							<b>read-write</b> - caching will work for read and write, can be used simultaneously.
						</li>
						<li>	
							<b>transactional</b> - caching will work for transaction.
						</li>
					</ol>
				</p>
			</div>
		</Tab.Pane>
	)
};

export const HibernateIntegrationSpring = () => {
	return (
		<Tab.Pane id="Hibernate-Integration-Spring" eventKey="Hibernate-Integration-Spring">
			<div id="Hibernate-Integration-Spring">
				<p>
					<h5>Hibernate and Spring Integration</h5>
					We can simply integrate hibernate application with spring application.<br/>

					In hibernate framework, we provide all the database information in the hibernate.cfg.xml file.
					<br/>
					But if we are going to integrate the hibernate application with spring, we don't need to create the hibernate.cfg.xml file. 
					We can provide all the information in the applicationContext.xml file.
					<br/><br/>
					<h6>Advantage of Spring framework with hibernate</h6>
					The Spring framework provides <b>HibernateTemplate</b> class, so we don't need to follow so many steps like 
					create Configuration, BuildSessionFactory, Session, beginning and committing transaction etc.
					
					So it saves a lot of code and time.<br/><br/>

					<h6>Understanding problem without using spring</h6>
					
					Let's understand it by the code of hibernate given below:
					<p className="pCode">	
						<span className="pComment">/ /creating configuration</span><br /> 
						Configuration configuration=new Configuration();  <br/>  
						configuration.configure("hibernate.cfg.xml");    <br/>
						    <br/>
						<span className="pComment">/ /creating seession factory object</span><br/>    
						SessionFactory factory=configuration.buildSessionFactory();<br/>    
						    <br/>
						<span className="pComment">/ /creating session object</span><br/>    
						Session session=factory.openSession();<br/>    
						    <br/>
						<span className="pComment">/ /creating transaction object</span><br/>    
						Transaction transaction=session.beginTransaction();    <br/>
						        <br/>
						Employee employee=new Employee(100, "employee", 10000);   <br/> 
						session.persist(employee);<span className="pComment"> / /persisting the object </span>  <br/> 
						    <br/>
						transaction.commit();<span className="pComment"> / /transaction is commited  </span>  <br/>
						session.close();    <br/>
					</p>
					
					As we can see in the code of sole hibernate, we have to follow so many steps.
					<br /><br />
					<h6>Solution by using HibernateTemplate class of Spring Framework</h6>
					
					Now, we do not need to follow so many steps. We can simply write this as below:
					<br />
					<p className="pCode">
						Employee employee=new Employee(100, "employee", 10000);    <br/>
						hibernateTemplate.save(employee);<span className="pComment"> / /persisting the object </span>
					</p>  
				</p>
			</div>
		</Tab.Pane>
	)
};
import {CourseModule} from './../common/course-module';
import { CourseDescription } from './../common/course-description';

const javaModules = [{ id: 'module1', module: 'Module 01', title: 'Python Introduction', topics: [{ topic: 'What is python?' }, { topic: 'History of Python' }, { topic: 'Features of Python - Why Python?' }] },
{ id: 'module2', module: 'Module 02', title: 'Getting Started with Python', topics: [{ topic: 'Installing Python' }, { topic: 'Setting up the VS Code for Python or any other IDE' }, { topic: 'Developing the Hello, Word! program' }] },
{ id: 'module3', module: 'Module 03', title: 'Python Interpreter', topics: [{ topic: 'Invoking the Interpreter' }, { topic: 'The Interpreter and Its Environment' }] },
{ id: 'module4', module: 'Module 04', title: 'Control Flow Tools', topics: [{ topic: 'Statements in Python' }, { topic: 'Defining Functions' }, { topic: 'Lambda Expressions' }] },
{ id: 'module5', module: 'Module 05', title: 'Python Data Structure', topics: [{ topic: 'Lists' }, { topic: 'Sets' }, { topic: 'Tuples and Sequences' }, { topic: 'Dictionaries' }, { topic: 'Looping Techniques'}] },
{ id: 'module6', module: 'Module 06', title: 'Python Modules', topics: [{ topic: 'Executing modules as scripts' }, { topic: 'The Module Search Path' }, { topic: 'Standard Modules' }, { topic: 'Packages' }] },
{ id: 'module7', module: 'Module 07', title: 'Python Input and Output', topics: [{ topic: 'Fancier Output Formatting' }, { topic: 'Reading and Writing Files' }] },
{ id: 'module8', module: 'Module 08', title: 'Errors and Exceptions', topics: [{ topic: 'Syntax Errors' }, { topic: 'Exceptions' }, { topic: 'Handling Exceptions' }, { topic: 'Raising Exceptions' }, { topic: 'User-defined Exceptions' }, { topic: 'Raising and Handling Multiple Unrelated Exceptions' }] },
{ id: 'module9', module: 'Module 09', title: 'Python Classes', topics: [{ topic: 'A Word About Names and Objects' }, { topic: 'Python Scopes and Namespaces' }, { topic: 'How to create a python class' }, { topic: 'Inheritance'}] },
{ id: 'module10', module: 'Module 10', title: 'Tour of the Standard Library', topics: [{ topic: 'Brief Tour of the Standard Library' }] },
{ id: 'module11', module: 'Module 11', title: 'Python Programs', topics: [{ topic: 'Python Programs for each and every topic' }] },
];

const courseDescription = "This Python Developer Course syllabus is a comprehensive study of Python language. It contains complete industrial Python topics to learn and became Python Developer from scratch with examples.";

const CoreJavaDeveloper = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="row">
						<div className="col-lg-12 header">
							<h2>Python Developer</h2>
							<hr />
						</div>
					</div>
					<CourseDescription courseDesc={courseDescription} enquiryFor="Enquiry for Python Developer"
						courseModuleCount="11" lectures="30" hours="60" price="7,500" offerPrice="5,000" />

				</div>
			</div>
			<div className="row paddingTop20">
				<div className="col-lg-12 spanCard">
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>	
						<div className="col-lg-8">
							<p>
								<h4>What You Need</h4><hr/>
								<ul>
									<li>Java 3.10 or later</li>
									<li>A favorite text editor or IDE, i.e. VS Code or Eclipse</li>
								</ul>	
							</p>
						</div>
					</div>
					
					<div className="row paddingBottom20 textColorDarkGreen">
						<div className="col-lg-2"></div>	
						<div className="col-lg-8">
							<p>
								<h4>Why Learn Python Programming?</h4><hr/>
								Here are the reasons why you should learn Python:
								<ul>
									<li>Python has a simple syntax and hence is easy to understand and learn</li>
									<li>Python developers are in high demand and easy to get job as a Python Developer</li>
									<li>It has a good collection of open-source libraries</li>
									<li>Python is among the programming languages most preferred for Machine Learning and Data Science</li>
								</ul>
							</p>
						</div>
					</div>
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">	
							<p>
								<h4>What you will be learning here</h4><hr/>
								<ul>
									<li>Python Environment setup</li>
									<li>Understanding about Python Interpreter</li>
									<li>Get to know about IDEs</li>
									<li>Complete understanding on Standard Library</li>
									<li>Explore more on Python Scripts</li>
									<li>One minor project on Python to develop a Web Application</li>
								</ul>
							</p>
						</div>
					</div>
				</div>	
			</div>
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">			
					<div className="row paddingTop20 paddingBottom20">
						<div className="col-lg-12">
							<span className="pCenter textColorPink"><h3>Course Content </h3></span>
							<div className="accordion" id="accordionExample">
								<div className="card courseContentCard" >
									<div className="card-body">
										<div className="row">
											{javaModules.map((module) =>
												<CourseModule id={module.id} key={module.id} module={module.module} title={module.title} topics={module.topics} />

											)}

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
	);
}

export default CoreJavaDeveloper;
import {CourseModule} from './../common/course-module';
import { CourseDescription } from './../common/course-description';

const springModules = [{ id: 'module1', module: 'Module 01', title: 'Spring Overview', topics: [{topic:'What is Spring?'}, {topic:'Why to learn Spring?'}]},
{id: 'module2', module: 'Module 02', title: 'Spring Architecture', topics: [{topic:'Spring Modules'}]},
{id: 'module3', module: 'Module 03', title: 'Spring Core', topics: [{topic:'Core Module'}, {topic:'Bean Module'},{topic:'Context Module'}, {topic:'Data Access Integration'}, {topic:'Spring Web'}, {topic:'Test Module'}]},
{id: 'module4', module: 'Module 04', title: 'Inversion of Control', topics: [{topic:'Spring BeanFactory Container'}, {topic:'Spring ApplicationContext Container'}]},
{id: 'module5', module: 'Module 05', title: 'Dependency Injection', topics: [{topic:'Constructor-based dependency injection'}, {topic:'Setter-based dependency injection'}]},
{id: 'module6', module: 'Module 06', title: 'Spring AOP', topics: [{topic:'AOP with Spring Framework'}, {topic:'AOP Terminologies'}]},
{id: 'module7', module: 'Module 07', title: 'Spring DAO', topics: [{topic:'Spring Data Access Object'}, {topic:'JDBC Template'}]},
{id: 'module8', module: 'Module 08', title: 'Transaction Management', topics: [{topic:'Local vs. Global Transactions'}, {topic:'Programmatic vs. Declarative'}]},
{id: 'module9', module: 'Module 09', title: 'Spring MVC', topics: [{topic:'Model'}, {topic:'View'}, {topic:'Controller'}]},
{id: 'module10', module: 'Module 10', title: 'Spring REST APIs', topics: [{topic:'REpresentational State Transfer'}]},
{id: 'module11', module: 'Module 11', title: 'Spring Security', topics: [{topic:'Spring Security Introduction'},{topic:'Security Custom Login Form'},{topic:'Spring Security with JWT'}]},
{id: 'module12', module: 'Module 12', title: 'Spring OXM', topics: [{topic:'Spring with JAXB'},{topic:'Spring with Xstream'}]},
{id: 'module13', module: 'Module 13', title: 'Spring Java Mail', topics: [{topic:'Spring Java Mail'}]},
{id: 'module14', module: 'Module 14', title: 'Spring Quartz Scheduler', topics: [{topic:'Spring Quartz Scheduler'}]}
];
const hibernateModules = [{ id: 'module15', module: 'Module 15', title: 'Hibernate Overview', topics: [{topic:'What is Hibernate?'}, {topic:'Why to learn Hibernate?'}]},
{id: 'module16', module: 'Module 16', title: 'Hibernate Architecture', topics: [{topic:'Hibernate Architecture'}]},
{id: 'module17', module: 'Module 17', title: 'Hibernate Environment Setup', topics: [{topic:'Hibernate Environment Setup'}]},
{id: 'module18', module: 'Module 18', title: 'Hibernate Mapping', topics: [{topic:'Collections Mappings'}, {topic:'Association Mappings'}, {topic:'Component Mappings'}]},
{id: 'module19', module: 'Module 19', title: 'Transaction Management', topics: [{topic:'Transaction Management'}]},
{id: 'module20', module: 'Module 20', title: 'Hibernate Query Language', topics: [{topic:'Hibernate Query Language(HQL)'}, {topic:'Advantage of HQL'}, {topic:'Example of HQL'}]},
{id: 'module21', module: 'Module 21', title: 'Hibernate Named Query', topics: [{topic:'Hibernate Named Query - by annotation'}, {topic:'Hibernate Named Query - by mapping file'}]},
{id: 'module22', module: 'Module 22', title: 'Hibernate Criteria', topics: [{topic:'Hibernate Criteria API'}, {topic:'Example of Criteria'}]},
{id: 'module23', module: 'Module 23', title: 'Hibernate Caching', topics: [{topic:'Hibernate Caching'}, {topic:'Type of Hibernate Caching'}, {topic:'Detailed explaination of First Level Cache'}, {topic:'Detailed explaination of Second Level Cache'}]},
{id: 'module24', module: 'Module 24', title: 'Hibernate and Spring Integration', topics: [{topic:'Hibernate and Spring Integration'}]}
];

const reactModules = [{ id: 'module25', module: 'Module 25', title: 'React Introduction', topics: [{topic:'What is React?'}, {topic:'Why to learn React?'}]},
{id: 'module26', module: 'Module 26', title: 'React Installation', topics: [{topic:'Pre-requisite for ReactJS'},{topic:'Ways to install ReactJS'}]},
{id: 'module27', module: 'Module 27', title: 'React Features', topics: [{topic:'JSX'}, {topic:'Components'},{topic:'One-way Data Binding'}, {topic:'Virtual DOM'}, {topic:'Simplicity'}, {topic:'Performance'}]},
{id: 'module28', module: 'Module 28', title: 'React JSX', topics: [{topic:'Why use JSX?'}, {topic:'JSX Attributes'}, {topic:'JSX Comments'}, {topic:'JSX Styling'}]},
{id: 'module29', module: 'Module 29', title: 'React Components', topics: [{topic:'Functional Components'}, {topic:'Class Components'}]},
{id: 'module30', module: 'Module 30', title: 'React State and Props', topics: [{topic:'React State'}, {topic:'React Props'}]},
{id: 'module31', module: 'Module 31', title: 'Component Life Cycle', topics: [{topic:'Initial Phase'}, {topic:'Mounting Phase'}, {topic:'Updating Phase'}, {topic:'Unmounting Phase'}]},
{id: 'module32', module: 'Module 32', title: 'React Styling', topics: [{topic:'CSS stylesheet'}, {topic:'Inline styling'}, {topic:'CSS Modules'}, {topic:'Styled component'}, {topic:'Sass stylesheet'}]},
{id: 'module33', module: 'Module 33', title: 'React Events', topics: [{topic:'React Events'}]},
{id: 'module34', module: 'Module 34', title: 'React Routing', topics: [{topic:'Install React Router'}, {topic:'Uses of React Route'}]},
{id: 'module35', module: 'Module 35', title: 'Build and Deploy', topics: [{topic:'How to Build an Application'}, {topic:'How to Deploy an Application on server'}]}];

const courseDescription = "This course offers hands-on experience with Java, Spring, Hibernate, REST APIs, DataBase and ReactJS which will make you to be a Java Full Stack Developer. It contains all the modules of these skills to make you expertise in Java world. All the learning would be practically not just theoretically. It would be covering the DevOps concepts(Build and deploy your developed applications).";

const JavaFullStackDeveloper = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="row">
						<div className="col-lg-12 header">
							<h2>Java Full Stack Developer</h2>
							<hr/>
						</div>
					</div>
					<CourseDescription courseDesc={courseDescription} enquiryFor="Enquiry for Java Full Stack Developer"
						courseModuleCount="35" lectures="60" hours="120" price="20,000" offerPrice="15,000" />
				</div>
			</div>
			<div className="row paddingTop20">
				<div className="col-lg-12 spanCard">
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>	
						<div className="col-lg-8">
							<p>
								<h4>What You Need</h4><hr/>
								<ul>
									<li>Java 1.8 or later</li>
									<li>A favorite text editor or IDE, i.e. Eclipse, IntelliJ</li>
									<li>Gradle or Maven</li>
									<li>Nodejs</li>
									<li>ReactJS</li>
								</ul>	
							</p>
						</div>
					</div>
					<div className="row paddingBottom20 textColorDarkGreen">
						<div className="col-lg-2"></div>	
						<div className="col-lg-8">
							<p>
								<h4>What is Java Full Stack Developer?</h4><hr/>
								A <b>Java Full Stack Developer</b> is a developer who has expertise and deep knowledge of frameworks 
								and tools used in Java full stack development like <b>Core Java, Spring, Hibernate, REST APIs, Database, ReactJS</b> etc.
								<br/>
								A Full Stack Java developer can build whole Java applications including front end, back-end, database,
								APIs, server and version control.
									
							</p>
						</div>
					</div>
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<p>
								<h4>Why to become Java Full Stack Developer?</h4><hr/>
								Here are the reasons why you should be a Java Full Stack Developer:
								<ul>
									<li>Java Full Stack Developers are in high demand because they play a role of front-end and back-end deveper</li>
									<li>The Unstoppable Growth</li>
									<li>Your productivity will improve once you become full stack developer</li>
									<li>You would be having flexibility to work on Front-end/Back-end</li>
									<li>You would be knowing about all different skills like <b>Spring, Hibernate, REAT APIs, Database, ReactJS</b> etc.</li>
								</ul>
							</p>
		
						</div>
					</div>
						
					<div className="row paddingBottom20 textColorDarkGreen">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<p>
								<h4>What you will be learning here</h4><hr/>
								<ul>
									<li><b>Spring Framework</b> with most of modules like Core, IoC, AOP, DAO, MVC, REST, Security, Test etc.</li>
									<li><b>Hibernate Framework</b> - Hibernate Mapping, HQL, Criteria, Caching</li>
									<li><b>REST APIs</b></li>
									<li><b>Database</b> like MySQL, H2 DB</li>
									<li><b>ReactJS</b> for front-end development</li>									
									<li>Create a project from scratch, build and deploy it on server</li>
								</ul>
							</p>
		
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">	
					<div className="row paddingTop20 paddingBottom20">
						<div className="col-lg-12">
							<span className="pCenter textColorPink"><h3>Course Content </h3></span>
							<div className="accordion" id="accordionExample">
								<div className="card courseContentCard" >
									<div className="card-body">
										<div className="row">
											
											{springModules.map((module)=>
												<CourseModule id={module.id} key={module.id} module={module.module} title={module.title} topics={module.topics} />
												
											)}
											{hibernateModules.map((module)=>
												<CourseModule id={module.id} key={module.id} module={module.module} title={module.title} topics={module.topics} />
												
											)}
											{reactModules.map((module)=>
												<CourseModule id={module.id} key={module.id} module={module.module} title={module.title} topics={module.topics} />
												
											)}
									
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
	)
}

export default JavaFullStackDeveloper;
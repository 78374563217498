import Logo from './../../images/SpringBootLogo.png';

const SpringBootDevOps = ()=> {
    return (
        <div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'padding-right': '10px', 'height': '40px', 'width': '40px' }} />
									Spring Boot - DevOps
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								We've seen that incremental development with faster feedback has become the cornerstone of software delivery 
								today. But how do we achieve that? While traditional agile methodologies take us to a reasonable point, 
								is it still not ideal.
								<br/><br/>
								<b>Agile methodologies</b> keep refining themselves as they continuously strive to break silos.
								<br/><br/>
								Traditionally, we always had different teams that were responsible for developing and delivering software. 
								These teams often operated in their silos. This effectively translated into a much longer feedback cycle, 
								which is not something we desire with agile methodologies.
								<br/>
								So, it doesn't require a lot of reasoning to understand that well-integrated, cross-functional agile teams
								 are much better suited to deliver their objectives. 
								 <br/><br/>
								 <b>DevOps</b> is the practice that encourages communication, 
								 collaboration, integration, and automation between software development and operations teams. 
								 <br/>
								 This better enables us to realize incremental development with faster feedback.
								
								<br/><br/>
								<h6>DevOps Implementation</h6>
								All developers follow agile DevOps practices while developing solutions in Java Spring Boot which 
								includes Deployment of code using <b>CICD</b>, maintaining repositories in versioning, Deployment of code using 
								release pipeline and developing solutions which can leverage the DevOps/Cloud offerings for a continuous 
								development to perform the Best.
								<br/><br/>
								We use multiple DevOps tools such as below:
								<ul>								
									<li>Cloud - AWS, Azure</li>
									<li>Code Repo - git, bitbucket, SNV, stash</li>
									<li>Performance Test - JMeter, BlazeMeter</li>
									<li>Automation Server - Jenkins, Bamboo, Azure DevOps</li>
									<li>Build tool - Maven, Gradle</li>
									<li>Containerization - Docker, Kubernetes, AKS, EKS</li>
									<li>Testing - unit, Mockito, Jasmine, Mocha, Karma, Selenium, etc</li>
									<li>Code Quality - SonarQube</li>
									<li>Security/Risk Analysis - BlackDuck</li>
									<li>Artifactory - Jfrog</li>
								</ul>


						</div>
							
						</div>
					</div>
					<div className="col-lg-12 paddingTop20">
						<span className="textColorPink">
							<b>Disclaimer: </b>
							Content of this page has been referred from <a href="https://spring.io/" target="_blank" rel="noreferrer">Spring.io</a>.
						</span> 
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
        
    );
}

export default SpringBootDevOps;

import {CourseModule} from './../common/course-module';
import { CourseDescription } from './../common/course-description';

const javaModules = [{ id: 'module1', module: 'Module 01', title: 'Microservices Architecture', topics: [{ topic: 'What is Microservices?' }, { topic: 'Monolithic Architecture' }, { topic: 'Microservices Architecture' }] },
{ id: 'module2', module: 'Module 02', title: 'Microservices Benefits', topics: [{ topic: 'Benefits of Microservices' }] },
{ id: 'module3', module: 'Module 03', title: 'Microservices Challenges', topics: [{ topic: 'Bounded Context' }, { topic: 'Dynamic Scale up and Scale Down' }, { topic: 'Monitoring' }, { topic: 'Fault Tolerance' }, { topic: 'Cyclic dependencies' }, { topic: 'DevOps Culture' }] },
{ id: 'module4', module: 'Module 04', title: 'Microservices Infrastructure', topics: [{ topic: 'Microservices Infrastructure' }] },
{ id: 'module5', module: 'Module 05', title: 'Spring Cloud', topics: [{ topic: 'Introduction to Spring Cloud' }, { topic: 'Features of Spring Cloud' }, { topic: 'Getting Started with Spring Cloud' }] },
{ id: 'module6', module: 'Module 06', title: 'Spring Cloud Gateway', topics: [{ topic: 'Introduction to Spring Cloud Gateway' }, { topic: 'Feature of Spring Cloud Gateway' }, { topic: 'Getting Started with Spring Cloud Gateway' }] },
{ id: 'module7', module: 'Module 07', title: 'Spring Cloud Config Server', topics: [{ topic: 'Introduction to Spring Cloud Config Server' }, { topic: 'Feature of Spring Cloud Config Server' }, { topic: 'Getting Started with Spring Cloud Config Server' }] },
{ id: 'module8', module: 'Module 08', title: 'Netflix Hystrix Circuit Breaker', topics: [{ topic: 'Introduction to Netflix Hystrix Circuit Breaker' }, { topic: 'Feature of Netflix Hystrix Circuit Breaker' }, { topic: 'Getting Started with Netflix Hystrix Circuit Breaker' }] },
{ id: 'module9', module: 'Module 09', title: 'Spring Cloud Sleuth with Zipkin', topics: [{ topic: 'Introduction to Spring Cloud Sleuth with Zipkin' }, { topic: 'Feature of Spring Cloud Sleuth with Zipkin' }, { topic: 'Getting Started with Spring Cloud Sleuth with Zipkin' }] },
{ id: 'module10', module: 'Module 10', title: 'Kafka Event Streaming', topics: [{topic:'Kafka Introduction'},{topic:'Kafka Consumer'},{topic:'Kafka Publisher'}]},
{ id: 'module11', module: 'Module 11', title: 'Build and Deploy', topics: [{topic:'How to Build an application'}, {topic:'How to Deploy an Application on server'}]}
];

const courseDescription = "This Microservices course gives you a practical and actionable overview of topics such as design, development and testing of microservices, implementing security, springboot, analysis of logs, design patterns etc. It contains complete industrial Microservices components to become master in Microservices with examples.";

const PlMicroservices = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="row">
						<div className="col-lg-12 header">
							<h2>Microservices with Spring Boot</h2>
							<hr />
						</div>
					</div>
					<CourseDescription courseDesc={courseDescription} enquiryFor="Enquiry for Microservices with Spring Boot"
						courseModuleCount="11" lectures="30" hours="60" price="15,000" offerPrice="10,000" />

					</div>
			</div>
			<div className="row paddingTop20">
				<div className="col-lg-12 spanCard">
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>	
						<div className="col-lg-8">
							<p>
								<h4>What You Need</h4><hr/>
								<ul>
									<li>Java 1.8 or later</li>
									<li>A favorite text editor or IDE, i.e. Eclipse, IntelliJ</li>
									<li>Gradle or Maven</li>
								</ul>	
							</p>
						</div>
					</div>
					<div className="row paddingBottom20 textColorDarkGreen">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">	
							<p>
								<h4>Why Learn Microservices?</h4><hr/>
								Here are the reasons why you should learn Microservices:
								<ul>
									<li>Microservices is an approach to software architecture that builds a complex application from multiple small components which each perform a single function.</li>
									<li>Microservices resolves organizational based issues, making it easy to debug and test applications.</li>
									<li>With the help of Microservices, continuous delivery, testing process and ability to deliver error-free applications improve drastically.</li>
									
								</ul>
							</p>

						</div>
					</div>
						
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<p>
								<h4>What you will be learning here -</h4><hr/>
								<ul>
									<li>Monolithic Architecture</li>
									<li>Understanding about Microservices Architecture</li>
									<li>Get to know about Spring Cloud and much more</li>
									<li>Implementing security in Microservices</li>
									<li>Implement the Kafka event streaming</li>
									<li>You will implement Fault Tolerance for microservices with Hystrix</li>
									<li>You will understand how to monitor RESTful Services with Spring Boot Actuator</li>
									<li>You will learn to implement Distributed tracing for microservices with Spring Cloud Sleuth and Zipkin</li>
									<li>Create different Microservice projects from scratch, build and deploy it on server</li>

								</ul>
							</p>

						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">	
					<div className="row paddingTop20 paddingBottom20">
						<div className="col-lg-12">
							<p className="pCenter">
								<h4>Course Content</h4>
							</p>
							<div className="accordion" id="accordionExample">
								<div className="card courseContentCard" >
									<div className="card-body">
										<div className="row">
											{javaModules.map((module) =>
												<CourseModule id={module.id} key={module.id} module={module.module} title={module.title} topics={module.topics} />

											)}

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
	);
}

export default PlMicroservices;
import Logo from './../../images/SpringBootLogo.png';

const SpringBootMVC = ()=> {
    return (
        <div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'padding-right': '10px', 'height': '40px', 'width': '40px' }} />
									Spring Boot - MVC
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								A Spring Boot MVC is a Java framework which is used to build web applications. 
								It follows the <b>Model-View-Controller</b> design pattern. It implements all the basic features of a 
								core spring framework like Inversion of Control, Dependency Injection.
								<br/><br/>
								A Spring MVC provides an elegant solution to use MVC in spring framework by the help of <b>DispatcherServlet</b>. 
								Here, DispatcherServlet is a class that receives the incoming request and maps it to the right resource such as controllers, models, and views.
								<ul>
									<li><b>Model </b>- A model contains the data of the application. A data can be a single object or a collection of objects.</li>
									<li><b>Controller</b> - A controller contains the business logic of an application. Here, the @Controller annotation is used to mark the class as the controller.</li>
									<li><b>View</b> - A view represents the provided information in a particular format. Generally, JSP+JSTL is used to create a view page. Although spring also supports other view technologies such as Apache Velocity, Thymeleaf and FreeMarker.</li>
									<li><b>Front Controller</b> - In Spring Web MVC, the DispatcherServlet class works as the front controller. It is responsible to manage the flow of the Spring MVC application.</li>
								</ul>								
								
								<h6>Advantages of Spring Boot MVC Framework</h6>
								Let's see some of the advantages of Spring Boot MVC Framework:-
								<ul>
									<li><b>Separate roles </b>- The Spring MVC separates each role, where the model object, controller, command object, view resolver, DispatcherServlet, validator, etc. can be fulfilled by a specialized object.</li>
									<li><b>Light-weight </b>- It uses light-weight servlet container to develop and deploy our application.</li>
									<li><b>Powerful Configuration</b> - It provides a robust configuration for both framework and application classes that includes easy referencing across contexts, such as from web controllers to business objects and validators.</li>
									<li><b>Rapid development</b> - The Spring MVC facilitates fast and parallel development.</li>
									<li><b>Reusable business code</b> - Instead of creating new objects, it allows us to use the existing business objects.</li>
									<li><b>Easy to test</b> - In Spring, generally we create JavaBeans classes that enable us to inject test data using the setter methods.</li>
									<li><b>Flexible Mapping</b> - It provides the specific annotations that easily redirect the page.</li>
								</ul>
								
								<h6>Entity Classes</h6>
								Lets take an example that we have to store information about developers and their skills.
								 So, we will need to define two entity classes: 'Developer' and 'Skill'.
								<br/><br/>
								<h6>Repository Classes</h6>
								With JPA we can define a very useful DeveloperRepository interface and SkillRepository interface, which allow for easy CRUD operations. 
								These interfaces will allow us to access stored developers and skills through simple method calls.
								<br/><br/>
								<h6>Controller Classes</h6>
								The DispatcherServlet delegates the request to the controllers to execute the functionality specific to it. 
								The <b>@Controller</b> annotation indicates that a particular class serves the role of a controller i.e. DeveloperController, SkillController. 
								<br/>
								The <b>@RequestMapping</b> annotation is used to map a URL to either an entire class or 
								a particular handler method.

						</div>
							
						</div>
					</div>
					<div className="col-lg-12 paddingTop20">
						<span className="textColorPink">
							<b>Disclaimer: </b>
							Content of this page has been referred from <a href="https://spring.io/" target="_blank" rel="noreferrer">Spring.io</a>.
						</span> 
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
        
    );
}

export default SpringBootMVC;

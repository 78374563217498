import Logo from './../../images/SpringBootLogo.png';

const SpringBootPersistence = ()=> {
    return (
        <div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'padding-right': '10px', 'height': '40px', 'width': '40px' }} />
									Spring Boot - Persistence
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								<h6>What is JPA(Java Persistence API)?</h6>
								Spring Boot JPA is a Java specification for managing relational data in Java applications. 
								It allows us to access and persist data between Java object/ class and relational database. 
								<br/>
								JPA follows Object-Relation Mapping (ORM). It is a set of interfaces. 
								It also provides a runtime EntityManager API for processing queries and transactions on the objects 
								against the database. It uses a platform-independent object-oriented query language JPQL 
								(Java Persistent Query Language).
								<br/>
								JPA is not a framework. It defines a concept that can be implemented by any framework.
								<br/><br/>
								<h6>Why should we use JPA? </h6>
								JPA is simpler, cleaner, and less labor-intensive than JDBC, SQL, and hand-written mapping. 
								JPA is suitable for non-performance oriented complex applications.
								<br/> 
								The main advantage of JPA over JDBC is that, in JPA, data is represented by objects and classes 
								while in JDBC data is represented by tables and records. It uses POJO to represent persistent data 
								that simplifies database programming.
								<br/>
								<br/>
								<h6>JPA Features</h6>
								There are following features of JPA:
								<ul>
									<li>It is a powerful repository and custom object-mapping abstraction.</li>
									<li>It supports for cross-store persistence. It means an entity can be partially 
									stored in MySQL and Neo4j (Graph Database Management System).</li>
									<li>It dynamically generates queries from queries methods name.</li>
									<li>The domain base classes provide basic properties.</li>
									<li>It supports transparent auditing.</li>
									<li>Possibility to integrate custom repository code.</li>
									<li>It is easy to integrate with Spring Framework with the custom namespace.</li>
								</ul>
								
								<h6>Difference between JPA and Hibernate</h6>
								<table className="table table-bordered">
									<thead><tr><th>JPA</th><th>Hibernate</th></tr></thead>
									<tbody><tr>
										<td>JPA is a Java specification that is used to access, manage, and persist data between Java object and relational database. It is a standard approach for ORM.</td>
										<td>It is a lightweight, open-source ORM tool that is used to store Java objects in the relational database system. It is a provider of JPA. It follows a common approach provided by JPA.</td>
									</tr>
									</tbody>
								</table>		
									
								<h6>Entity</h6>
								we use JPA when we annotate our model classes with <b>@Entity</b>, and when we annotate model fields using annotations like 
								<b>@Id</b> and <b>@OneToMany</b>. These annotations are part of the JPA standard. We can think of the main functionality of our data layer 
								as being laid out by JPA.
								<br/><br/>
								<h6>CrudRepository</h6>
								Spring Boot provides an interface called <b>CrudRepository</b> that contains methods for CRUD operations. 
								It is defined in the package <b>org.springframework.data.repository</b>. It extends the Spring Data <b>Repository</b> interface. 
								It provides generic Crud operation on a repository. If we want to use <b>CrudRepository</b> in an application, 
								we have to create an interface and extend the <b>CrudRepository</b>.

						</div>
							
						</div>
					</div>
					<div className="col-lg-12 paddingTop20">
						<span className="textColorPink">
							<b>Disclaimer: </b>
							Content of this page has been referred from <a href="https://spring.io/" target="_blank" rel="noreferrer">Spring.io</a>.
						</span> 
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
        
    );
}

export default SpringBootPersistence;

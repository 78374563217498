import Logo from './../../images/SpringBootLogo.png';

const SpringBootCustomization = ()=> {
    return (
        <div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'padding-right': '10px', 'height': '40px', 'width': '40px' }} />
									Spring Boot - Customization
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								<h6>Custom Spring Boot Starters</h6>
								The Spring Boot provide starters for most of the popular open source projects, 
								but we are not limited to these.
								<br/>
								We can also write our own custom starters. If we have an internal library for use within our organization, 
								it would be a good practice to write our own starter for Spring Boot projects.
								<br/><br/>
								These starters enable developers to avoid lengthy configuration and quickly jumpstart their development. 
								However, with a lot of things happening in the background, it sometimes becomes difficult to understand 
								how an annotation or just including a dependency in the pom.xml/build.gradle enables so many features.
								<br/><br/>
								<h6>Custom Banner Generator</h6>
								We need to create the custom banner which will be displayed at the time of application start-up time. 
								We can create the custom banner from scratch.
								
								we need to create a file named banner.txt in the src/main/resources directory and paste the banner 
								content (which we want to display) into it.
								<br/>
								Point to note here is that banner.txt is the default expected banner file name, which Spring Boot uses. 
								However, if we want to choose any other location or another name for the banner, we need to set the 
								spring.banner.location property in the application.properties file:
								<br/>
								<p className="pCode">spring.banner.location = classpath: /path/to/banner/bannername.txt</p>
						
								We can also use images as banners too. Same as with banner.txt, Spring Boot expects the banner image's 
								name as banner.gif. Additionally, we can set different image properties such as height, width, etc. 
								in the application.properties.
								
								<br/><br/>
								<h6>Change Server Port</h6>
								If we have an application deployed in different environments, we may want it to run on different ports on each system.

								We can easily achieve this by combining the property files approach with Spring profile for each environment.
								<br/>
								For example, we'll have an application-dev.properties file with this content:
								<br/>
								<p className="pCode">server.port = 8081</p>
								
								Then we'll add another application-qa.properties file with a different port:
								<br/>
								<p className="pCode">server.port = 8082</p>
								There are other options also available to achieve this.

								<br/><br/>
								<h6>Custom Error Page</h6>
								Disabling the Whitelabel Error Page - 
								We can disable the white label error page entirely by setting the server.error.whitelabel.enabled property to false:
								<br/>
								<p className="pCode">server.error.whitelabel.enabled = false</p>
						</div>
							
						</div>
					</div>
					<div className="col-lg-12 paddingTop20">
						<span className="textColorPink">
							<b>Disclaimer: </b>
							Content of this page has been referred from <a href="https://spring.io/" target="_blank" rel="noreferrer">Spring.io</a>.
						</span> 
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
        
    );
}

export default SpringBootCustomization;

export const Note = (props) => {
	let noteClass = 'note';
	if(props.noteClass != undefined) {
		noteClass = props.noteClass	
	}
	
	return (
		<p className={noteClass}>
			<b>Note:-</b><br/> {props.text} 
		</p>
	)
};
import SkypeCourse from './../../images/LiveCourseImg.png';
import { SocialIcon } from 'react-social-icons';

export const CourseDescription = (props) => {
	var emailAddr = "mailto:dootjavaacademy@gmail.com"
	if(props.enquiryFor !== undefined) {
		emailAddr = "mailto:dootjavaacademy@gmail.com?subject="+props.enquiryFor
	}
	
	return (
		<div className="row animate fadeInUp one">
			<div className="col-lg-12">
				<span className="textColorRed pull-right">
								
					<b><i>Live Classes @ </i></b> <span className="aFooter"><i class="fa fa-video-camera"></i> Google Meet</span>
					&nbsp; <b>/ </b>Skype <SocialIcon url="https://join.skype.com/invite/pugQKLdfgXG5" network="soundcloud" bgColor="#6f42c1" style={{ height: 25, width: 25 }} /> 
					<a className="aFooter" href="https://join.skype.com/invite/pugQKLdfgXG5"> Doot Java Academy</a>
				</span>
			</div>
			<div className="col-lg-12">
						
				<div className="card courseCard" >
					<div className="row">
						<div className="col-md-4">
							<img src={SkypeCourse} className="card-img" style={{ 'height': '100%'  }} alt="not found" />
						</div>
						<div className="col-md-8">
							<div className="card-body">
								<h5 className="card-title">Course Description</h5>
								<p className="card-text">
									{props.courseDesc}
									<br/><br/>
									This course content is divided into <b>{props.courseModuleCount} modules.</b>
								</p>
								
								{props.price ? 
									<div className="pull-right priceTag">
										<span className="oldPriceTag"><i class="fa fa-rupee"></i> <b>{props.price}/-</b></span>
										<br/>
										<span className="offerPriceTag"><i class="fa fa-rupee"></i> <b>{props.offerPrice}/-</b></span>
									</div>
									: null 
								}
								<br/>		
								<p className="pull-left">
									<h6><b>{props.lectures}</b> Lectures</h6>
								</p>
								<p className="pull-right">
									<h6><b>{props.hours}</b> Hours &nbsp;</h6>
								</p>
								<br/>
								
								<p className="pull-right"> 
									<span className="textColorRed">For an enquiry, write us @ </span> 
									<SocialIcon url={emailAddr} bgColor="#6f42c1" style={{ height: 25, width: 25 }} /> 
								 	<a className="aFooter" href={emailAddr}> DootJavaAcademy@gmail.com</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

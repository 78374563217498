import {CourseModule} from './../common/course-module';
import { CourseDescription } from './../common/course-description';

const javaModules = [{ id: 'module1', module: 'Module 01', title: 'Java Introduction', topics: [{ topic: 'What is Java?' }, { topic: 'History of Java' }, { topic: 'Features of Java - Why Java?' }, { topic: 'Setting Java Environment' }, { topic: 'Introduction about JVM' }] },
{ id: 'module2', module: 'Module 02', title: 'Java Basics', topics: [{ topic: 'Java Sample Program' }, { topic: 'Variables and Data Types' }, { topic: 'Statements and Loops' }, { topic: 'Operators' }] },
{ id: 'module3', module: 'Module 03', title: 'Java OOPs Concepts', topics: [{ topic: 'Objects and Classes' }, { topic: 'Constructor' }, { topic: 'Methods in Java' }, { topic: 'Encapsulation' }, { topic: 'Abstraction' }, { topic: 'Inheritance' }, { topic: 'Polymorphism' }, { topic: 'Interface - class vs interface' }] },
{ id: 'module4', module: 'Module 04', title: 'Java String Handling', topics: [{ topic: 'Introduction to String' }, { topic: 'Functions in String class' }, { topic: 'String Buffer and Builder' }, { topic: 'String Tokenizer' }] },
{ id: 'module5', module: 'Module 05', title: 'Java Exception Handling', topics: [{ topic: 'Introduction to Exceptions' }, { topic: 'Try, catch and finally blocks' }, { topic: 'Throw and throws' }, { topic: 'Custom Exception ' }] },
{ id: 'module6', module: 'Module 06', title: 'Java MultiThreading', topics: [{ topic: 'What is MultiThreading' }, { topic: 'Thread and Runnable' }, { topic: 'Thread life cycle' }, { topic: 'Synchronization' }, { topic: 'Wait, notify and notifyAll' }] },
{ id: 'module7', module: 'Module 07', title: 'Java File Handling', topics: [{ topic: 'Java Files' }, { topic: 'Create/Write Files' }, { topic: 'Read Files' }, { topic: 'Delete Files' }] },
{ id: 'module8', module: 'Module 08', title: 'Collection Framework', topics: [{ topic: 'Introduction to Collection framework' }, { topic: 'Collection Interfaces and Classes' }, { topic: 'List, Set and Map' }] },
{ id: 'module9', module: 'Module 09', title: 'Java Wrapper Class', topics: [{ topic: 'What is wrapper class' }, { topic: 'List of different wrapper class' }] },
{ id: 'module10', module: 'Module 10', title: 'Java Reflection', topics: [{ topic: 'Introduction to Reflection' }, { topic: 'Reflection classes' }] },
{ id: 'module11', module: 'Module 11', title: 'JDBC', topics: [{ topic: 'What is JDBC?' }, { topic: 'Steps to connect to Database' }, { topic: 'Perform CRUD operations on Database table' }] },
{ id: 'module12', module: 'Module 12', title: 'Java Memory Management', topics: [{ topic: 'Garbage Collection in Java' }, { topic: 'Static variable in Java' }, { topic: 'Memory Allocation' }] },
{ id: 'module13', module: 'Module 13', title: 'Functional Interface', topics: [{ topic: 'Functional Interface' }, { topic: 'Example on functional interface' }] },
{ id: 'module14', module: 'Module 14', title: 'Default Methods', topics: [{ topic: 'Default Methods' }, { topic: 'Example on default methods' }] },
{ id: 'module15', module: 'Module 15', title: 'Lambda Expression', topics: [{ topic: 'Lambda Expression' }, { topic: 'Different ways of using lambda expression' }, { topic: 'Example on lambda expression' }] },
{ id: 'module16', module: 'Module 16', title: 'Streams APIs', topics: [{ topic: 'Definition of Stream' }, { topic: 'Static variable in Java' }, { topic: 'Memory Allocation' }] },
{ id: 'module17', module: 'Module 17', title: 'Date Time API Changes', topics: [{ topic: 'Garbage Collection in Java' }, { topic: 'Static variable in Java' }, { topic: 'Memory Allocation' }] },
{ id: 'module18', module: 'Module 18', title: 'Java Programs', topics: [{ topic: 'Java Programs for each and every topic' }] },
];

const courseDescription = "This Core Java Developer Course syllabus is a comprehensive study of core java and java 8. It contains complete industrial Java topics to learn the java programming language from scratch with examples.";

const CoreJavaDeveloper = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="row">
						<div className="col-lg-12 header">
							<h2>Core Java Developer</h2>
							<hr />
						</div>
					</div>
					<CourseDescription courseDesc={courseDescription} enquiryFor="Enquiry for Core Java Developer"
						courseModuleCount="18" lectures="30" hours="60" price="7,500" offerPrice="5,000" />

				</div>
			</div>
			<div className="row paddingTop20">
				<div className="col-lg-12 spanCard">
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>	
						<div className="col-lg-8">
							<p>
								<h4>What You Need</h4><hr/>
								<ul>
									<li>Java 1.8 or later</li>
									<li>A favorite text editor or IDE, i.e. Eclipse, IntelliJ</li>
								</ul>	
							</p>
						</div>
					</div>
					
					<div className="row paddingBottom20 textColorDarkGreen">
						<div className="col-lg-2"></div>	
						<div className="col-lg-8">
							<p>
								<h4>Why Learn Java Programming?</h4><hr/>
								Here are the reasons why you should learn Java:
								<ul>
									<li>Java is very easy to learn</li>
									<li>Java developers are in high demand and easy to get job as a Core Java Developer</li>
									<li>It has a good collection of open-source libraries</li>
									<li>Java is free</li>
								</ul>
							</p>
						</div>
					</div>
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">	
							<p>
								<h4>What you will be learning here</h4><hr/>
								<ul>
									<li>Java Environment setup</li>
									<li>Understanding about JVM</li>
									<li>Get to know about IDEs</li>
									<li>Complete understanding on OOPs</li>
									<li>Explore Java 8 features</li>
									<li>One minor project on Core Java using Java OOPs and JDBC</li>
								</ul>
							</p>
						</div>
					</div>
				</div>	
			</div>
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">			
					<div className="row paddingTop20 paddingBottom20">
						<div className="col-lg-12">
							<span className="pCenter textColorPink"><h3>Course Content </h3></span>
							<div className="accordion" id="accordionExample">
								<div className="card courseContentCard" >
									<div className="card-body">
										<div className="row">
											{javaModules.map((module) =>
												<CourseModule id={module.id} key={module.id} module={module.module} title={module.title} topics={module.topics} />

											)}

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
	);
}

export default CoreJavaDeveloper;
import { Link } from "react-router-dom";

import JavaLogo from './../images/Java8Img.png';
import PiPythonLogo from './../images/PlPythonImg.png';
import HibernateLogo from './../images/HibernateImg.png';
import PlSpringLogo from './../images/PlSpringImg.png';
import PlSpringBootLogo from './../images/PlSpringBootImg.png';
import PlReactJSLogo from './../images/PlReactJSImg.png';
import PlMicroservicesLogo from './../images/PlMicroservicesImg.png';
import JavaImg1 from './../images/JavaImg1.png';

const profestionalCoursesData = [{
	title: 'Core Java Developer',
	image: JavaLogo,
	lectures: "30",
	hours: "60",
	price: "7,500",
	offerPrice: "5,000",
	desc: 'Learn the core Java along with Java 8 feature to become Core Java Developer with hands on examples!',
	link: '/core-java-developer'
},
{
	title: 'Python Developer',
	image: PiPythonLogo,
	lectures: "30",
	hours: "60",
	price: "7,500",
	offerPrice: "5,000",
	desc: 'Step wise learning of Python to become Web Developer along with hands on examples!',
	link: '/python-developer'
},
{
	title: 'Java Full Stack Developer',
	image: PlSpringLogo,
	lectures: "60",
	hours: "120",
	price: "20,000",
	offerPrice: "15,000",
	desc: 'Learn the Spring, Hibernate, REST APIs, DataBase and ReactJS from scratch to become Java Full Stack Developer!',
	link: '/java-full-stack-developer'
},
{
	title: 'Java Backend Developer',
	image: HibernateLogo,
	lectures: "45",
	hours: "90",
	price: "15,000",
	offerPrice: "10,000",
	desc: 'Learn the Spring, Hibernate, REST APIs and DataBase from scratch to become Java Backend Developer!',
	link: '/java-backend-developer'
},
{
	title: 'Frontend Developer - ReactJS/Angular',
	image: PlReactJSLogo,
	lectures: "15",
	hours: "30",
	price: "5,000",
	offerPrice: "4,000",
	desc: 'Step wise learning of ReactJS/Angular to became Frontend Developer!',
	link: '/frontend-developer'
},
{
	title: 'Full Stack - Spring Boot and ReactJS',
	image: PlSpringBootLogo,
	lectures: "45",
	hours: "90",
	price: "15,000",
	offerPrice: "12,000",
	desc: 'Transform your self on Spring Boot as Full Stack Developer - The Quick way to create Java Applications!',
	link: '/springboot'
},
{
	title: 'Microservices with Spring Boot',
	image: PlMicroservicesLogo,
	lectures: "30",
	hours: "60",
	price: "15,000",
	offerPrice: "10,000",
	desc: 'Learn Microservices to compite in the industory with different modules of Spring Boot!',
	link: '/java-microservices'
}
];


const Home = () => {
	return (
		<div className="container-fluid" >
			<div className="row">
				<div className="col-lg-1"></div>
				<div className="col-lg-10 ">
					<div className="row">
						<div className="col-md-6 paddingBottom20 animate fadeInUp one" >
							<div className="card cardShadow bg-dark textColorBg">
								<img src={JavaImg1} style={{'height':'250px','opacity': '.5'}} className="card-img" alt="Java Logo not found" />
								<div className="card-img-overlay">
							    	<h5 className="card-title">Why Learn Java?</h5>
							    	<p className="card-text">
							    		<ul>
							    			<li>Java Has Plenty of Job Opportunities</li>
							    			<li>Java is Easy to Learn</li>
							    			<li>There’s Great Tool Support and Frameworks</li>
							    			<li>Java Has a Rich API</li>
							    			<li>Java is Versatile</li>
							    			<li>Java is Scalable</li>
							    			<li>Java is Open Source</li>
							    		</ul>
									</p>
							  	</div>
							</div>					
						</div>
						<div className="col-md-6 paddingBottom20 animate fadeInUp one" >
							<div className="card cardShadow bg-dark textColorBg">
								<img src={JavaImg1} style={{'height':'250px','opacity': '.5'}} className="card-img" alt="Java Logo not found" />
								<div className="card-img-overlay">
							    	<h5 className="card-title">Why Learn Java from Us?</h5>
							    	<p className="card-text">
							    		<ul>
							    			<li>Having 12+ Years of Experience</li>
							    			<li>Strong Knowledge on Java along with Frameworks</li>
							    			<li>Learn Practically, not Just Theoretically</li>
							    			<li>Always up to Date with Latest Technology Stack</li>
							   				<li>Less Expensive Compared to Others in Market</li>
							    		</ul>
									</p>
							  	</div>
							</div>					
						</div>
					</div>
				</div>
				<div className="col-lg-1"></div>
			</div>
			
			<div className="row">
				<div className="col-lg-1"></div>
				<div className="col-lg-10 header">
					<span className="pCenter textColorPink"><h3> Job Oriented Courses </h3><hr /></span>
				</div>
				<div className="col-lg-1"></div>
			</div>
			<div className="row" >
				<div className="col-lg-1"></div>
				<div className="col-lg-10" >
					<div className="row">
						{profestionalCoursesData.map((data) =>
							<div className="col-md-4 paddingBottom20 animate fadeInUp one" key={data.title}>
								<div className="card homeCard" key={data.title}>
									<img src={data.image} className="card-img-top cardImage" alt="Java Logo not found" />
									<div className="card-body" >
										<h5 className="card-title">{data.title}</h5>
										<p className="card-text">
											{data.desc}
										</p>
										{data.price ? 
											<div className="pull-right priceTag">
												<span className="oldPriceTag"><i class="fa fa-rupee"></i> <b>{data.price}</b></span>
												<br/>
												<span className="offerPriceTag"><i class="fa fa-rupee"></i> <b>{data.offerPrice}</b></span>
											</div>
											: null 
										}
										<br/>
										<p className="pull-left">
											<h6><b>{data.lectures}</b> Lectures</h6>
										</p>
										<p className="pull-right">
											<h6><b>{data.hours}</b> Hours &nbsp;</h6>
										</p>
										
									</div>
									<Link to={data.link} style={{ 'width': '100%' }} className="btn btn-primary btn-custom"> More Details... </Link>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="col-lg-1"></div>
			</div>
			
		</div>
	);
}

export default Home;
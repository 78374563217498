import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';

import ScrollToTop from './components/ScrollToTop';
import MyNavbar from './navbar';
import Page404 from './components/page404';

import Home from './components/home';
import Java8 from './components/java/java-8';
import Java11 from './components/java/java-11';

import JavaFullStackDeveloper from './components/courses/java-full-stack-developer';
import CoreJavaDeveloper from './components/courses/core-java-developer';
import PythonDeveloper from './components/courses/python-developer';
import JavaBackendDeveloper from './components/courses/java-backend-developer';
import FrontendDeveloper from './components/courses/frontend-developer';
import Springboot from './components/courses/springboot';
import CourseMicroservices from './components/courses/microservices';

import Spring from './components/spring/spring';
import Hibernate from './components/hibernate/hibernate';

import SpringBoot from './components/spring_boot/spring-boot';
import SpringBootCustomization from './components/spring_boot/spring-boot-customization';
import SpringBootPersistence from './components/spring_boot/spring-boot-persistence';
import SpringBootRESTAPI from './components/spring_boot/spring-boot-restapi';
import SpringBootMVC from './components/spring_boot/spring-boot-mvc';
import SpringBootTesting from './components/spring_boot/spring-boot-testing';
import SpringBootDevOps from './components/spring_boot/spring-boot-devOps';
import SpringBootCaching from './components/spring_boot/spring-boot-caching';

import WebReactJS from './components/web/reactjs';
import WebAngular from './components/web/angular';
import WebPython from './components/web/python';

import Microservices from './components/microservices/microservices';

import Footer from './components/footer';

import ContactUs from './components/contactUs';

function App() {
	return (
		<Router basename={'/'} >
			<ScrollToTop />
			<div>
				
				<div className="col-lg-12 bannerHeading">
					<span>
						Please 
						<a className="aBanner" 
							href="mailto:dootjavaacademy@gmail.com?subject=Enquiry to join weekend Demo Session">
							&nbsp;Click here&nbsp;
						</a>
						for Weekend Demo Session Details!			
					</span>
				</div>
								
				<MyNavbar />
				<Switch>
					<Route exact path='/' component={Home} />
			
					<Route exact path='/core-java-developer' component={CoreJavaDeveloper} />
					<Route exact path='/python-developer' component={PythonDeveloper} />
					<Route exact path='/java-full-stack-developer' component={JavaFullStackDeveloper} />
					<Route exact path='/java-backend-developer' component={JavaBackendDeveloper} />
					<Route exact path='/frontend-developer' component={FrontendDeveloper} />
					<Route exact path='/springboot' component={Springboot} />
					<Route exact path='/java-microservices' component={CourseMicroservices} />
			
					<Route exact path='/java-8' component={Java8} />
					<Route exact path='/java-11' component={Java11} />
			
					<Route exact path='/spring' component={Spring} />
					<Route exact path='/hibernate' component={Hibernate} />
					
					<Route exact path='/spring-boot-basics' component={SpringBoot} />
					<Route exact path='/spring-boot-customization' component={SpringBootCustomization} />
					<Route exact path='/spring-boot-persistence' component={SpringBootPersistence} />
					<Route exact path='/spring-boot-restAPI' component={SpringBootRESTAPI} />
					<Route exact path='/spring-boot-mvc' component={SpringBootMVC} />
					<Route exact path='/spring-boot-testing' component={SpringBootTesting} />
					<Route exact path='/spring-boot-devOps' component={SpringBootDevOps} />
					<Route exact path='/spring-boot-caching' component={SpringBootCaching} />
					
					<Route exact path='/reactjs' component={WebReactJS} />
					<Route exact path='/angular' component={WebAngular} />
					<Route exact path='/python' component={WebPython} />
					
					<Route exact path='/microservices' component={Microservices} />
					
					<Route exact path='/contact-us' component={ContactUs} />
					
					<Route path='**' component={Page404} />
				</Switch>
				<Footer />
			</div>
		</Router>
	);
};

export default App;
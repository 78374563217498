let styles = {
	color: 'red',
	textAlign: 'center',
	fontStyle: 'italic',
	paddingTop: '20px'
}
const page404 = () => {
	return (
		<div className="container-fluid" style={styles}>
			<h1>Page not found</h1>
		</div>
	)
}

export default page404;

import { Tab, Row, Col, Nav } from 'react-bootstrap';
import JavaLogo from './../../images/JavaLogo.png';
import { FunctionalInterface, DefaultMethods, LambdaExpression, Streams, DateTimeApiChanges } from './java-feature';

const tabsData = [{ eventKey: 'Functional-Interface', event: 'Functional Interface' },
{ eventKey: 'Default-Methods', event: 'Default Methods' },
{ eventKey: 'Lambda-Expression', event: 'Lambda Expression' },
{ eventKey: 'Streams', event: 'Streams' },
{ eventKey: 'Date-Time-API-Changes', event: 'Date/Time API Changes' }];

const Java8 = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={JavaLogo} className="paddingRight20" alt="Java Logo not found" style={{ 'paddingRight': '10px', 'height': '30px', 'width': '30px' }} />
							Learn Java 8</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								Java 8 is a revolutionary release of java development platform.
								It includes a huge upgrade to the Java programming model and a coordinated evolution of the JVM,
								Java language, and libraries. Java 8 includes features for productivity, ease of use, security
							and improved performance. 
							<br />Here are the some of java 8 features –

						</div>
							<div className="col-lg-12">

								<Tab.Container id="left-tabs-example" defaultActiveKey={tabsData[0].eventKey}>
									<Row>
										<Col sm={3}>
											<Nav variant="pills" className="flex-column menuItems">
												{tabsData.map((tabData, index) => {
													const hrefData= '#'+tabData.eventKey;
													return <Nav.Item key={tabData.eventKey}>
																<Nav.Link href={hrefData} eventKey={tabData.eventKey}>{index + 1}.) {tabData.event}</Nav.Link>
															</Nav.Item>
													}
												)}
											</Nav>
										</Col>
										<Col sm={9}>
											<Tab.Content>
												<FunctionalInterface />
												<DefaultMethods />
												<LambdaExpression />
												<Streams />
												<DateTimeApiChanges />
											</Tab.Content>
										</Col>
									</Row>
								</Tab.Container>
							</div>
						</div>
					</div>
				
					<div className="col-lg-12 paddingTop20">
						<span className="textColorPink">
							<b>Disclaimer: </b>
							Content of this page has been referred from <a href="https://www.oracle.com/java/technologies/java-se-glance.html" target="_blank" rel="noreferrer">Oracle</a>.
						</span> 
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
	);
}

export default Java8;

import { Tab, Row, Col, Nav } from 'react-bootstrap';
import Logo from './../../images/PythonLogoImg.png';

const tabsData = [
	{ eventKey: 'Python-Introduction', event: 'Python Introduction' },
	{ eventKey: 'Python-GettingStarted', event: 'Getting Started with Python' },
	{ eventKey: 'Python-Interpreter', event: 'Python Interpreter' },
	{ eventKey: 'Python-ControlFlow', event: 'Control Flow Tools' },
	{ eventKey: 'Python-DataStructure', event: 'Python Data Structure' },
	{ eventKey: 'Python-Modules', event: 'Python Modules' },
	{ eventKey: 'Python-InputOutput', event: 'Python Input and Output' },
	{ eventKey: 'Python-ErrorsExceptions', event: 'Errors and Exceptions' },
	{ eventKey: 'Python-Classes', event: 'Python Classes' },
	{ eventKey: 'Python-StandardLibrary', event: 'Tour of the Standard Library' }
	];

const Python = () => {
	return (
		<div className="container-fluid">
			<div className="row paddingBottom20">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'paddingRight': '10px', 'height': '50px', 'width': '50px' }} />
									Python
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">

								Python is an easy to learn, powerful programming language. 
								It has efficient high-level data structures and a simple but effective approach to 
								object-oriented programming. Python’s elegant syntax and dynamic typing, 
								together with its interpreted nature, make it an ideal language for scripting 
								and rapid application development in many areas on most platforms.
								
							</div>
							<div className="col-lg-12 paddingTop20">

								<Tab.Container id="left-tabs-example" defaultActiveKey={tabsData[0].eventKey}>
									<Row>
										<Col sm={3}>
											<Nav variant="pills" className="flex-column menuItems">
												{tabsData.map((tabData, index) => {
													const hrefData= '#'+tabData.eventKey;
													return <Nav.Item key={tabData.eventKey}>
														<Nav.Link href={hrefData} eventKey={tabData.eventKey}>{index + 1}.) {tabData.event}</Nav.Link>
													</Nav.Item>
													}
												)}
											</Nav>
										</Col>
										<Col sm={9}>
											<Tab.Content>
												<PythonIntroduction />
												<PythonGettingStarted />
												<PythonInterpreter />
												<PythonControlFlow />
												<PythonDataStructure />
												<PythonModules />
												<PythonInputOutput />
												<PythonErrorsExceptions />
												<PythonClasses />
												<PythonStandardLibrary />
											</Tab.Content>
										</Col>
									</Row>
								</Tab.Container>
							</div>
							<div className="col-lg-12 paddingTop20">
								<span className="textColorPink">
									<b>Disclaimer: </b>
									Content of this page has been referred from <a href="https://docs.python.org/3/tutorial/index.html" target="_blank" rel="noreferrer">Python Documentation</a>.
								</span> 
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>

	);
}

export default Python;

const PythonIntroduction = () => {
	return (
		<Tab.Pane id="Python-Introduction" eventKey="Python-Introduction">
			<div id="Python-Introduction">
				<p>
					<h5>Python Introduction</h5>
						Python is a high-level, object-oriented programming language. 
						This means that Python is based around data and is easily understood by humans.
						<br/><br/>

						Earlier, Python was only considered to be useful for automating boring stuff, but now, 
						it is getting used in various domains such as Web Development, App Development, Data Science and Machine Learning.
						<br/> <br/>

						<h6>Why Use Python?</h6>
						Depending on how we plan to use Python, there can be several reasons it’s currently being used 
						in the industry.
						<br/> <br/>						
						Some of the reasons are –
						<br/>
						<ol> 
							<li><h6>Python for Programmers</h6>
							The high level of abstraction offered by Python benefits all programmers. 
							It is highly interactive and has a simple syntax, making it everyone’s favourite.
							</li>
							<li><h6>Python for AI and Machine Learning</h6>
							Because of its stable, flexible and straightforward nature, 
							Python is used extensively in Machine Learning and Artificial Intelligence. 
							It is one of the go-to languages for Data Scientists and Machine Learning 
							practitioners because of the available AI and ML toolkits.
							</li>
							<li><h6>Data Analytics and Data Science</h6>
							Much like AI and ML, the field of Data Analytics and Data Science also see wide use of Python. 
							In the present time, when we are creating more and more data every day, efficient platforms 
							like Python are in huge demand for analysing, manipulating and managing data.
							</li>
							<li><h6>Web Development and Game Development</h6>
							Web Development and Game Development fields are not too far behind in the Python race. 
							Python is extremely efficient for developing quick game prototypes, 
							which offers an edge to most developers.
							</li>
							<li><h6>Highly compatible with all major platforms</h6>
							Python supports all popular operating systems. The same codes can run on different 
							platforms without recompilation.
							</li>
						</ol>
				</p>
			</div>
		</Tab.Pane>
	)
};

const PythonGettingStarted = () => {
	return (
		<Tab.Pane id="Python-GettingStarted" eventKey="Python-GettingStarted">
			<div id="Python-GettingStarted">
				<p>
					<h5>Getting Started with Python</h5>
					This section helps us to get started with Python programming.	
					<br/><br/>
					<h6>Install Python</h6>
					First, <a href="https://www.python.org/downloads/" target="_blank" rel="noreferrer">
					download the latest version of Python </a> from the download page.
					<br/>
					And then, install the downloaded Python installer on our Machine.
					
					<br/><br/>
					<h6>Setting up Visual Studio Code</h6>
					First, go to <a href="https://code.visualstudio.com/" target="_blank" rel="noreferrer">
					 VS Code official </a> website and download the VS code based on our platform (Windows, macOS, or Linux)..
					<br/>
					And then, install the downloaded installer on our Machine.
					<br/>
					Once the installation is completed, we can launch the VS code application.
					<br/><br/>
					<h6>Install Python Extension</h6>
					To make the VS Code work with Python, we need to install the Python extension from the Visual Studio Marketplace.
					
					<br/><br/>
					<h6>Python Hello World</h6>
					Create a new directory called helloworld(or any name can be taken) anywhere in our system e.g., C:\ drive.
					<br/>
					Then, open the helloworld directory in VS Code which we had already installed above.
					<br/>
					Now, create a new app.py file, enter the following code, and save it:
					<br/>
					<p className="pCode">
						<br/>
						<b>&gt;&gt;&gt;</b>print('Hello, World!')
						<br/>&nbsp;
					</p>
					
					The <b>print()</b> is a built-in function that displays a message on the screen. 
					In this example, it’ll show the message 
					<br/>
					<p className="pCode">
						'Hello, World!'
					</p>
				</p>
			</div>
		</Tab.Pane>
	)
};

const PythonInterpreter = () => {
	return (
		<Tab.Pane id="Python-Interpreter" eventKey="Python-Interpreter">
			<div id="Python-Interpreter">
				<p>
					<h5>Python Interpreter</h5>
						Python is an interpreted language. To develop a Python program, 
						you write Python code into a file called source code.
						<br/><br/>
						To execute the source code, you need to convert it to 
						the machine language that the computer can understand. And the Python <b>interpreter</b> turns the source code,
						 line by line, once at a time, into the machine code when the Python program executes.
						<br/><br/>
						Compiled languages like Java and C# use a <b>compiler</b> that compiles the whole source code before 
						the program executes.
						<br/><br/>
						By default, Python source files are treated as encoded in <b>UTF-8</b>. In that encoding, 
						characters of most languages in the world can be used simultaneously in string literals, 
						identifiers and comments — although the standard library only uses <b>ASCII</b> characters for identifiers, 
						a convention that any portable code should follow. 
						<br/>
						To display all these characters properly, your editor must recognize that the file is UTF-8, 
						and it must use a font that supports all the characters in the file.
						<br/><br/>
						To declare an encoding other than the default one, a special comment line should be added 
						as the first line of the file. 
						<br/>The syntax is as follows:
						<br/><br/>
						<p className="pCode">
							# -*- coding: encoding -*-
							<br/>&nbsp;
						</p>
				</p>
			</div>
		</Tab.Pane>
	)
};

const PythonControlFlow = () => {
	return (
		<Tab.Pane id="Python-ControlFlow" eventKey="Python-ControlFlow">
			<div id="Python-ControlFlow">
				<p>
					<h5>Control Flow Tools</h5>
					To decide the flow of our program and make it work exactly how we want it to be, we need flow control.
					<br/><br/>
					<h6>if Statements</h6>
					<p className="pCode">
							<b>&gt;&gt;&gt;</b>if x &lt; 0:<br/>
							&nbsp;&nbsp;&nbsp; x = 0<br/>
							&nbsp;&nbsp;&nbsp; print('Negative changed to zero')<br/>
							elif x == 0:<br/>
							    &nbsp;&nbsp;&nbsp; print('Zero')<br/>
							elif x == 1:<br/>
							    &nbsp;&nbsp;&nbsp; print('One')<br/>
							else:<br/>
							    &nbsp;&nbsp;&nbsp; print('More')<br/>
						</p>
					There can be zero or more elif parts, and the else part is optional. <br/>
					The keyword ‘elif’ is short for ‘else if’, and is useful to avoid excessive indentation.<br/> 
					An if … elif … elif … sequence is a substitute for the switch or case statements found in other languages.
					<br/><br/>
					
					<h6>for Statements</h6>
					The for statement in Python differs a bit from what we have used in C. 
					Rather than always iterating over an arithmetic progression of numbers, 
					or giving the user the ability to define both the iteration step and halting condition (as C),
					<br/>
					 Python’s for statement iterates over the items of any sequence (a list or a string), 
					 in the order that they appear in the sequence.
					<p className="pCode">
						# Measure some strings: <br/>
						<b>&gt;&gt;&gt;</b>words = ['cat', 'window', 'defenestrate']<br/>
						for w in words:<br/>
						    &nbsp;&nbsp;&nbsp;&nbsp;print(w, len(w))<br/>
						<br/>
					</p>
					<p><b>Output:</b><br/>
						cat 3<br/>
						window 6<br/>
						defenestrate 12<br/>
					</p>
					
					<h6>break and continue Statements, and else Clauses on Loops</h6>
					The break statement breaks out of the innermost enclosing for or while loop.
					<br/>
					A for or while loop can include an else clause.
					<br/><br/>
					In a for loop, the else clause is executed after the loop reaches its final iteration.
					<br/>
					In a while loop, it’s executed after the loop’s condition becomes false.
					<br/>
					In either kind of loop, the else clause is not executed if the loop was terminated by a break.	
					<br/><br/>
					
					<h6>Defining Functions</h6>
					We can create a function that writes the Fibonacci series to an arbitrary boundary:
					<br/>
					<p className="pCode">
						<b>&gt;&gt;&gt;</b><b>def</b> fib(n):    # write Fibonacci series up to n <br/>
					    &nbsp;&nbsp;&nbsp;&nbsp;"""Print a Fibonacci series up to n."""<br/>
					    &nbsp;&nbsp;&nbsp;&nbsp;a, b = 0, 1<br/>
					    &nbsp;&nbsp;&nbsp;&nbsp;while a &lt; n:<br/>
					    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    print(a, end=' ')<br/>
					    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    a, b = b, a+b<br/>
					    &nbsp;&nbsp;&nbsp;&nbsp;print()<br/>
					<br/>
					# Now call the function we just defined:<br/>
					fib(2000)<br/>
					<br/>
					</p>
					<b>Output :</b><br/>
					0 1 1 2 3 5 8 13 21 34 55 89 144 233 377 610 987 1597
					<br/>
					The keyword <b>def</b> introduces a function definition. It must be followed by the function name 
					and the parenthesized list of formal parameters. <br/> The statements that form the body of the 
					function start at the next line, and must be indented.
					<br/><br/>
					
					<h6>Lambda Expressions</h6>
					Small anonymous functions can be created with the lambda keyword. 
					This function returns the sum of its two arguments: <b>lambda a, b: a+b</b>.<br/> 
					Lambda functions can be used wherever function objects are required. 
					They are syntactically restricted to a single expression.<br/> 
					Semantically, they are just syntactic sugar for a normal function definition. 
					Like nested function definitions, lambda functions can reference variables from the containing scope:
					<br/>
					<p className="pCode">
						<b>&gt;&gt;&gt;</b>def make_incrementor(n):<br/>
						    &nbsp;&nbsp;&nbsp;&nbsp;return lambda x: x + n<br/>
						
						f = make_incrementor(42)<br/>
						f(0)<br/>
						&nbsp;<b>Output:</b> 42<br/>
						f(1)<br/>
						&nbsp;<b>Output:</b>43<br/><br/>
					</p>
				</p>
			</div>
		</Tab.Pane>
	)
};

const PythonDataStructure = () => {
	return (
		<Tab.Pane id="Python-DataStructure" eventKey="Python-DataStructure">
			<div id="Python-DataStructure">
				<p>
					<h5>Python Data Structure</h5>
					<h6>List in Python</h6>
					Python offers six types of built-in sequences to store data, one of which is a List. 
					The List is like a dynamic array, along with the linear sequence, and also provides us with 
					several built-in functions to perform various operations on data stored in it.
					<br/><br/>

					We will see what operations can be performed on lists later in this article. 
					Lists in Python can contain duplicate elements as each element is identified by a 
					unique number/ position known as an index.
					<br/><br/>
					
					A list is created by placing the items/ elements separated by a comma (,) between the square brackets ([ ]).
					<br/>
					Let’s see how we can create Lists in Python in different ways.

					<p className="pCode">
						# Creation of a List in Python<br/>
						 <br/>
						# Creating an empty List<br/>
						<b>&gt;&gt;&gt;</b>empty_List = []<br/>
						 <br/>
						# Creating a List of Integers<br/>
						<b>&gt;&gt;&gt;</b>integer_List = [10, 12, 9, 8]<br/>
						 <br/>
						# Creating a List of floating point numbers<br/>
						<b>&gt;&gt;&gt;</b>float_List = [1.2, 2.0, 1.7, 0.5]<br/>
						# Creating a List of Strings<br/>
						<b>&gt;&gt;&gt;</b>string_List = ["Python", "String", "List"]
					</p>
					
					<h6>Set in Python</h6>
					Sets in Python are a collection of unique elements that are unordered and immutable. 
					In other words, sets can be thought of as a collection of distinct items that cannot be 
					modified once created.
					<br/> 
					Python provides a built-in set data type that allows users to create, 
					manipulate and perform operations on sets efficiently.
					<br/>
					There are two ways of creating a set - 
					<ol>
						<li>
							<h6>Putting all the elements inside curly braces &#123; and &#125;</h6>
							<p className="pCode">
								<b>&gt;&gt;&gt;</b>numberSet = &#123;12, 14, 16&#125;<br/>
								print(numberSet)<br/>
								stringNumberSet = &#123;"eleven", 12, 13&#125;<br/>
								print(stringNumberSet)<br/>
							</p>
						</li>
						<li>
							<h6>Using set() method</h6>
							<p className="pCode">
								<b>&gt;&gt;&gt;</b>setMethodSet = set([1, 2, 'ten'])<br/>
								print(setMethodSet)<br/>
							</p>
						</li>
					</ol>
					<br/>
					<h6>Tuples in Python</h6>
					A tuple is one of the four inbuilt data types used to store collections in Python. 
					Unlike other data types, the elements in tuples are ordered and immutable. 
					They are used to store multiple items in a single variable and provides some built-in operation to work with them.
					<br/><br/>
					Tuples can only be created when they are being assigned, hence placing all the elements inside the parenthesis, 
					separated by a comma will create a tuple. 
					<br/>
					Below is the syntax to create a tuple:
					<br/>
					<p className="pCode">
						<b>&gt;&gt;&gt;</b>colorTuple = ('orange', 'red', 'green')<br/>
						print(colorTuple)<br/>		
					</p>
					<br/>
					<h6>Dictionary in Python</h6>
					The data is stored in a key-value pair format using Python Dictionary.
					<br/> 
					In Python, a dictionary is a data type that may replicate a real-world data arrangement 
					in which a given value exists for a specified key.
					<br/>
					The syntax of declaring a dictionary in python is as follows –
					<p className="pCode">
						#creating an empty dictionary <br/>
						<b>&gt;&gt;&gt;</b>myEmptydict = &#123; &#125;<br/>
						#creating a dictionary with data<br/>
						<b>&gt;&gt;&gt;</b>myDict = &#123;'key':'value', "key2":'value2'&#125;
					</p>
					<br/>
					<h6>Looping Techniques</h6>
					When looping through dictionaries, the key and corresponding value can be retrieved 
					at the same time using the <b>items()</b> method.
					<br/>
					<p className="pCode">
						<b>&gt;&gt;&gt;</b>myDict = &#123;'key1': 'value1', 'key2': 'value2'&#125; <br/>
						for k, v in myDict.items():<br/>
					    &nbsp;&nbsp;&nbsp;print(k, v)<br/>
					</p>
					When looping through a sequence, the position index and corresponding value can be 
					retrieved at the same time using the <b>enumerate()</b> function.

					<p className="pCode">
						<b>&gt;&gt;&gt;</b>for i, v in enumerate(['abc', 'def', 'ghi']):<br/>
							&nbsp;&nbsp;&nbsp;print(k, v)<br/>
					</p>	
				</p>
			</div>
		</Tab.Pane>
	)
};

const PythonModules = () => {
	return (
		<Tab.Pane id="Python-Modules" eventKey="Python-Modules">
			<div id="Python-Modules">
				<p>
					<h5>Python Modules</h5>
					When we quit from the Python interpreter and enter it again, the definitions we have made 
					(functions and variables) are lost. Therefore, if we want to write a somewhat longer program, 
					we are better off using a text editor to prepare the input for the interpreter and running it 
					with that file as input instead. This is known as creating a <b><i>script</i></b>. 
					<br/>
					As our program gets longer, 
					we may want to split it into several files for easier maintenance. We may also want to use 
					a handy function that we’ve written in several programs without copying its definition into each program.
					<br/><br/>
					To support this, Python has a way to put definitions in a file and use them in a script or in an 
					interactive instance of the interpreter. Such a file is called a <b><i>module</i></b>; definitions from a module 
					can be imported into other modules or into the main module.
					<br/><br/>
					A module is a file(i.e myModule) containing Python definitions and statements. 
					The file name is the module name with the suffix <b><i>.py</i></b> appended. 
					<br/><br/>
					<h6>The Module Search Path</h6>
					When a module named 'myModule' is imported, the interpreter first searches for a built-in module with that name. 
					These module names are listed in <b>sys.builtin_module_names</b>. If not found, it then searches for 
					a file named <b>myModule.py</b> in a list of directories given by the variable <b>sys.path</b>. 
					<br/><br/>
					<b>sys.path</b> is initialized from these locations - 
					<ul>
						<li>				
						The directory containing the input script (or the current directory when no file is specified).
						</li>
						<li>
						PYTHONPATH (a list of directory names, with the same syntax as the shell variable PATH).
						</li>
						<li>
						The installation-dependent default (by convention including a site-packages directory, handled by the site module).
						</li>
					</ul>
					<br/> 
					<h6>Standard Modules</h6>
					Python comes with a library of standard modules, described in a separate document, 
					the Python Library Reference ('Library Reference' hereafter).
					<br/> <br/> 
					Some modules are built into the interpreter; these provide access to operations that are not part 
					of the core of the language but are nevertheless built in, either for efficiency 
					or to provide access to operating system primitives such as system calls. 
					<br/>
					The set of such modules is a configuration option which also depends on the underlying platform. 
					<br/><br/> 
					For example, the winreg module is only provided on Windows systems. 
					One particular module deserves some attention: <b>sys</b>, which is built into every Python interpreter.	
				
					<br/><br/>
					
					<h6>Packages</h6>
					Packages are a way of structuring Python’s module namespace by using 'dotted module names'. 
					For example, the module name A.B designates a submodule named B in a package named A.
					<br/> 
					Just like the use of modules saves the authors of different modules from having to worry 
					about each other’s global variable names, the use of dotted module names saves the authors 
					of multi-module packages like <b>NumPy</b> or <b>Pillow</b> from having to worry about each other’s module names.
					<br/><br/>
					Users of the package can import individual modules from the package,<br/> for example -
					<br/>
					<p className="pCode">				
						<b>&gt;&gt;&gt;</b><b>import</b> myModule.subModule.myFun
						<br/><br/>
						# An alternative way of importing the submodule is -
						<br/>
						<b>from</b> myModule.subModule <b>import</b> myFun
						<br/>&nbsp;
					</p>
				</p>
			</div>
		</Tab.Pane>
	)
};

const PythonInputOutput = () => {
	return (
		<Tab.Pane id="Python-InputOutput" eventKey="Python-InputOutput">
			<div id="Python-InputOutput">
				<p>
					<h5>Python Input and Output</h5>
					There are several ways to present the output of a program, 
					data can be printed in a human-readable form, or written to a file for future use.
					
					<br/><br/>
					<h6>Fancier Output Formatting</h6>
					So far we’ve encountered two ways of writing values: <b>expression statements</b> and the <b>print()</b> function.
					<br/>
					A third way is using the <b>write()</b> method of file objects, 
					the standard output file can be referenced as <b>sys.stdout</b>.
					
					<br/>
					<br/>
				
					<h6>Formatted String Literals</h6>
					Formatted string literals (also called f-strings for short) let us include the value of 
					Python expressions inside a string by prefixing the string with f or F and writing expressions 
					as <b>&#123;</b>expression<b>&#125;</b>.
					<br/><br/>
					An optional format specifier can follow the expression. 
					This allows greater control over how the value is formatted. 
					<br/><br/>
					The following example rounds pi to three places after the decimal:
					
					<p className="pCode">
						<b>&gt;&gt;&gt;</b><b>import</b> math <br/>
						<b>print</b> ( f'The value of pi is approximately &#123; <b><i>math.pi:.3f</i></b> &#125;.' )<br/>
						<br/>
						# Output<br/>	
						<i>The value of pi is approximately 3.142.</i>
						<br/>
					</p>
					
					<br/>
					<h6>Reading and Writing Files</h6>
					<b>open()</b> returns a file object, and is most commonly used with two positional arguments 
					and one keyword argument: <b>open</b>(<b>filename</b>, <b>mode</b>, <b>encoding</b> = None)
					
					<p className="pCode">
						&nbsp;<br/>
						<b>&gt;&gt;&gt;</b>f = <b>open</b>('workfile', 'w', encoding="utf-8")
						<br/>
						&nbsp;
					</p>
					The first argument is a string containing the filename. 
					The second argument is another string containing a few characters describing the way in 
					which the file will be used. 
					<br/>
					mode can be <b>'r'</b> when the file will only be read, 
					<b>'w'</b> for only writing (an existing file with the same name will be erased), and 
					<b>'a'</b> opens the file for appending; any data written to the file is automatically added to the end. 
					<b>'r+'</b> opens the file for both reading and writing. The mode argument is optional, 
					<b>'r'</b> will be assumed if it’s omitted.	
				</p>
			</div>
		</Tab.Pane>
	)
};

const PythonErrorsExceptions = () => {
	return (
		<Tab.Pane id="Python-ErrorsExceptions" eventKey="Python-ErrorsExceptions">
			<div id="Python-ErrorsExceptions">
				<p>
					<h5>Errors and Exceptions</h5>
					Until now error messages haven’t been more than mentioned, but if we have tried out the examples 
					we have probably seen some.<br/> 
					There are (at least) two distinguishable kinds of errors: 
					<b>syntax errors</b> and <b>exceptions</b>.
					
					<br/><br/>
					<h6>Syntax Errors</h6>
					Syntax errors, also known as parsing errors, are perhaps the most common kind of complaint 
					we get while we are still learning Python -
					
					<p className="pCode">
						<b>&gt;&gt;&gt;</b><b>while</b> True print('Hello world') <br/>
						&nbsp;&nbsp;File "&lt;stdin&gt;", line 1 <br/>
						    &nbsp;&nbsp;&nbsp;&nbsp;while True print('Hello world') <br/>
						    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;^ <br/>
						SyntaxError: invalid syntax<br/>
					</p>
					The parser repeats the offending line and displays a little 'arrow' pointing at the earliest 
					point in the line where the error was detected. The error is caused by (or at least detected at)
					the token preceding the arrow: in the example, the error is detected at the function <b>print()</b>,
					since a colon ('<b>:</b>') is missing before it.
					<br/><br/> 
			        File name and line number are printed so we know where to look in case the input came from a script.
					<br/><br/>
					
					<h6>Exceptions</h6>
					Even if a statement or expression is syntactically correct, it may cause an error 
					when an attempt is made to execute it. Errors detected during execution are called exceptions 
					and are not unconditionally fatal: you will soon learn how to handle them in Python programs. 
					<br/>
					Most exceptions are not handled by programs, however, and result in error messages as shown here:
					
					<p className="pCode">
						<b>&gt;&gt;&gt;</b>10 * (1/0) <br/>
						&nbsp;&nbsp;&nbsp;Traceback (most recent call last): <br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  File "&lt;stdin&gt;", line 1, in &lt;module&gt; <br/>
						<br/>
						&nbsp;&nbsp;&nbsp;ZeroDivisionError: division by zero	
					</p>
					The last line of the error message indicates what happened. Exceptions come in different types, 
					and the type is printed as part of the message: the types in the example are <b>ZeroDivisionError</b>, 
					<b>NameError</b> and <b>TypeError</b>. The string printed as the exception type is the name of the built-in 
					exception that occurred. This is true for all built-in exceptions, but need not be true for 
					user-defined exceptions (although it is a useful convention). 
					<br/>
					Standard exception names are built-in identifiers (not reserved keywords).
					
					<br/><br/>
					<h6>Handling Exceptions</h6>
					It is possible to write programs that handle selected exceptions. Look at the following example, 
					which asks the user for input until a valid integer has been entered, 
					but allows the user to interrupt the program (using Control-C or whatever the operating system supports); 
					note that a user-generated interruption is signalled by raising the KeyboardInterrupt exception.
					
					<p className="pCode">
						<b>&gt;&gt;&gt;</b><b>while</b> True: <br/>
					    &nbsp;&nbsp;&nbsp;<b>try</b>: <br/>
					    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    x = int(input("Please enter a number: "))<br/>
					    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    <b>break</b><br/>
					    &nbsp;&nbsp;&nbsp;<b>except</b> ValueError:<br/>
					    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    print("Oops!  That was no valid number.  Try again...")<br/>
					    &nbsp;
					</p>
					
					<br/>
					<h6>Raising Exceptions</h6>
					The raise statement allows the programmer to force a specified exception to occur. For example:
					
					<p className="pCode">
						<b>&gt;&gt;&gt;</b><b>raise</b> NameError('SomeName') <br/>
							&nbsp;&nbsp;&nbsp;Traceback (most recent call last):<br/>
							  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;File "&lt;stdin&gt;", line 1, in &lt;module&gt;<br/>
							<b>NameError</b>: SomeName<br/>
							
					</p>		
					The sole argument to raise indicates the exception to be raised. 
					This must be either an exception instance or an exception class (a class that derives from BaseException,
					 such as Exception or one of its subclasses). If an exception class is passed, 
					 it will be implicitly instantiated by calling its constructor with no arguments:
					<p className="pCode">
						<b>&gt;&gt;&gt;</b><b>raise</b> ValueError  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;# shorthand for 'raise ValueError()'
					</p>
					
					<br/>
					<h6>User-defined Exceptions</h6>
						Programs may name their own exceptions by creating a new exception class. 
						Exceptions should typically be derived from the Exception class, either directly 
						or indirectly.
						<br/><br/>
						Exception classes can be defined which do anything any other class can do, but are usually kept simple,
						 often only offering a number of attributes that allow information about the error to be extracted by 
						 handlers for the exception.
						<br/><br/>
						Most exceptions are defined with names that end in <b>Error</b>, similar to the naming of the standard exceptions.
						<br/><br/>
						Many standard modules define their own exceptions to report errors that may occur in functions they define.
					
					<br/><br/>
					<h6>Raising and Handling Multiple Unrelated Exceptions</h6>
						There are situations where it is necessary to report several exceptions that have occurred. 
						This is often the case in concurrency frameworks, when several tasks may have failed in parallel, 
						but there are also other use cases where it is desirable to continue execution and 
						collect multiple errors rather than raise the first exception.
						<br/>
						The builtin ExceptionGroup wraps a list of exception instances so that they can be raised together. 
						It is an exception itself, so it can be caught like any other exception.
						<br/>
						<p className="pCode">
							<b>&gt;&gt;&gt;</b><b>
							def</b> f(): <br/>
							&nbsp;&nbsp;&nbsp;    excs = [<b>OSError</b>('error 1'), <b>SystemError</b>('error 2')]<br/>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    <b>raise</b> ExceptionGroup('there were problems', excs)<br/>
							<br/>
							<b>&gt;&gt;&gt;</b>f()<br/>
						</p>	
				</p>
			</div>
		</Tab.Pane>
	)
};

const PythonClasses = () => {
	return (
		<Tab.Pane id="Python-Classes" eventKey="Python-Classes">
			<div id="Python-Classes">
				<p>
					<h5>Python Classes</h5>
					
					Classes provide a means of bundling data and functionality together. Creating a new class creates a new 
					type of object, allowing new instances of that type to be made. Each class instance can have 
					attributes attached to it for maintaining its state. Class instances can also have methods 
					(defined by its class) for modifying its state.
					<br/><br/>
					Compared with other programming languages, Python's class mechanism adds classes with a minimum of 
					new syntax and semantics.
					<br/>
					<br/>
					<h6>A Word About Names and Objects</h6>
					Objects have individuality, and multiple names (in multiple scopes) can be bound to the same object. 
					This is known as aliasing in other languages. This is usually not appreciated on a first glance at Python,
					 and can be safely ignored when dealing with immutable basic types (numbers, strings, tuples). 
					 <br/>
					 However, aliasing has a possibly surprising effect on the semantics of Python code involving mutable 
					 objects such as lists, dictionaries, and most other types. This is usually used to the benefit of the program,
					  since aliases behave like pointers in some respects. For example, passing an object is cheap 
					  since only a pointer is passed by the implementation; and if a function modifies an object passed as 
					  an argument, the caller will see the change — this eliminates the need for two different argument 
					  passing mechanisms as in Pascal.
					<br/><br/>
					<h6>Python Scopes and Namespaces</h6>
					Before introducing classes, We first have to take a look at something about Python’s scope rules. 
					Class definitions play some neat tricks with namespaces, and we need to know how scopes and namespaces 
					work to fully understand what's going on. Incidentally, knowledge about this subject is useful for any
					 advanced Python programmer.
					<br/><br/>
					Let’s begin with some definitions.
					<br/>
					A namespace is a mapping from names to objects. Most namespaces are currently implemented 
					as Python dictionaries, but that's normally not noticeable in any way (except for performance), 
					and it may change in the future.
					<br/>
					A scope is a textual region of a Python program where a namespace is directly accessible. 
					'Directly accessible' here means that an unqualified reference to a name attempts to find 
					the name in the namespace.
					<br/><br/>
 	
 					<h6>How to create a python class</h6>
 					The simplest form of class definition looks like this:
					<p className="pCode">
						<b>&gt;&gt;&gt;</b><b> class</b> MyClassName: <br/>
						&nbsp;&nbsp;&nbsp;    &lt;statement-1&gt;<br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    .<br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    .<br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    .<br/>
						&nbsp;&nbsp;&nbsp;    &lt;statement-N&gt;<br/>
						&nbsp;&nbsp;&nbsp;
					</p>
					
					<br/>
					<h6>Class Objects</h6>
					Class objects support two kinds of operations: <b>Attribute references</b> and <b>Instantiation</b>.
					<br/>
					<br/>
					<b>Attribute references</b> use the standard syntax used for all attribute references in Python: 
					obj.name. Valid attribute names are all the names that were in the class’s namespace when the 
					class object was created. So, if the class definition looked like this:
					
					<p className="pCode">
						<b>&gt;&gt;&gt;</b><b> class</b> MyClass: <br/>
					    &nbsp;&nbsp;&nbsp;"""A simple example class"""<br/>
					    &nbsp;&nbsp;&nbsp;i = 12345<br/>
						<br/>
					    &nbsp;&nbsp;&nbsp;def f(self):<br/>
					        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return 'hello Python'<br/>
					</p>        
					then <b>MyClass.i</b> and <b>MyClass.f</b> are valid attribute references, returning an integer and 
					a function object, respectively. Class attributes can also be assigned to, 
					so we can change the value of <b>MyClass.i</b> by assignment. <b>__doc__</b> is also a valid attribute, 
					returning the docstring belonging to the class: "A simple example class".
					<br/><br/>
					<b>Class instantiation</b> uses function notation. Just pretend that the class object is a parameterless
					 function that returns a new instance of the class. For example (assuming the above class):
					
					<p className="pCode">
						<b>&gt;&gt;&gt;</b> x = MyClass()
						<br/>
						&nbsp;
					</p>
					creates a new instance of the class and assigns this object to the local variable x.

					<br/><br/>
					<h6>Inheritance</h6>
					Of course, a language feature would not be worthy of the name “class” without supporting inheritance. 
					The syntax for a derived class definition looks like this:
					
					<p className="pCode">
						<b>&gt;&gt;&gt; class</b> DerivedClassName(BaseClassName): <br/>
					    &nbsp;&nbsp;&nbsp;    &lt;statement-1&gt;<br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    .<br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    .<br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    .<br/>
						&nbsp;&nbsp;&nbsp;    &lt;statement-N&gt;<br/>
						&nbsp;&nbsp;&nbsp;
					</p>
					The name BaseClassName must be defined in a namespace accessible from the scope containing the derived 
					class definition. In place of a base class name, other arbitrary expressions are also allowed. 
					This can be useful, for example, when the base class is defined in another module:
					
					<br/>
					<b>class</b> DerivedClassName(modname.BaseClassName):
					Execution of a derived class definition proceeds the same as for a base class. 
					When the class object is constructed, the base class is remembered. This is used for resolving attribute 
					references: if a requested attribute is not found in the class, the search proceeds to look in the base class. 
					This rule is applied recursively if the base class itself is derived from some other class.
					
					<br/><br/>
					
					<h6>Multiple Inheritance</h6>
					Python supports a form of multiple inheritance as well. A class definition with multiple base classes 
					looks like this:
					<br/>
					<p className="pCode">
						<b>&gt;&gt;&gt; class</b> DerivedClassName(Base1, Base2, Base3): <br/>
					    &nbsp;&nbsp;&nbsp;    &lt;statement-1&gt;<br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    .<br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    .<br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    .<br/>
						&nbsp;&nbsp;&nbsp;    &lt;statement-N&gt;<br/>
						&nbsp;&nbsp;&nbsp;
					</p>
				</p>
			</div>
		</Tab.Pane>
	)
};

const PythonStandardLibrary = () => {
	return (
		<Tab.Pane id="Python-StandardLibrary" eventKey="Python-StandardLibrary">
			<div id="Python-StandardLibrary">
				<p>
					<h5>Tour of the Standard Library</h5>
					<h6>Operating System Interface</h6>
					The <b>os</b> module provides dozens of functions for interacting with the operating system:
					<br/>
					<p className="pCode">
						<b>&gt;&gt;&gt; import</b> os <br/>
						&nbsp;&nbsp;&nbsp;os.getcwd()  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    <i># Return the current working directory</i> <br/>
						&nbsp;&nbsp;&nbsp;<i>'C:\\Python'</i> <br/><br/>
						&nbsp;&nbsp;&nbsp;os.chdir('/myProject/accessLogs')   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;# Change current working directory <br/>
						&nbsp;&nbsp;&nbsp;os.system('mkdir today') &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  # Run the command mkdir in the system shell<br/><br/>
						&nbsp;&nbsp;&nbsp;<br/>
						
					</p>
					Be sure to use the import os style instead of from <b>os import *</b>. 
					This will keep <b>os.open()</b> from shadowing the built-in <b>open()</b> function which operates much differently.
					
					<br/><br/>
					<h6>File Wildcards</h6>
					The <b>glob</b> module provides a function for making file lists from directory wildcard searches:
					<br/>
					<p className="pCode">
						<b>&gt;&gt;&gt; import</b> glob <br/>
					&nbsp;&nbsp;&nbsp;glob.glob('*.py') <br/>
					&nbsp;&nbsp;&nbsp;['primes.py', 'random.py', 'quote.py'] <br/>
					</p>
					<br/>
					
					<h6>Command Line Arguments</h6>
					Common utility scripts often need to process command line arguments. These arguments are stored in the sys module’s argv attribute as a list. For instance, let’s take the following demo.py file:
					<p className="pCode">
						# File demo.py <br/>
						<b>&gt;&gt;&gt; import</b> sys <br/>
						&nbsp;&nbsp;&nbsp;print(sys.argv) <br/>
					</p>
					
					Here is the output from running <b>python demo.py one two three</b> at the command line:
					<br/>
					<p className="pCode">
						['demo.py', 'one', 'two', 'three'] <br/>
						&nbsp;
					</p>
					
					<br/>
					
					<h6>String Pattern Matching</h6>
					The <b>re</b> module provides regular expression tools for advanced string processing. 
					For complex matching and manipulation, regular expressions offer succinct, optimized solutions:
					
					<p className="pCode">
						<b>&gt;&gt;&gt; import</b> re <br/>
						&nbsp;&nbsp;&nbsp;re.findall(r'\bf[a-z]*', 'which foot or hand fell fastest') <br/>
						&nbsp;&nbsp;&nbsp;['foot', 'fell', 'fastest']<br/><br/>
						&nbsp;&nbsp;&nbsp;re.sub(r'(\b[a-z]+) \1', r'\1', 'cat in the the hat')<br/>
						&nbsp;&nbsp;&nbsp;'cat in the hat'<br/>
					</p>
					
					<br/>
					<h6>Mathematics</h6>
					The <b>math</b> module gives access to the underlying C library functions for floating point math:
					
					<p className="pCode">
						<b>&gt;&gt;&gt; import</b> math <br/>
						&nbsp;&nbsp;&nbsp;math.cos(math.pi / 4)<br/>
						&nbsp;&nbsp;&nbsp;0.70710678118654757<br/><br/>
						&nbsp;&nbsp;&nbsp;math.log(1024, 2)<br/>
						&nbsp;&nbsp;&nbsp;10.0<br/>
					
					</p>
					
					The <b>random</b> module provides tools for making random selections:

					<p className="pCode">
						<b>&gt;&gt;&gt; import</b> random <br/>
						&nbsp;&nbsp;&nbsp;random.choice(['apple', 'pear', 'banana'])<br/>
						&nbsp;&nbsp;&nbsp;'apple'<br/>
					</p>
					
					The <b>statistics</b> module calculates basic statistical properties (the mean, median, variance, etc.) 
					of numeric data:

					<p className="pCode">
						<b>&gt;&gt;&gt; import</b> statistics<br/>
						&nbsp;&nbsp;&nbsp;data = [2.75, 1.75, 1.25, 0.25, 0.5, 1.25, 3.5]<br/>
						&nbsp;&nbsp;&nbsp;statistics.mean(data)<br/>
						&nbsp;&nbsp;&nbsp;1.6071428571428572<br/><br/>
						&nbsp;&nbsp;&nbsp;statistics.median(data)<br/>
						&nbsp;&nbsp;&nbsp;1.25<br/>
					</p>
					<br/>
					<h6>Dates and Times</h6>
					The <b>datetime</b> module supplies classes for manipulating dates and times in both simple and complex ways. 
					While date and time arithmetic is supported, the focus of the implementation is on efficient member 
					extraction for output formatting and manipulation. The module also supports objects that are timezone aware.
					
					<p className="pCode">
						
						# dates are easily constructed and formatted<br/>
						<b>&gt;&gt;&gt;from datetime import</b> date<br/>
						now = date.today()<br/>
						<br/>
						datetime.date(2003, 12, 2)<br/>
					</p>
				</p>
			</div>
		</Tab.Pane>
	)
};

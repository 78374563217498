import React, { useState } from "react";
import {CourseModule} from './../common/course-module';
import { CourseDescription } from './../common/course-description';


const reactModules = [{ id: 'module1', module: 'Module 01', title: 'React Introduction', topics: [{topic:'What is React?'}, {topic:'Why to learn React?'}]},
{id: 'module2', module: 'Module 02', title: 'React Installation', topics: [{topic:'Pre-requisite for ReactJS'},{topic:'Ways to install ReactJS'}]},
{id: 'module3', module: 'Module 03', title: 'React Features', topics: [{topic:'JSX'}, {topic:'Components'},{topic:'One-way Data Binding'}, {topic:'Virtual DOM'}, {topic:'Simplicity'}, {topic:'Performance'}]},
{id: 'module4', module: 'Module 04', title: 'React JSX', topics: [{topic:'Why use JSX?'}, {topic:'JSX Attributes'}, {topic:'JSX Comments'}, {topic:'JSX Styling'}]},
{id: 'module5', module: 'Module 05', title: 'React Components', topics: [{topic:'Functional Components'}, {topic:'Class Components'}]},
{id: 'module6', module: 'Module 06', title: 'React State and Props', topics: [{topic:'React State'}, {topic:'React Props'}]},
{id: 'module7', module: 'Module 07', title: 'Component Life Cycle', topics: [{topic:'Initial Phase'}, {topic:'Mounting Phase'}, {topic:'Updating Phase'}, {topic:'Unmounting Phase'}]},
{id: 'module8', module: 'Module 08', title: 'React Styling', topics: [{topic:'CSS stylesheet'}, {topic:'Inline styling'}, {topic:'CSS Modules'}, {topic:'Styled component'}, {topic:'Sass stylesheet'}]},
{id: 'module9', module: 'Module 09', title: 'React Events', topics: [{topic:'React Events'}]},
{id: 'module10', module: 'Module 10', title: 'React Routing', topics: [{topic:'Install React Router'}, {topic:'Uses of React Route'}]},
{id: 'module11', module: 'Module 11', title: 'Build and Deploy', topics: [{topic:'How to Build an Application'}, {topic:'How to Deploy an Application on server'}]}
];

const angularModules = [{ id: 'module1', module: 'Module 01', title: 'Angular Introduction', topics: [{topic:'What is Angular?'}, {topic:'Why to learn Angular?'}]},
{id: 'module2', module: 'Module 02', title: 'Angular Installation', topics: [{topic:'Pre-requisite for Angular'},{topic:'Ways to install Angular'}]},
{id: 'module3', module: 'Module 03', title: 'Project Setup', topics: [{topic:'Setting up an Angular Project'}]},
{id: 'module4', module: 'Module 04', title: 'Angular Features', topics: [{topic:'Document Object Model'}, {topic:'TypeScript'},{topic:'Data Binding'}, {topic:'Powerful command-line interface'}, {topic:'Forms and Routing'}]},
{id: 'module5', module: 'Module 05', title: 'Angular Components', topics: [{topic:'How to create a Component?'}, {topic:'Use of Components'}]},
{id: 'module6', module: 'Module 06', title: 'Angular Modules', topics: [{topic:'What is Angular Module?'}, {topic:'Use of Angular Modules'}]},
{id: 'module7', module: 'Module 07', title: 'Angular Templates', topics: [{topic:'What is Angular Template?'}, {topic:'Use of Angular Templates'}]},
{id: 'module8', module: 'Module 08', title: 'Angular Directives', topics: [{topic:'Component Directives'}, {topic:'Structural Directives'}, {topic:'Attribute Directives'}]},
{id: 'module9', module: 'Module 09', title: 'Angular Events', topics: [{topic:'Angular Events'}]},
{id: 'module10', module: 'Module 10', title: 'Angular Routing', topics: [{topic:'Install Angular Router'}, {topic:'Uses of Angular Route'}]},
{id: 'module11', module: 'Module 11', title: 'Build and Deploy', topics: [{topic:'How to Build an Application'}, {topic:'How to Deploy an Application on server'}]}
];

const courseDescription = "This course syllabus is a comprehensive study of ReactJS/Angular to learn front end development. It contains complete ReactJS/Angular functionality to learn basic concepts from scratch with examples.";

const FrontendDeveloper = () => {
	const [activeTab, setActiveTab] = useState("reactJSTab");
  	
  	const handleReactJSTab = () => {
    	setActiveTab("reactJSTab");
  	};
  	const handleAngularTab = () => {
    	setActiveTab("angularTab");
  	};
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="row">
						<div className="col-lg-12 header">
							<h2>Frontend Developer - ReactJS/Angular</h2>
							<hr/>
						</div>
					</div>
					<CourseDescription courseDesc={courseDescription} enquiryFor="Enquiry for Frontend Developer - ReactJS/Angular"
						courseModuleCount="11" lectures="15" hours="30" price="5,000" offerPrice="4,000" />
				</div>
			</div>
			<div className="row paddingTop20">
				<div className="col-lg-12 spanCard">
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>	
						<div className="col-lg-8">
							<p>
								<h4>What You Need</h4><hr/>
								<ul>
									<li>Nodejs</li>
									<li>ReactJS or Angular</li>
									<li>A favorite text editor, i.e. VSCode</li>									
								</ul>	
							</p>
						</div>
					</div>
					<div className="row paddingBottom20 textColorDarkGreen">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<p>
								<h4>Why to become Frontend Developer?</h4><hr/>
								Here are the reasons why you should be a Frontend Developer:
								<ul>
									<li>You will be in high demand</li>
									<li>You have the opportunity to make a real difference in the world by designing User Interfaces</li>
									<li>You can work independently or on a team</li>
									<li>You would be knowing about all different skills like HTML, CSS, Bootstrap, ReactJS/Angular etc.</li>
								</ul>
							</p>
		
						</div>
					</div>
						
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<p>
								<h4>What you will be learning here</h4><hr/>
								<ul>
									<li>ReactJS/Angular development environment setup</li>
									<li>Get to know about different type of node modules</li>
									<li>Skills like HTML, CSS, Bootstrap, ReactJS/Angular etc.</li>									
									<li>Create a project from scratch, build and deploy it on server</li>
								</ul>
							</p>
		
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">	
					<div className="row paddingTop20 paddingBottom20">
						<div className="col-lg-12">
							<span className="pCenter textColorPink"><h3>Course Content </h3></span>
							<div className="accordion" id="accordionExample">
								<div className="card courseContentCard" >
									<div className="card-body">
										<div className="row">
											<div >
										      <ul className="nav">
										        <li className={activeTab === "reactJSTab" ? "active" : ""} onClick={handleReactJSTab}>
										          ReactJS
										        </li>
										        <li className={activeTab === "angularTab" ? "active" : ""} onClick={handleAngularTab}>
										          Angular
										        </li>
										      </ul>
										 
										       {activeTab === "reactJSTab" ? <ReactJSTab /> : <AngularTab />}
										      
										    </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
	)
}

export default FrontendDeveloper;

export const ReactJSTab = () => {
  return (
    <div >
      {reactModules.map((module)=>
		<CourseModule id={module.id} key={module.id} module={module.module} title={module.title} topics={module.topics} />
												
	  )}
    </div>
  );
};

export const AngularTab = () => {
  return (
    <div >
		{angularModules.map((module)=>
			<CourseModule id={module.id} key={module.id} module={module.module} title={module.title} topics={module.topics} />
												
	  )}      
    </div>
  );
};
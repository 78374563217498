import {CourseModule} from './../common/course-module';
import { CourseDescription } from './../common/course-description';

const javaModules = [{ id: 'module1', module: 'Module 01', title: 'Spring Boot Basics', topics: [{ topic: 'What is Spring Boot?' }, { topic: 'Advantages of Spring Boot' }, { topic: 'Limitations of Spring Boot' }, {topic: 'Goals of Spring Boot'}] },
{ id: 'module2', module: 'Module 02', title: 'Spring Boot Customization', topics: [{ topic: 'Spring Boot Starter' }, { topic: 'How to customize embed servers' }] },
{ id: 'module3', module: 'Module 03', title: 'Spring Boot JPA', topics: [{ topic: 'Spring Boot with JPA' }, { topic: 'Spring Boot with SpringData' }] },
{ id: 'module4', module: 'Module 04', title: 'Spring Boot AOP', topics: [{ topic: 'About Spring Boot AOP' }, { topic: 'AOP Before Advice' }, { topic: 'AOP After Advice' }, { topic: 'AOP Around Advice' }, { topic: 'After Returning Advice' }, { topic: 'After Throwing Advice' }] },
{ id: 'module5', module: 'Module 05', title: 'Spring Boot REST API', topics: [{ topic: 'Introduction to REST API' }, { topic: 'Implementing HTTP methods' }, { topic: 'Exception Handling in REST API' }, { topic: 'Implementing validations on serviecs' }, { topic: 'Monitoring APIs with Spring Boot' }] },
{ id: 'module6', module: 'Module 06', title: 'Spring Boot MVC', topics: [{ topic: 'What is Spring MVC?' }, { topic: 'How does Spring MVC works?' }, { topic: 'Details about Model, View and Controller layers' }] },
{ id: 'module7', module: 'Module 07', title: 'Spring Boot Testing', topics: [{ topic: 'Integration Testing With @SpringBootTest' }, { topic: 'Test Configuration With @TestConfiguration' }, { topic: 'Mocking With @MockBean' }, {topic: 'Integration Testing With @DataJpaTest'}, { topic: 'Unit Testing With @WebMvcTest'}] },
{ id: 'module8', module: 'Module 08', title: 'Spring Boot DevOps', topics: [{ topic: 'What Is DevOps?' }, { topic: 'DevOps Practices' }] },
{ id: 'module9', module: 'Module 09', title: 'Spring Boot Caching', topics: [{ topic: 'Why should we use the cache?' }, { topic: 'What data should be cached?' }, { topic: 'Types of Caching' }, { topic:'Caching with Spring Boot'}] },
{ id: 'module10', module: 'Module 10', title: 'Spring Boot Security', topics: [{topic:'Spring Boot Security Introduction'},{topic:'Security Custom Login Form'},{topic:'Spring Boot Security with JWT'}]},
{ id: 'module11', module: 'Module 11', title: 'Integration with Kafka', topics: [{topic:'Kafka Introduction'},{topic:'Kafka Consumer'},{topic:'Kafka Publisher'}]}
];

const reactModules = [{ id: 'module12', module: 'Module 12', title: 'React Introduction', topics: [{topic:'What is React?'}, {topic:'Why to learn React?'}]},
{id: 'module13', module: 'Module 13', title: 'React Installation', topics: [{topic:'Pre-requisite for ReactJS'},{topic:'Ways to install ReactJS'}]},
{id: 'module14', module: 'Module 14', title: 'React Features', topics: [{topic:'JSX'}, {topic:'Components'},{topic:'One-way Data Binding'}, {topic:'Virtual DOM'}, {topic:'Simplicity'}, {topic:'Performance'}]},
{id: 'module15', module: 'Module 15', title: 'React JSX', topics: [{topic:'Why use JSX?'}, {topic:'JSX Attributes'}, {topic:'JSX Comments'}, {topic:'JSX Styling'}]},
{id: 'module16', module: 'Module 16', title: 'React Components', topics: [{topic:'Functional Components'}, {topic:'Class Components'}]},
{id: 'module17', module: 'Module 17', title: 'React State and Props', topics: [{topic:'React State'}, {topic:'React Props'}]},
{id: 'module18', module: 'Module 18', title: 'Component Life Cycle', topics: [{topic:'Initial Phase'}, {topic:'Mounting Phase'}, {topic:'Updating Phase'}, {topic:'Unmounting Phase'}]},
{id: 'module19', module: 'Module 19', title: 'React Styling', topics: [{topic:'CSS stylesheet'}, {topic:'Inline styling'}, {topic:'CSS Modules'}, {topic:'Styled component'}, {topic:'Sass stylesheet'}]},
{id: 'module20', module: 'Module 20', title: 'React Events', topics: [{topic:'React Events'}]},
{id: 'module21', module: 'Module 21', title: 'React Routing', topics: [{topic:'Install React Router'}, {topic:'Uses of React Route'}]},
{id: 'module22', module: 'Module 22', title: 'Build and Deploy', topics: [{topic:'How to Build an Application'}, {topic:'How to Deploy an Application on server'}]}];

const courseDescription = "This Full Stack Course syllabus is a comprehensive study of ReactJS and Spring Boot. It contains complete industrial Spring Boot components to become master in Spring Boot with examples.";

const SpringBoot = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="row">
						<div className="col-lg-12 header">
							<h2>Full Stack - Spring Boot and ReactJS</h2>
							<hr />
						</div>
					</div>
					<CourseDescription courseDesc={courseDescription} enquiryFor="Enquiry for Full Stack with Spring Boot"
						courseModuleCount="22" lectures="45" hours="90" price="15,000" offerPrice="12,000" />

				</div>
			</div>
			<div className="row paddingTop20">
				<div className="col-lg-12 spanCard">
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>	
						<div className="col-lg-8">
							<p>
								<h4>What You Need</h4><hr/>
								<ul>
									<li>Java 1.8 or later</li>
									<li>A favorite text editor or IDE, i.e. Eclipse, IntelliJ</li>
									<li>Gradle or Maven</li>
									<li>Nodejs</li>
									<li>ReactJS</li>
								</ul>	
							</p>
						</div>
					</div>
					<div className="row paddingBottom20 textColorDarkGreen">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">			
							<p>
								<h4>Why Learn Spring Boot?</h4><hr/>
								Here are the reasons why you should learn Spring Boot:
								<ul>
									<li>Full Stack Developers are in high demand because they play a role of frontend and backend deveper</li>
									<li>The Unstoppable Growth</li>
									<li>Your productivity will improve once you become full stack developer</li>
									<li>You would be having flexibility to work on Frontend/Backend</li>
									<li>Spring Boot makes it easy to create stand-alone, production-grade Spring based Applications that you can 'just run'</li>
									<li>Embed Tomcat, Jetty or Undertow directly (no need to deploy WAR files)</li>
									<li>It simplifies integration with other frameworks like <b>AOP, JPA, Kafka, Hibernate, Logging</b> etc.</li>
									<li>Provide production-ready features such as metrics, health checks, and externalized configuration</li>
									
								</ul>
							</p>

						</div>
					</div>
						
					<div className="row paddingBottom20">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<p>
								<h4>What you will be learning here -</h4><hr/>
								<ul>
									<li>Setting up <b>Spring Boot Project</b></li>
									<li>Understanding about <b>Spring Boot's sister projects</b></li>
									<li>Spring Boot <b>Integrations</b></li>
									<li>Make use of production-ready features such as <b>Actuator, Metrics, Health Checks </b>etc.</li>
									<li><b>REST APIs</b></li>
									<li><b>Database</b> like MySQL, H2 DB</li>
									<li><b>ReactJS</b> for frontend development</li>									
									<li>Create a project from scratch, build and deploy it on server</li>
								</ul>
							</p>

						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">	
					<div className="row paddingTop20 paddingBottom20">
						<div className="col-lg-12">
							<p className="pCenter">
								<h4>Course Content</h4>
							</p>
							<div className="accordion" id="accordionExample">
								<div className="card courseContentCard" >
									<div className="card-body">
										<div className="row">
											{javaModules.map((module) =>
												<CourseModule id={module.id} key={module.id} module={module.module} title={module.title} topics={module.topics} />

											)}
											{reactModules.map((module)=>
												<CourseModule id={module.id} key={module.id} module={module.module} title={module.title} topics={module.topics} />
												
											)}

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
	);
}

export default SpringBoot;
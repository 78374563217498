import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Note } from './../common/note';
import Logo from './../../images/AngularLogoImg.png';
import AngularArchitectureImg from './../../images/Architecture_Angular.png';

const tabsData = [
	{ eventKey: 'Angular-Introduction', event: 'Angular Introduction' },
	{ eventKey: 'Angular-Installation', event: 'Angular Installation' },
	{ eventKey: 'Angular-ProjectSetup', event: 'Project Setup' },
	{ eventKey: 'Angular-Features', event: 'Angular Features' },
	{ eventKey: 'Angular-Components', event: 'Angular Components' },
	{ eventKey: 'Angular-Modules', event: 'Angular Modules' },
	{ eventKey: 'Angular-DataBinding', event: 'Angular Data Binding' },
	{ eventKey: 'Angular-EventBinding', event: 'Angular Event Binding' },
	{ eventKey: 'Angular-Templates', event: 'Angular Templates' },
	{ eventKey: 'Angular-Directives', event: 'Angular Directives' },
	{ eventKey: 'Angular-Routing', event: 'Angular Routing' },
	{ eventKey: 'Angular-Services', event: 'Angular Services' },
	{ eventKey: 'Angular-HttpClient', event: 'Angular Http Client' },
	{ eventKey: 'Angular-Forms', event: 'Angular Forms' }
	];


const Angular = () => {
	return (
		<div className="container-fluid">
			<div className="row paddingBottom20">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'paddingRight': '10px', 'height': '50px', 'width': '50px' }} />
									Angular
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">

								Angular is a TypeScript-based, free and open-source web application framework led by the Angular Team 
								at Google and by a community of individuals and corporations. 
								Angular is a complete rewrite from the same team that built AngularJS.
								<br/>
								<Note noteClass="noteInfo" text="Here, we are using Angular 15 version." />
							</div>
							<div className="col-lg-12 ">

								<Tab.Container id="left-tabs-example" defaultActiveKey={tabsData[0].eventKey}>
									<Row>
										<Col sm={3}>
											<Nav variant="pills" className="flex-column menuItems">
												{tabsData.map((tabData, index) => {
													const hrefData= '#'+tabData.eventKey;
													return <Nav.Item key={tabData.eventKey}>
														<Nav.Link href={hrefData} eventKey={tabData.eventKey}>{index + 1}.) {tabData.event}</Nav.Link>
													</Nav.Item>
													}
												)}
											</Nav>
										</Col>
										<Col sm={9}>
											<Tab.Content>
												<AngularIntroduction />
												<AngularInstallation />
												<AngularProjectSetup />
												<AngularFeatures />
												<AngularComponents />
												<AngularModules />
												<AngularDataBinding />
												<AngularEventBinding />
												<AngularTemplates />
												<AngularDirectives />
												<AngularRouting />
												<AngularServices />
												<AngularHttpClient />
												<AngularForms />
											</Tab.Content>
										</Col>
									</Row>
								</Tab.Container>
							</div>
							<div className="col-lg-12 paddingTop20">
								<span className="textColorPink">
									<b>Disclaimer: </b>
									Content of this page has been referred from <a href="https://angular.io/" target="_blank" rel="noreferrer">Angular</a>.
								</span> 
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>

	);
}

export default Angular;

const AngularIntroduction = () => {
	return (
		<Tab.Pane id="Angular-Introduction" eventKey="Angular-Introduction">
			<div id="Angular-Introduction">
				<p>
					<h5>Angular Introduction</h5>
						Angular is a development platform, built on TypeScript. 
						<br/>
						As a platform, Angular includes:
						<ul>
							<li>
								A component-based framework for building scalable web applications
							</li>
							<li>
								A collection of well-integrated libraries that cover a wide variety of features, including routing, forms management, client-server communication, and more
							</li>
							<li>
								A suite of developer tools to help us develop, build, test, and update our code
							</li>	
						</ul>
						With Angular, we're taking advantage of a platform that can scale from single-developer projects 
						to enterprise-level applications. Angular is designed to make updating as straightforward as possible, 
						so take advantage of the latest developments with minimal effort. 
						<br/>
						Best of all, the Angular ecosystem consists of a diverse group of over 1.7 million developers, 
						library authors, and content creators.
					
				</p>
				<p className="pCenter">
					<img src={AngularArchitectureImg} alt="not found" className="paddingTop20" style={{'width':'70%'}} />
				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularInstallation = () => {
	return (
		<Tab.Pane id="Angular-Installation" eventKey="Angular-Installation">
			<div id="Angular-Installation">
				<p>
					<h5>Angular Installation</h5>
					In order to Setup Environment for Angular, we require the following −<br/>
					<ul>
						<li>Nodejs</li>
						<li>Npm</li>
						<li>Angular CLI</li>
						<li>IDE for writing our code</li>
					</ul>
					
					<h6>Nodejs</h6>
					To check if nodejs is installed on our system, type <b>node -v</b> in the terminal/cammond prompt. 
					This will help us to see the version of nodejs currently installed on our system.
					<br/>
					<p className="pCode">node –v</p>
					If it does not print anything, install latest version of nodejs on our system.
					<br/> 
					To install nodejs, go to the <a href="https://nodejs.org/en/download/" target="_blank" rel="noreferrer">Nodejs</a> download page and 
					install the latest package based on our OS.
					
					
					<h6>Angular</h6> 
					Angular installations are very simple with the help of <b>angular CLI</b>. 
					Visit the <a href="https://cli.angular.io/" target="_blank" rel="noreferrer">Angular CLI</a> page to get the reference of the command.
					<br/>
					Type <b>npm install –g @angular/cli</b> in our command prompt, to install angular cli on our system. 
					It will take a while to install and once done we can check the version using below command −
					<p className="pCode">ng version</p>
				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularProjectSetup = () => {
	return (
		<Tab.Pane id="Angular-ProjectSetup" eventKey="Angular-ProjectSetup">
			<div id="Angular-ProjectSetup">
				<p>
					<h5>Project Setup</h5>
						To get started with the project setup, make sure we have <b>nodejs</b> installed. 
						We can check the version of node in the command line using the command, <b>node –v</b>
						<br/><br/>
						The below commands help to get the project setup in Angular -
						<p className="pCode">
							&gt; npm install -g @angular/cli    &nbsp;&nbsp;&nbsp;//command to install angular<br/>
							&gt; ng new my-angular-app &nbsp;&nbsp;&nbsp;// 'my-angular-app' name of the project<br/>
							&gt; cd my-angular-app<br/>
							&gt; ng serve
						</p>
						
						
						The <b>'my-angular-app'</b> folder has the following folders/files structure−
						<br/>
						
						<h6>node_modules/</h6> The npm package installed is node_modules. We can open the folder and see the 
						packages available.
						<br/>
						<h6>src/</h6> This folder is where we will work on the project using Angular. 
						<br/>
						Inside src/, app/ folder created during the project setup and holds all the required 
						files required for the project.
						
						<h6>angular.json</h6> It basically holds the project name, version of cli, etc.
						
						<h6>.editorconfig</h6> This is the config file for the editor.
						
						<h6>.gitignore</h6> A .gitignore file should be committed into the repository, in order to share the 
						ignore rules with any other users that clone the repository.
						
						<h6>package.json</h6> The package.json file tells which libraries will be installed into node_modules 
						when we run npm install.
				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularFeatures = () => {
	return (
		<Tab.Pane id="Angular-Features" eventKey="Angular-Features">
			<div id="Angular-Features">
				<p>
					<h5>Angular Features</h5>
					<ol>
						<li><b>Document Object Model</b> - 
							DOM (Document Object Model) treats an XML or HTML file as a tree shape in which every node represents 
							a phase of the document. Angular makes use of everyday DOM. Consider that ten updates are made on the 
							identical HTML page. Instead of updating the ones that have been already updated, it will replace the 
							whole tree shape of HTML tags.
						</li>
						<li><b>TypeScript</b> - 
							TypeScript defines a set of kinds of JavaScript, which helps users write JavaScript code that is less 
							complicated to understand. All of the TypeScript code compiles with JavaScript and can run easily on 
							any platform. TypeScript is now not obligatory for growing an Angular application. However, 
							it is fairly advocated as it gives higher syntactic structure—while making the codebase less 
							difficult to apprehend and maintain.
						</li>
						<li><b>Data Binding</b> - 
							Data binding is a manner that allows customers to manipulate net web page factors via an internet browser. 
							It employs dynamic HTML and does now not require complicated scripting or programming. Data binding is 
							used in net pages that consist of interactive components, such as calculators, tutorials, forums, 
							and games. It additionally allows a higher incremental show of an internet web page when pages 
							incorporate a giant quantity of data.
						</li>
						<li><b>Powerful command-line interface</b> - 
							The Angular command-line interface <b>(CLI)</b> supports starting a project and growing it to production, 
							maintaining consistency across teams and projects.
						</li>
						<li><b>Forms and Routing</b> - 
							Angular's Forms and Routing, developed and supported by the Angular team, 
							provide stability and integration with the rest of the platform. 
						</li>
						<li><b>Development tools</b> - 
							Angular's integrated development environment <b>(IDE)</b> tools offer autocompletion and type checking. 
							Profiling tools give a preview of our app's performance.
						</li>
						<li><b>Component libraries</b> - 
							Many component libraries are available for Angular apps to use. 
							Material component library is one library that thousands of Google apps use with confidence.
						</li>
					</ol>
				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularComponents = () => {
	return (
		<Tab.Pane id="Angular-Components" eventKey="Angular-Components">
			<div id="Angular-Components">
				<p>
					<h5>Angular Components</h5>
					Components are basically classes that interact with the <b>.html</b> file of the component, 
					which gets displayed on the browser. We have seen the file structure in one of our previous chapters.
					<br/>
					The file structure has the app component and it consists of the following files −
					<br/>
					<ol>
						<li>app.component.css</li>
						<li>app.component.html</li>
						<li>app.component.spec.ts</li>
						<li>app.component.ts</li>
						<li>app.module.ts</li>
					</ol>
					
					Now, <b>angular-cli</b> has a command to create our own component. However, the app component 
					which is created by default will always remain the parent and the next components created will form 
					the child components.
					<br/><br/>
					Let us now run the command to create the component with the below line of code −
					<br/>
					<p className="pCode">&gt; ng g component new-cmp</p>
					
					When we run the above command in the command line, we will receive the following output −
					<br/>
					<p className="pCode">&gt; ng g component new-cmp <br/> 
						CREATE src/app/new-cmp/new-cmp.component.html <br/> 
						CREATE src/app/new-cmp/new-cmp.component.spec.ts <br/>
						CREATE src/app/new-cmp/new-cmp.component.ts <br/>
						CREATE src/app/new-cmp/new-cmp.component.css <br/>
						UPDATE src/app/app.module.ts <br/>
					</p>
				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularModules = () => {
	return (
		<Tab.Pane id="Angular-Modules" eventKey="Angular-Modules">
			<div id="Angular-Modules">
				<p>
					<h5>Angular Modules</h5>
					Module in Angular refers to a place where we can group the <b>components, directives, pipes, and services</b>, 
					which are related to the application.
					<br/>
					In case we are developing a website, the header, footer, left, center and the right section become part 
					of a module.
					<br/>
					To define module, we can use the <b>NgModule</b>. When we create a new project using the Angular –cli command, 
					the ngmodule is created in the <b>app.module.ts</b> file by default.


				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularDataBinding = () => {
	return (
		<Tab.Pane id="Angular-DataBinding" eventKey="Angular-DataBinding">
			<div id="Angular-DataBinding">
				<p>
					<h5>Angular Data Binding</h5>
					Data Binding is available right from AngularJS, and all the versions of Angular released later on. 
					We use curly braces for data binding, this process is called interpolation. 
					<br/><br/>
					All the variables are created in the <b>app.component.ts</b> and the value of these variables are also
					initialized in the <b>app.component.ts</b> file and in <b>app.component.html</b>, the values are displayed.


				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularEventBinding = () => {
	return (
		<Tab.Pane id="Angular-EventBinding" eventKey="Angular-EventBinding">
			<div id="Angular-EventBinding">
				<p>
					<h5>Angular Event Binding</h5>
					When a user interacts with an application in the form of a keyboard movement, a mouse click, or a mouse over, 
					it generates an event. These events need to be handled to perform some kind of action. 
					This is where event binding comes into picture.

				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularTemplates = () => {
	return (
		<Tab.Pane id="Angular-Templates" eventKey="Angular-Templates">
			<div id="Angular-Templates">
				<p>
					<h5>Angular Templates</h5>
					In Angular, a template is a blueprint for a fragment of a user interface (UI). 
					Templates are written in HTML, and special syntax can be used within a template to build on many of 
					Angular's features.


				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularDirectives = () => {
	return (
		<Tab.Pane id="Angular-Directives" eventKey="Angular-Directives">
			<div id="Angular-Directives">
				<p>
					<h5>Angular Directives</h5>
					Directives in Angular is a js class, which is declared as <b>@directive</b>. We have 3 directives in Angular. 
					<br />
					The directives are listed below −
					<br/>
					<ol>
						<li>
							<h6>Component Directives</h6>
							These form the main class having details of how the component should be processed, instantiated and 
							used at runtime.
						</li>	
						<li>
							<h6>Structural Directives</h6>
							A structure directive basically deals with manipulating the dom elements. 
							Structural directives have a * sign before the directive. For example, <b>*ngIf and *ngFor</b>.
						</li>
						<li>
							<h6>Attribute Directives</h6>
							Attribute directives deal with changing the look and behavior of the dom element. 
							We can create our own directives as explained in the below section.
						</li>
					</ol>
					
					<h6>How to Create Custom Directives?</h6>
					In this section, we will discuss about <b>Custom Directives</b> to be used in components. 
					<br/>
					Custom directives are created by us and are not standard.
					
					<br />
					Let us see how to create the custom directive. We will create the directive using the command line.
					<br/> 
					The command to create the directive using the command line is as follows −
					<br/>
					<p className="pCode">&gt; ng g directive myCustomDirective<br/>
						e.g <br/>
						&gt; ng g directive changeText
					</p>
				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularRouting = () => {
	return (
		<Tab.Pane id="Angular-Routing" eventKey="Angular-Routing">
			<div id="Angular-Routing">
				<p>
					<h5>Angular Routing</h5>
					In a single-page app, we change what the user sees by showing or hiding portions of the display 
					that correspond to particular components, rather than going out to the server to get a new page. 
					As users perform application tasks, they need to move between the different views that we have defined.
					<br/><br/>
					To handle the navigation from one view to the next, we use the <b>Angular Router</b>. The Router enables 
					navigation by interpreting a browser URL as an instruction to change the view.
					<br/><br/>
					Routing basically means navigating between pages. We have seen many sites with links that direct 
					to a new page. This can be achieved using routing. Here the pages that we are referring to will be in 
					the form of components. 					
				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularServices = () => {
	return (
		<Tab.Pane id="Angular-Services" eventKey="Angular-Services">
			<div id="Angular-Services">
				<p>
					<h5>Angular Services</h5>
					We might come across a situation where we need some code to be used everywhere on the page. 
					For example, it can be for data connection that needs to be shared across components. 
					<br/>
					This is achieved with the help of <b>Services</b>. With services, we can access methods and properties 
					across other components in the entire project.
					<br/><br/>
					To create a service, we need to make use of the command line as given below −
					<br/>
					<p className="pCode">&gt;ng g service myservice</p>

				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularHttpClient = () => {
	return (
		<Tab.Pane id="Angular-HttpClient" eventKey="Angular-HttpClient">
			<div id="Angular-HttpClient">
				<p>
					<h5>Angular Http Client</h5>
					Most front-end applications need to communicate with a server over the HTTP protocol, 
					to download or upload data and access other back-end services. Angular provides a client HTTP API 
					for Angular applications, the <b>HttpClient</b> service class in <b>@angular/common/http</b>.
					<br/><br/>
					The HTTP client service offers the following major features.
					<ol>
						<li>The ability to request typed response objects</li>
						<li>Streamlined error handling</li>
						<li>Testability features</li>
						<li>Request and response interception</li>
					</ol>
					
					<b>HttpClient</b> will help us fetch external data, post to it, etc. We need to import the http module 
					to make use of the http service. Let us consider an example to understand how to make use of the http service.
				</p>
			</div>
		</Tab.Pane>
	)
};

const AngularForms = () => {
	return (
		<Tab.Pane id="Angular-Forms" eventKey="Angular-Forms">
			<div id="Angular-Forms">
				<p>
					<h5>Angular Forms</h5>
					Handling user input with forms is the cornerstone of many common applications. Applications use forms to 
					enable users to log in, to update a profile, to enter sensitive information, and to perform many other 
					data-entry tasks.
					<br/>
					Angular provides two different approaches to handling user input through forms: 
					 <b> reactive</b> and <b>template-driven</b>. 
					<br/>
					Both capture user input events from the view, 
					validate the user input, create a form model and data model to update, and provide a way to track changes.
					<br/>
					We will discuss two ways of working with forms −
					<ol>
						<li>Template driven form</li>
						<li>Model driven form</li>
					</ol>
					
					<h6>Template Driven Form</h6>
					With a template driven form, most of the work is done in the template. With the model driven form, 
					most of the work is done in the component class.
					<br/>
					Let us now consider working on the Template driven form. We will create a simple login form and add 
					the email id, password and submit button in the form.
					<br/><br/>
					<h6>Model Driven Form</h6>
					In the model driven form, we need to import the ReactiveFormsModule from <b>@angular/forms</b> and use the 
					same in the imports array.
					<br/><br/>
					<h6>Form Validation</h6>
					Let us now discuss form validation using model driven form. We can use the built-in form validation 
					or also use the custom validation approach. We will use both the approaches in the form. 
					<br/>
					With Angular, we need to import <b>Validators</b> from <b>@angular/forms</b> as shown below −
					
					
					Angular has built-in validators such as mandatory field, minlength, maxlength, and pattern. 
					These are to be accessed using the Validators module.
				</p>
			</div>
		</Tab.Pane>
	)
};
import { Tab } from 'react-bootstrap';
import SpringArchitectureImg from './../../images/SpringArchitecture.png';
import SpringMVCImg from './../../images/SpringMVC.png';

export const SpringOverview = () => {
	return (
		<Tab.Pane id="Spring-Overview" eventKey="Spring-Overview">
			<div id="Spring-Overview">
				<p>
					<h5>Spring Overview</h5>
					Spring is the most popular application development framework for enterprise Java.
					Millions of developers around the world use Spring Framework to create high performing, easily testable, and reusable code.
					<br />
					Spring is lightweight when it comes to size and transparency. The basic version of Spring framework is around 2MB.
					<br /><br />
					<h5><i>Why to Learn Spring?</i></h5>
					Spring is the most popular application development framework for enterprise Java.
					Millions of developers around the world use Spring Framework to create high performing, easily testable,
					and reusable code.<br />
					The framework, in broader sense, can be defined as a structure where we find solution of the various
					technical problems. <br /><br />
					The core features of the Spring Framework can be used in developing any Java application,
					but there are extensions for building web applications on top of the Java EE platform.
					Spring framework targets to make J2EE development easier to use and promotes good programming practices
					by enabling a POJO-based programming model.
	
				</p>
			</div>
		</Tab.Pane>
	)
};

export const SpringArchitecture = () => {
	return (
		<Tab.Pane id="Spring-Architecture" eventKey="Spring-Architecture">
			<div id="Spring-Architecture">
				<p>
					<h5>Spring Architecture</h5>
					Spring could potentially be a one-stop shop for all our enterprise applications. However, Spring is modular,
					allowing us to pick and choose which modules are applicable to us, without having to bring in the rest.
					<br /><br />
					The Spring Framework provides about 20 modules which can be used based on an application requirement.
	
				</p>
				<p className="pCenter"><img src={SpringArchitectureImg} alt="not found" className="paddingTop20" style={{ 'width': '70%' }} /></p>
			</div>	
		</Tab.Pane>
	)
};


export const SpringCore = () => {
	return (
		<Tab.Pane id="Spring-Core" eventKey="Spring-Core">
			<div id="Spring-Core">
				<p>
					<h5>Spring Core</h5>
					The Core Container consists of the Core, Beans, Context, and Expression Language modules the details of which are as follows −
					<br />
					<ul>
						<li>
							The <b>Core module</b> provides the fundamental parts of the framework, including the IoC and Dependency Injection features.
					</li>
						<li>
							The <b>Bean module</b> provides BeanFactory, which is a sophisticated implementation of the factory pattern.
					</li>
								<li>
									The <b>Context module</b> builds on the solid base provided by the Core and Beans modules and it is a medium to 
									access any objects defined and configured. The ApplicationContext interface is the focal point of the Context module.
					</li>
								<li>
									The <b>SpEL module</b> provides a powerful expression language for querying and manipulating an object graph at runtime.
					</li>
								<li>
									<b>Data Access Integration </b><br />
					The Data Access Integration layer consists of the JDBC, ORM, OXM, JMS and Transaction modules whose detail is as follows −
					<ul>
										<li>
											The <b>JDBC module</b> provides a JDBC-abstraction layer that removes the need for tedious JDBC related coding.
					</li>
												<li>
													The <b>ORM module</b> provides integration layers for popular object-relational mapping APIs, 
													including JPA, JDO, Hibernate, and iBatis.
					</li>
														<li>
															The <b>OXM module</b> provides an abstraction layer that supports Object/XML mapping implementations for JAXB,
															 Castor, XMLBeans, JiBX and XStream.
					</li>
					</ul></li>
												<li>
													The Java Messaging Service <b>JMS module</b> contains features for producing and consuming messages.
					</li>
												<li>
													The <b>Transaction module</b> supports programmatic and declarative transaction management for classes 
													that implement special interfaces and for all our POJOs.
					</li>
												<li>
													<b>Web</b><br />
					The Web layer consists of the Web, Web-MVC, Web-Socket, and Web-Portlet modules the details of which are as follows −
	
					<ul><li>The <b>Web module</b> provides basic web-oriented integration features such as multipart file-upload functionality and 
					the initialization of the IoC container using servlet listeners and a web-oriented application context.
					</li><li>
															The <b>Web-MVC module</b> contains Spring's Model-View-Controller (MVC) implementation for web applications.
					</li><li>
															The <b>Web-Socket module</b> provides support for WebSocket-based, two-way communication between the client and the server in web applications.
					</li><li>
															The <b>Web-Portlet module</b> provides the MVC implementation to be used in a portlet environment and mirrors the functionality of Web-Servlet module.
					</li></ul>
												</li>
												<li>
													<b>Miscellaneous</b>
					There are few other important modules like AOP, Aspects, Instrumentation, Web and Test modules the details of which are as follows −
					</li>
												<li>
													The <b>AOP module</b> provides an aspect-oriented programming implementation allowing us to define method-interceptors 
													and pointcuts to cleanly decouple code that implements functionality that should be separated.
					<br />
					The Aspects module provides integration with AspectJ, which is again a powerful and mature AOP framework.
					</li>
												<li>
													The <b>Instrumentation module </b>provides class instrumentation support and class loader implementations to be used 
													in certain application servers.
					</li>
												<li>
													The <b>Messaging module</b> provides support for STOMP as the WebSocket sub-protocol to use in applications. 
													It also supports an annotation programming model for routing and processing STOMP messages from WebSocket clients.
					</li>
												<li>
													The <b>Test module</b> supports the testing of Spring components with JUnit or TestNG frameworks.
					</li>
					</ul>
				</p>
			</div>	
		</Tab.Pane>
	)
};


export const InversionOfControl = () => {
	return (
		<Tab.Pane id="Inversion-of-control" eventKey="Inversion-of-control">
			<div id="Inversion-of-control">
				<p>
					<h5>Inversion of Control</h5>
					The Spring container is at the core of the Spring Framework. The container will create the objects, wire them together, 
					configure them, and manage their complete life cycle from creation till destruction. The Spring container uses DI to manage 
					the components that make up an application. These objects are called Spring Beans, which we will discuss in the next chapter.
					<br />
					The container gets its instructions on what objects to instantiate, configure, and assemble by reading the configuration metadata provided. 
					The configuration metadata can be represented either by XML, Java annotations, or Java code. The following diagram represents a 
					high-level view of how Spring works. The Spring IoC container makes use of Java POJO classes and configuration metadata to produce 
					a fully configured and executable system or application.
					<ol><li>
					<h6>Spring BeanFactory Container</h6>
					This is the simplest container providing the basic support for DI and is defined by the org.springframework.beans.factory.BeanFactory interface. <br /> 
					The BeanFactory and related interfaces, such as BeanFactoryAware, InitializingBean, DisposableBean, are still present in Spring for the purpose of backward 
					compatibility with a large number of third-party frameworks that integrate with Spring.
					</li>
					<li>	
					<h6>Spring ApplicationContext Container</h6>
					This container adds more enterprise-specific functionality such as the ability to resolve textual messages from a properties file 
					and the ability to publish application events to interested event listeners. This container is defined by the 
					org.springframework.context.ApplicationContext interface.
					</li>
					</ol>
				</p>
			</div>
		</Tab.Pane>
	)
};

export const SpringDependencyInjection = () => {
	return (
		<Tab.Pane id="Spring-Dependency-Injection" eventKey="Spring-Dependency-Injection">
			<div id="Spring-Dependency-Injection">
				<p>
					<h5>Dependency Injection</h5>
					Every Java-based application has a few objects that work together to present what the end-user sees as a working application. 
					When writing a complex Java application, application classes should be as independent as possible of other Java classes to 
					increase the possibility to reuse these classes and to test them independently of other classes while unit testing. <br/>
					Dependency Injection (or sometime called wiring) helps in gluing these classes together and at the same time keeping 
					them independent.
					<br/><br/>
					Consider we have an application which has a text editor component and we want to provide a spell check.
					<br/> 
					Our standard code would look something like this −
					<br/>
					TODO 
					<br />
					What we've done here is, create a dependency between the TextEditor and the SpellChecker. 
					In an inversion of control scenario, we would instead do something like this −
					<br/>
					TODO
					
					<br/>
					Here, the TextEditor should not worry about SpellChecker implementation. 
					The SpellChecker will be implemented independently and will be provided to the TextEditor at the time of 
					TextEditor instantiation. This entire procedure is controlled by the Spring Framework.
					<br /><br />
					Here, we have removed total control from the TextEditor and kept it somewhere else (i.e. XML configuration file) 
					and the dependency (i.e. class SpellChecker) is being injected into the class TextEditor through a Class Constructor. 
					Thus the flow of control has been <b>"inverted"</b> by <b>Dependency Injection (DI)</b> because we have effectively delegated 
					dependances to some external system.
					<br />
					The second method of injecting dependency is through Setter Methods of the TextEditor class where we will create a 
					SpellChecker instance. This instance will be used to call setter methods to initialize TextEditor's properties.
					<br />
					Thus, DI exists in two major variants and the following two sub-chapters will cover both of them with examples −
					<br/><br/>				
					<h6>Dependency Injection Type</h6>
					 <ul><li><b>Constructor-based dependency injection</b> <br/>
					 
							Constructor-based DI is accomplished when the container invokes a class constructor with a number of arguments, 
							each representing a dependency on the other class.
						</li><li><b>
							Setter-based dependency injection</b><br />
							Setter-based DI is accomplished by the container calling setter methods on our beans after invoking a no-argument 
							constructor or no-argument static factory method to instantiate our bean.
						</li>
					</ul>
					We can mix both, Constructor-based and Setter-based DI but it is a good rule of thumb to use constructor arguments for 
					mandatory dependencies and setters for optional dependencies.
					<br/><br/>
					The code is cleaner with the DI principle and decoupling is more effective when objects are provided with their dependencies.
					The object does not look up its dependencies and does not know the location or class of the dependencies, 
					rather everything is taken care by the Spring Framework.
						
				</p>
			</div>
		</Tab.Pane>
	)
};


export const SpringAOP = () => {
	return (
		<Tab.Pane id="Spring-AOP" eventKey="Spring-AOP">
			<div id="Spring-AOP">
				<p>
					<h5>AOP with Spring Framework</h5>
					One of the key components of Spring Framework is the Aspect oriented programming (AOP) framework. 
					Aspect-Oriented Programming entails breaking down program logic into distinct parts called so-called concerns. 
					The functions that span multiple points of an application are called cross-cutting concerns and these cross-cutting 
					concerns are conceptually separate from the application's business logic.<br /> 
					There are various common good examples of aspects like logging, auditing, declarative transactions, security, caching, etc.<br />
	
					The key unit of modularity in OOP is the class, whereas in AOP the unit of modularity is the aspect. 
					Dependency Injection helps us decouple our application objects from each other and AOP helps us decouple 
					cross-cutting concerns from the objects that they affect. AOP is like triggers in programming languages 
					such as Perl, .NET, Java, and others. <br />
	
					Spring AOP module provides interceptors to intercept an application. For example, when a method is executed, 
					we can add extra functionality before or after the method execution.<br />
					<br/>
					<h6>AOP Terminologies</h6>
					Before we start working with AOP, let us become familiar with the AOP concepts and terminology. 
					These terms are not specific to Spring, rather they are related to AOP.
					<br />
					<ul>
						<li><b>Aspect</b><br/>
	
							This is a module which has a set of APIs providing cross-cutting requirements. For example, a logging module would be called AOP aspect for logging. An application can have any number of aspects depending on the requirement.
						</li>
						<li><b>Join point</b><br />
	
							This represents a point in our application where we can plug-in the AOP aspect. We can also say, it is the actual place in the application where an action will be taken using Spring AOP framework.
						</li>
						<li><b>Advice</b> <br />
	
							This is the actual action to be taken either before or after the method execution. This is an actual piece of code that is invoked during the program execution by Spring AOP framework.
						</li>
						<li><b>Pointcut</b><br />
	
							This is a set of one or more join points where an advice should be executed. We can specify pointcuts using expressions or patterns as we will see in our AOP examples.
						</li>
						<li><b>Introduction</b><br />
	
							An introduction allows us to add new methods or attributes to the existing classes.
						</li>
						<li><b>Target object</b><br />
							The object being advised by one or more aspects. This object will always be a proxied object, also referred to as the advised object.
						</li>
						<li><b>Weaving</b> <br />
							Weaving is the process of linking aspects with other application types or objects to create an advised object. This can be done at compile time, load time, or at runtime.
						</li>
					</ul>
					
					<h6>Types of Advice</h6>
						Spring aspects can work with five kinds of advice mentioned as follows −<br />
					<ul>
						<li> <b>before</b><br />
	
							Run advice before the a method execution.
						</li>
						<li><b>after</b><br />
							Run advice after the method execution, regardless of its outcome.
						</li>
						<li><b>after-returning</b><br />
							Run advice after the a method execution only if method completes successfully.
						</li>
						<li><b>after-throwing</b><br />
							Run advice after the a method execution only if method exits by throwing an exception.
						</li>
						<li><b>around</b><br />
							Run advice before and after the advised method is invoked.
						</li>
					</ul>
				</p>
			</div>			
		</Tab.Pane>
	)
};


export const SpringREST = () => {
	return (
		<Tab.Pane id="Spring-Rest" eventKey="Spring-Rest">
			<div id="Spring-Rest">
				<p>
					<h5>Spring REST APIs</h5>
					REST stands for <b>REpresentational State Transfer</b>. 
					The main goal of RESTful web services is to make web services more effective. RESTful web services try to define services 
					using the different concepts that are already present in HTTP. REST is an architectural approach, not a protocol.
					
					<br/><br/>
					It does not define the standard message exchange format. We can build REST services with both XML and JSON. 
					JSON is more popular format with REST.
					<br/><br/>
					When we request a resource, we provide the representation of the resource like XML, HTML and JSON. The important methods of HTTP are:
					<ul>
						<li>
							<b>GET</b> - It reads a resource.
						</li><li>	
							<b>PUT</b> - It updates an existing resource.
						</li><li>
							<b>POST</b> - It creates a new resource.
						</li><li>
							<b>DELETE</b> - It deletes the resource.
						</li>
					</ul>
					
					<h6>Advantages of REST APIs</h6>
						<ul>
							<li>
							REST APIs are platform-independent.
							</li><li>
							It can be written in any programming language and can be executed on any platform.
							</li><li>
							It provides different data format like JSON, text, HTML, and XML.
							</li><li>
							It is fast in comparison to SOAP because there is no strict specification like SOAP.
							</li><li>
							These are reusable.
							</li><li>
							They are language neutral.
							</li>
						</ul>
					<h6>REST APIs Constraints</h6>
					<ul>
						<li>
						There must be a service producer and service consumer.
						</li><li>
						The service is stateless.
						</li><li>
						The service result must be cacheable.
						</li><li>
						The interface is uniform and exposing resources.
						</li><li>
						The service should assume a layered architecture.
						</li>
					</ul>	 
				</p>
			</div>			
		</Tab.Pane>
	)
};


export const SpringORM = () => {
	return (
		<Tab.Pane id="Spring-ORM" eventKey="Spring-ORM">
			<div id="Spring-ORM">
				<p>
					<h5>Spring Data Access Object (DAO)</h5>
					DAO stands for Data Access Object, which is commonly used for database interaction. 
					DAOs exist to provide a means to read and write data to the database and they should expose this functionality 
					through an interface by which the rest of the application will access them.
					<br /><br />
					The DAO support in Spring makes it easy to work with data access technologies like 
					<b> JDBC, Hibernate, JPA, or JDO </b>in a consistent way.
					<br /><br />
					<h6>Spring JDBC Framework</h6>
					While working with the database using plain old JDBC, it becomes cumbersome to write unnecessary code to handle exceptions, 
					opening and closing database connections, etc.
					<br /> 
					However, Spring JDBC Framework takes care of all the low-level details starting 
					from opening the connection, prepare and execute the SQL statement, process exceptions, handle transactions and finally 
					close the connection.
					<br /><br />
					So what we have to do is just define the connection parameters and specify the SQL statement to be executed and do the 
					required work for each iteration while fetching data from the database.
					<br />
					Spring JDBC provides several approaches and correspondingly different classes to interface with the database. 
					We are going to take classic and the most popular approach which makes use of JdbcTemplate class of the framework.
					<br /> 
					This is the central framework class that manages all the database communication and exception handling.
					<br /><br />
					<h6>JdbcTemplate Class</h6>
					The JDBC Template class executes SQL queries, updates statements, stores procedure calls, performs iteration over ResultSets, 
					and extracts returned parameter values. It also catches JDBC exceptions and translates them to the generic, more informative,
					 exception hierarchy defined in the org.springframework.dao package.
					<br /><br />
					Instances of the JdbcTemplate class are threadsafe once configured. So we can configure a single instance of a 
					JdbcTemplate and then safely inject this shared reference into multiple DAOs.
					<br /><br />
					A common practice when using the JDBC Template class is to configure a DataSource in our Spring configuration file,
					 and then dependency-inject that shared DataSource bean into our DAO classes, and the JdbcTemplate is created in the 
					 setter for the DataSource.
					<br />
					
				</p>
			</div>			
		</Tab.Pane>
	)
};

export const TransactionManagement = () => {
	return (
		<Tab.Pane id="Spring-TransactionManagement" eventKey="Spring-TransactionManagement">
			<div id="Spring-TransactionManagement">
				<p>
					<h5>Transaction Management</h5>
					A database transaction is a sequence of actions that are treated as a single unit of work. 
					These actions should either complete entirely or take no effect at all. Transaction management is an important part of 
					RDBMS-oriented enterprise application to ensure data integrity and consistency.
					<br/><br/> 
					The concept of transactions can be described with the following four key properties described as ACID −
					<br/>
					<ul>
						<li>
						<b>Atomicity </b>− A transaction should be treated as a single unit of operation, which means either the entire sequence of operations is successful or unsuccessful.
						</li><li>
						<b>Consistency </b>− This represents the consistency of the referential integrity of the database, unique primary keys in tables, etc.
						</li><li>
						<b>Isolation </b>− There may be many transaction processing with the same data set at the same time. Each transaction should be isolated from others to prevent data corruption.
						</li><li>
						<b>Durability </b>− Once a transaction has completed, the results of this transaction have to be made permanent and cannot be erased from the database due to system failure.
						</li>
					</ul>
					A real RDBMS database system will guarantee all four properties for each transaction. 
					<br/>
					The simplistic view of a transaction issued to the database using SQL is as follows −
					<ul>
					<li>
					Begin the transaction using begin transaction command.
					</li><li>
					Perform various deleted, update or insert operations using SQL queries.
					</li><li>
					If all the operation are successful then perform commit otherwise rollback all the operations.
					</li></ul>
					Spring framework provides an abstract layer on top of different underlying transaction management APIs. 
					Spring's transaction support aims to provide an alternative to EJB transactions by adding transaction capabilities to POJOs. 
					<br/>
					Spring supports both programmatic and declarative transaction management. EJBs require an application server, 
					but Spring transaction management can be implemented without the need of an application server.
					<br/><br/>
					<h6>Local vs. Global Transactions</h6>
					<ul><li> Local transactions are specific to a single transactional resource like a JDBC connection, whereas global transactions can span multiple transactional resources like transaction in a distributed system.
					</li><li>
					Local transaction management can be useful in a centralized computing environment where application components and resources are located at a single site, and transaction management only involves a local data manager running on a single machine. Local transactions are easier to be implemented.
					</li></ul>
					Global transaction management is required in a distributed computing environment where all the resources are distributed across multiple systems. In such a case, transaction management needs to be done both at local and global levels. A distributed or a global transaction is executed across multiple systems, and its execution requires coordination between the global transaction management system and all the local data managers of all the involved systems.
					<br/><br/>
					<h6>Programmatic vs. Declarative</h6>
						Spring supports two types of transaction management −
						<ul>
						<li>
						<b>Programmatic transaction management</b> − This means that we have to manage the transaction with the help of programming. 
						That gives us extreme flexibility, but it is difficult to maintain.
					</li><li>
						<b>Declarative transaction management</b> − This means we separate transaction management from the business code. 
						We only use annotations or XML-based configuration to manage the transactions.
					</li></ul>
						Declarative transaction management is preferable over programmatic transaction management though it is less flexible 
						than programmatic transaction management, which allows us to control transactions through our code.
				</p>
			</div>			
		</Tab.Pane>
	)
}
export const SpringMVC = () => {
	return (
		<Tab.Pane id="Spring-MVC" eventKey="Spring-MVC">
			<div id="Spring-MVC">
				<p>
					<h5>Spring MVC</h5>
					The Spring Web MVC framework provides <b>Model-View-Controller (MVC)</b> architecture and ready components that can be used to 
					develop flexible and loosely coupled web applications.<br/> 
					The MVC pattern results in separating the different aspects of the 
					application (input logic, business logic, and UI logic), while providing a loose coupling between these elements.
					<br />
					<ol>
						<li>
						The <b>Model</b> encapsulates the application data and in general they will consist of POJO.
						</li><li>
						The <b>View</b> is responsible for rendering the model data and in general it generates HTML output that the client's 
						browser can interpret.
						</li><li>
						The <b>Controller</b> is responsible for processing user requests and building an appropriate model and passes it to the 
						view for rendering.
						</li>
					</ol>
					
					<h6>The DispatcherServlet</h6>
					The Spring Web model-view-controller (MVC) framework is designed around a DispatcherServlet that handles all the HTTP requests 
					and responses. The request processing workflow of the Spring Web MVC DispatcherServlet is illustrated in the following diagram −
					<br />
					<p className="pCenter"><img src={SpringMVCImg} alt="not found" className="paddingTop20" style={{ 'width': '50%' }} /></p>
					
					Following is the sequence of events corresponding to an incoming HTTP request to DispatcherServlet −
					<ul>
						<li>
						After receiving an HTTP request, DispatcherServlet consults the HandlerMapping to call the appropriate Controller.
						</li><li>
						The Controller takes the request and calls the appropriate service methods based on used GET or POST method. The service method will set model data based on defined business logic and returns view name to the DispatcherServlet.
						</li><li>
						The DispatcherServlet will take help from ViewResolver to pickup the defined view for the request.
						</li><li>
						Once view is finalized, The DispatcherServlet passes the model data to the view which is finally rendered on the browser.
						</li>
					</ul>
					All the above-mentioned components, i.e. HandlerMapping, Controller, and ViewResolver are parts of WebApplicationContext which is an extension of the plainApplicationContext with some extra features necessary for web applications.
					
				</p>
			</div>
		</Tab.Pane>
	)
};

export const SpringEvents = () => {
	return (
		<Tab.Pane id="Spring-Events" eventKey="Spring-Events">
			<div id="Spring-Events">
				<p>
					<h5>Spring Events</h5>
					As we know that core of Spring is the ApplicationContext, which manages the complete life cycle of the beans.
					<br />
					The ApplicationContext publishes certain types of events when loading the beans. 
					For example, a ContextStartedEvent is published when the context is started and ContextStoppedEvent is published 
					when the context is stopped.
					<br/><br />
					Event handling in the ApplicationContext is provided through the ApplicationEvent class and ApplicationListener interface. 
					Hence, if a bean implements the ApplicationListener, then every time an ApplicationEvent gets published to the ApplicationContext,
					 that bean is notified.
					 
					<br/>
					Spring provides the following standard events −<br/>
					<ul>
						<li>
							<h6>ContextRefreshedEvent</h6>
							
							This event is published when the ApplicationContext is either initialized or refreshed. This can also be raised using the refresh() method on the ConfigurableApplicationContext interface.
							
						</li><li>	
							<h6>ContextStartedEvent</h6>
							
							This event is published when the ApplicationContext is started using the start() method on the ConfigurableApplicationContext interface. We can poll our database or we can restart any stopped application after receiving this event.
							
							</li><li>
							<h6>ContextStoppedEvent</h6>
							
							This event is published when the ApplicationContext is stopped using the stop() method on the ConfigurableApplicationContext interface. We can do required housekeep work after receiving this event.
							
							</li><li>	
							<h6>ContextClosedEvent</h6>
							
							This event is published when the ApplicationContext is closed using the close() method on the ConfigurableApplicationContext interface. A closed context reaches its end of life; it cannot be refreshed or restarted.
							
							</li><li>	
							<h6>RequestHandledEvent</h6>
							
							This is a web-specific event telling all beans that an HTTP request has been serviced. 
						</li>
					</ul>	
				</p>
			</div>			
		</Tab.Pane>
	)
};


export const SpringIntegration = () => {
	return (
		<Tab.Pane id="Spring-Integration" eventKey="Spring-Integration">
			<div id="Spring-Integration">
				<p>
					<h6>Spring Hibernate Integration</h6>
					Spring is one of the most used Java EE Framework and Hibernate is the most popular ORM framework. 
					That’s why Spring Hibernate combination is used a lot in enterprise applications. <br/><br/>
					
					The Spring framework provides HibernateTemplate class, so we don't need to follow so many steps like 
					create Configuration, BuildSessionFactory, Session, beginning and committing transaction etc.<br />
					So it saves a lot of code and time.<br/><br/>
	
					<h6>Understanding problem without using spring</h6>
					
					Let's understand it by the code of hibernate given below:
					<p className="pCode">	
					<span className="pComment">/ /creating configuration</span><br /> 
					Configuration configuration=new Configuration();  <br/>  
					configuration.configure("hibernate.cfg.xml");    <br/>
					    <br/>
					<span className="pComment">/ /creating seession factory object</span><br/>    
					SessionFactory factory=configuration.buildSessionFactory();<br/>    
					    <br/>
					<span className="pComment">/ /creating session object</span><br/>    
					Session session=factory.openSession();<br/>    
					    <br/>
					<span className="pComment">/ /creating transaction object</span><br/>    
					Transaction transaction=session.beginTransaction();    <br/>
					        <br/>
					Employee employee=new Employee(100,"employee", 20000);   <br/> 
					session.persist(employee);<span className="pComment"> / /persisting the object </span>  <br/> 
					    <br/>
					transaction.commit();<span className="pComment"> / /transaction is commited  </span>  <br/>
					session.close();    <br/>
					</p>
					
					As we can see in the code of sole hibernate, we have to follow so many steps.
					<br /><br />
					<h6>Solution by using HibernateTemplate class of Spring Framework</h6>
					
					Now, we do not need to follow so many steps. We can simply write this as below:
					<br />
					<p className="pCode">
					Employee employee=new Employee(100, "employee", 20000);    <br/>
					hibernateTemplate.save(employee);<span className="pComment"> / /persisting the object </span>
					</p>  
				</p>
			</div>			
		</Tab.Pane>
	)
};

export const SpringUnitTesting = () => {
	return (
		<Tab.Pane id="Spring-Unit-Testing" eventKey="Spring-Unit-Testing">
			<div id="Spring-Unit-Testing">
				<p>
					<h5>Spring Unit Testing</h5>
					Unit Testing is a one of the testing done by the developers to make sure individual unit or component 
					functionalities are working fine.
					<br/>
					Writing good unit tests can be considered an art that is hard to master. 
					But the good thing is that the mechanics supporting it are easy to learn.
					<br/><br />
									
					<h6>Advantages of unit testing</h6>
					<ul>
						<li>Unit testing allows developers to locate and fix bugs earlier.</li>
						<li>				
						It facilitates high-quality code. By running the test several times, developers gain confidence when changing the code.
						</li><li>
						Unit testing can boost a developer’s coding skills. Through this process, developers can learn how to write better code.
						</li>
					</ul>
					
					<h5>Best practices for unit testing</h5>
					Below are some of the best practices when writing unit testing:
					
					<ul>
						<li><h6>Separate the functionality that needs to be evaluated</h6>
							The functionality to be tested should be isolated by restricting the context in which loaded components are used. 
							This is achieved by using the <b>@Test</b> annotation.
							<br/>
							The advantage of this approach is that it is easy to locate tricky bugs which promote clean production.
							<br />
							This particular feature, if used wisely, can help developers test large applications without 
							impacting performance.
						</li>
						<li>
							<h6>Loading functionality in slices</h6>
							It is crucial to restrict the application context to only the Spring components included in the test scenario. 
							This is achieved by including them in the annotation declaration.
						</li>
						<li>			
							<h6>Use the @DataJpaTest Annotation</h6>
							To increase the performance of different components, we use the <b>@DataJpaTest</b> annotation.
							<br />
							This is because it will not load beans annotated with <b>@Service</b>, <b>@Controller</b> and the entire application context.
						</li>
						<li>				
						<h6>Performing tests that are database-related</h6>
							It’s good to simulate database-interacting beans and disable Spring Boot test DB initialization for the 
							Spring profile where the tests are executed.
							<br />				
							We should always keep this practice in mind when testing controllers.
						</li>
						<li>
							<h6>Make our test simple</h6>
							Whenever we wtire unit tests, we should always ensure that the tests are straightforward.
							<br />				
							To achieve this, we should keep the test with low cyclomatic complexity. Cyclomatic complexity is a 
							coding statistic that shows how many different execution pathways a procedure can take.
							<br />
							We are less likely to introduce problems when working on code with a lesser complexity 
							since it is easier to understand and maintain.
						</li>
					</ul>
				</p>
			</div>			
		</Tab.Pane>
	)
};
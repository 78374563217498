import { Tab, Row, Col, Nav } from 'react-bootstrap';
import Logo from './../../images/SpringLogo.png';
import {
	SpringOverview, SpringArchitecture, SpringCore, InversionOfControl, SpringDependencyInjection,
	SpringAOP, SpringREST, SpringORM, TransactionManagement, SpringMVC, SpringEvents, SpringIntegration, SpringUnitTesting
} from './spring-topics';

const tabsData = [
{ eventKey: 'Spring-Overview', event: 'Spring Overview' },
{ eventKey: 'Spring-Architecture', event: 'Spring Architecture' },
{ eventKey: 'Spring-Core', event: 'Spring Core' },
{ eventKey: 'Inversion-of-control', event: 'Inversion of Control' },
{ eventKey: 'Spring-Dependency-Injection', event: 'Dependency Injection' },
{ eventKey: 'Spring-AOP', event: 'AOP with Spring Framework' },
{ eventKey: 'Spring-ORM', event: 'Spring DAO' },
{ eventKey: 'Spring-TransactionManagement', event: 'Transaction Management'},
{ eventKey: 'Spring-MVC', event: 'Spring MVC' },
{ eventKey: 'Spring-Rest', event: 'Spring REST APIs' },
{ eventKey: 'Spring-Events', event: 'Spring Events' },
{ eventKey: 'Spring-Integration', event: 'Spring Hibernate Integration' },
{ eventKey: 'Spring-Unit-Testing', event: 'Spring Unit Testing' }];

const Spring = ()=> {
    return (
        <div className="container-fluid">
			<div className="row paddingBottom20">
				<div className="col-lg-2"></div>
				<div className="col-lg-8">
					<div className="col-lg-12" >
						<div className="row" >
							<div className="col-lg-12 header">
								<h3><img src={Logo} className="paddingRight20" alt="Spring Logo not found" style={{ 'paddingRight': '10px', 'height': '30px', 'width': '30px' }} />
									Spring Framework
								</h3>
								<hr />
							</div>
							<div className="col-lg-12 paddingBottom10">
								Spring framework is an open source Java platform that provides comprehensive infrastructure support 
								for developing robust Java applications very easily and very rapidly. Spring framework was initially 
								written by Rod Johnson and was first released under the Apache 2.0 license in June 2003.<br />
								
							</div>
							<div className="col-lg-12">

								<Tab.Container id="left-tabs-example" defaultActiveKey={tabsData[0].eventKey}>
									<Row>
										<Col sm={3}>
											<Nav variant="pills" className="flex-column menuItems">
												{tabsData.map((tabData, index) => {												
													const hrefData= '#'+tabData.eventKey;
													
													return <Nav.Item key={tabData.eventKey}>
														<Nav.Link href={hrefData} eventKey={tabData.eventKey}>{index + 1}.) {tabData.event}</Nav.Link>
													</Nav.Item>
													}
												)}
											</Nav>
										</Col>
										<Col sm={9}>
											<Tab.Content>
												<SpringOverview />
												<SpringArchitecture />
												<SpringCore />
												<InversionOfControl />
												<SpringDependencyInjection />
												<SpringAOP />
												<SpringREST />
												<SpringORM />
												<TransactionManagement />
												<SpringMVC />
												<SpringEvents />
												<SpringIntegration />
												<SpringUnitTesting />
											</Tab.Content>
										</Col>
									</Row>
								</Tab.Container>
							</div>
							<div className="col-lg-12 paddingTop20">
								<span className="textColorPink">
									<b>Disclaimer: </b>
									Content of this page has been referred from <a href="https://spring.io/" target="_blank" rel="noreferrer">Spring.io</a>.
								</span> 
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
        
    );
}

export default Spring;
